import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { Calculator } from "../calculatorTypes";

import archiveIcon from "../image/icon/proj_archive.svg";
import deleteIcon from "../image/icon/proj_delete.svg";

import modifyIcon from "../image/icon/proj_modif.svg";
import styles from "./style/Hover.module.scss";

const HoverActions = ({
  id,
  handleDuplicateRoi,
  handleDeleteOneProject,
}: {
  id: number;
  handleDuplicateRoi: (id: number) => Promise<void>;
  handleDeleteOneProject: (id: number) => Promise<void>;
}) => {
  const { t } = useTranslation();

  return (
    <span className={classNames(styles.hover_actions_container)}>
      <Link
        to={`/`}
        data-title={t<string>("Duplicate")}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleDuplicateRoi(id);
        }}
      >
        <img
          className={classNames(styles.actionIcons)}
          src={archiveIcon}
          alt="Archiver le projet"
        />
      </Link>

      <Link
        to={"/"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleDeleteOneProject(id);
        }}
        data-title={t<string>("Delete")}
      >
        <img
          className={classNames(styles.actionIcons)}
          src={deleteIcon}
          alt="Supprimer le projet"
        />
      </Link>

      <Link
        to={`/customer/${id}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-title={t<string>("Edit")}
      >
        <img
          className={classNames(styles.actionIcons)}
          src={modifyIcon}
          alt="Modifier le projet"
        />
      </Link>
    </span>
  );
};

export default HoverActions;
