import React from "react";
import styles from "./style/home.module.scss";
import Button from "@material-ui/core/Button";
import { AllInputs } from "./roiTypes";
import { useHistory, useRouteMatch } from "react-router";
import { useUser } from "@mgi-labs/mgi-id";
import { usersAuthorized } from "../authorizedUsers";

const Prevnext = ({
  allInputs,
  previous,
  next,
  isInputForm,
  overhead,
  setIsOpenModal,
}: {
  allInputs?: AllInputs;
  previous?: string;
  next?: string;
  isInputForm: boolean;
  overhead?: number;
  setIsOpenModal?: React.Dispatch<boolean>;
}) => {
  const history = useHistory();

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const roiId = () => {
    if (routeMatch?.params.roiId) return routeMatch.params.roiId;
    else return "";
  };

  const profile = useUser();

  return (
    <div
      style={{
        bottom: !isInputForm ? "-85px" : "",
        paddingLeft: !isInputForm ? "0" : "",
        paddingRight: !isInputForm ? "0" : "",
      }}
      className={styles.prevnext}
    >
      <Button
        className={styles.left}
        variant="outlined"
        color="primary"
        size="large"
        disableElevation
        style={{ visibility: previous ? "visible" : "hidden" }}
        onClick={() => {
          history.push("/" + previous + "/" + roiId(), allInputs?.id);
        }}
        type="submit"
      >
        Previous
      </Button>

      {next === "confirm" ? (
        profile?.email &&
        usersAuthorized.includes(profile?.email) && (
          <>
            <Button
              className={styles.right}
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              onClick={() => {
                setIsOpenModal && setIsOpenModal(true);
              }}
              type="submit"
            >
              Save
            </Button>
          </>
        )
      ) : (
        <Button
          className={styles.right}
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          style={{ visibility: next ? "visible" : "hidden" }}
          onClick={() => {
            if (
              next !== "" &&
              next !== "calibration" &&
              next !== "job-info" &&
              next !== "time-labor"
            )
              history.push("/" + next + "/" + roiId(), allInputs?.id);
          }}
          type="submit"
        >
          Next
        </Button>
      )}
    </div>
  );
};
export default Prevnext;
