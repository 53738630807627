import { AllInputs } from "./roiTypes";

export default function dataFoilCost(allInputs: AllInputs) {
  if (!allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect) {
    return {
      widthofMasterFoilRoll: 0,
      totalLengthofRoll: 0,
      costofMasterRoll: 0,
      costPerSquare: 0,
      widthofFoilRollSlitDown: 0,
      lengthWithWasteFront: 0,
      lengthWithWasteBack: 0,
      widthofFoilRollSlitDownBack: 0,
      widthofFoilRollSlitDownFront: 0,
      lengthWithWaste: 0,
      sQMofFoilUsedper: 0,
      costofFoilPerSheetin: 0,
      costPerJobIn: 0,
    };
  }

  const widthofMasterFoilRoll: number = allInputs.calibrationInputs
    .foilInformation.foilMasterRollWidth
    ? allInputs.calibrationInputs.foilInformation.foilMasterRollWidth
    : 0;

  const totalLengthofRoll: number = allInputs.calibrationInputs
    ? allInputs.calibrationInputs.foilInformation.foilRollLength
    : 0;
  const costofMasterRoll = allInputs.calibrationInputs
    ? allInputs.calibrationInputs.foilInformation.foilMasterRollCost
    : 0;
  const costPerSquare = allInputs.calibrationInputs.isSqm
    ? allInputs.calibrationInputs.sqmPrice
    : (costofMasterRoll / (totalLengthofRoll * 1000 * widthofMasterFoilRoll)) *
      1000000;

  const widthofFoilRollSlitDownFront = allInputs.jobInfoInputs?.front
    .optimizedFoilUsagePerSheet
    ? allInputs.jobInfoInputs?.front.optimizedFoilUsagePerSheet
        .maximizedFoilWidthUsageRollWidth
    : 0;

  const lengthWithWasteFront = allInputs.jobInfoInputs.front
    .optimizedFoilUsagePerSheet
    ? allInputs.jobInfoInputs?.front.optimizedFoilUsagePerSheet
        .maximizedFoilLengthUsagePerSheet + 50.8
    : 0;

  const widthofFoilRollSlitDownBack = allInputs.jobInfoInputs?.back
    .optimizedFoilUsagePerSheet
    ? allInputs.jobInfoInputs?.back.optimizedFoilUsagePerSheet
        .maximizedFoilWidthUsageRollWidth
    : 0;

  const lengthWithWasteBack = allInputs.jobInfoInputs.back
    .optimizedFoilUsagePerSheet
    ? allInputs.jobInfoInputs?.back.optimizedFoilUsagePerSheet
        .maximizedFoilLengthUsagePerSheet + 50.8
    : 0;

  const sqmFront =
    allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect > 0 &&
    allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0
      ? (widthofFoilRollSlitDownFront * lengthWithWasteFront) / 1000000
      : 0;

  const sqmBack =
    allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect > 0 &&
    allInputs.jobInfoInputs.back.digitalHotFoilCoverage > 0
      ? (widthofFoilRollSlitDownBack * lengthWithWasteBack) / 1000000
      : 0;

  const sQMofFoilUsedper = allInputs.jobInfoInputs.doubleSided
    ? sqmFront + sqmBack
    : sqmFront;

  const costofFoilPerSheetin =
    costPerSquare * (allInputs.foilSurface ?? sQMofFoilUsedper);

  const costPerJobIn = allInputs.jobInfoInputs
    ? allInputs.jobInfoInputs?.numberOfSheets * costofFoilPerSheetin
    : 0;

  const ret = {
    widthofMasterFoilRoll: widthofMasterFoilRoll,
    totalLengthofRoll: totalLengthofRoll,
    costofMasterRoll: costofMasterRoll,
    lengthWithWasteFront: lengthWithWasteFront,
    lengthWithWasteBack: lengthWithWasteBack,
    widthofFoilRollSlitDownBack: widthofFoilRollSlitDownBack,
    widthofFoilRollSlitDownFront: widthofFoilRollSlitDownFront,
    costPerSquare: costPerSquare,
    sQMofFoilUsedper: sQMofFoilUsedper,
    costofFoilPerSheetin: costofFoilPerSheetin,
    costPerJobIn: costPerJobIn,
  };

  return ret;
}
