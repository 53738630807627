import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/modal.module.scss";

import close from "../image/icon/close.svg";
import { useUser } from "@mgi-labs/mgi-id";
import { testPdf } from "./testPdf";
import { AllInputs } from "./roiTypes";

const DownloadModal = ({
  setIsOpenModal,
  allInputs,
  overhead,
}: {
  setIsOpenModal: React.Dispatch<boolean>;
  allInputs: AllInputs;
  overhead: number;
}) => {
  const profile = useUser();
  const defaultFirstName = profile?.firstName;
  const defaultLastName = profile?.lastName;
  const defaultEmail = profile?.email;

  const [email, setEmail] = useState<string | undefined>(defaultEmail);
  const [firstName, setFirstName] = useState<string | undefined>(
    defaultFirstName
  );
  const [lastName, setLastName] = useState<string | undefined>(defaultLastName);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [title, setTitle] = useState<string>("MGI Seller");

  useEffect(() => {
    if (!email && !firstName && !lastName) {
      setEmail(defaultEmail);
      setFirstName(defaultFirstName);
      setLastName(defaultLastName);
    }
  }, [
    defaultEmail,
    defaultFirstName,
    defaultLastName,
    email,
    firstName,
    lastName,
  ]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownload = useCallback(async () => {
    if (allInputs && !isDownloading) {
      const userPdfInfos = {
        name: `${firstName} ${lastName}`,
        email: email,
        phone: phoneNumber,
        title: title,
      };
      setIsDownloading(true);
      try {
        await testPdf(
          allInputs,
          profile,
          overhead ?? allInputs.overhead,
          userPdfInfos
        );
      } finally {
        setIsOpenModal(false);
        setIsDownloading(false);
      }
    } else {
      alert("Error when trying to create pdf, please restart process");
    }
  }, [
    allInputs,
    email,
    firstName,
    isDownloading,
    lastName,
    overhead,
    phoneNumber,
    profile,
    setIsOpenModal,
    title,
  ]);

  return (
    <>
      <div className={classNames(styles.modal)}>
        <div
          className={classNames(styles.background)}
          onClick={() => setIsOpenModal(false)}
        ></div>
        <div className={classNames(styles.modalContent)}>
          <img
            onClick={() => setIsOpenModal(false)}
            className={classNames(styles.close)}
            src={close}
            alt="Close"
          />
          <div className={classNames(styles.title)}>
            <p>PDF AUTHOR INFORMATIONS</p>
          </div>
          <div className={classNames(styles.content)}>
            <div className={classNames(styles.inputtext, styles.padding10)}>
              <label htmlFor="mail">Email * : </label>
              <input
                id="mail"
                type="mail"
                placeholder="exemple@mail.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="prenom">First Name * : </label>
              <input
                id="prenom"
                type="text"
                placeholder={"Your first name"}
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="nom">Last Name * : </label>
              <input
                id="nom"
                type="text"
                placeholder={"Your last name"}
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="phone">Phone number * : </label>
              <input
                id="phone"
                type="text"
                placeholder={"06 00 00 00 00"}
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className={classNames(styles.inputtext)}>
              <label htmlFor="title">Title * : </label>
              <input
                id="title"
                type="text"
                placeholder={"MGI Seller"}
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className={classNames(styles.submit)}>
              <input
                required
                type="submit"
                style={{
                  cursor: isDownloading ? "default" : "pointer",
                  opacity: isDownloading ? 0.75 : 1,
                }}
                value={"Save PDF"}
                onClick={handleDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadModal;
