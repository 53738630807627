import { useUser } from "@mgi-labs/mgi-id";
import { useEffect, useState } from "react";
import { useRoiList } from "../hooks/useRoi";
import RoiTable from "./roiTable";

function RoiListFetch() {
  const [filterCustom, setFilterCustom] = useState<{
    search?: string;
  }>({});

  const user = useUser();

  const { data: roiList, mutate } = useRoiList(user?.producer_team_uuid ?? "");

  useEffect(() => {
    mutate();
  }, [mutate, user]);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  return roiList?.length !== undefined ? (
    <RoiTable
      events={roiList}
      filterCustom={filterCustom}
      setFilterCustom={setFilterCustom}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      mutate={mutate}
    />
  ) : null;
}

export default RoiListFetch;
