import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
import {
  createStyles,
  FormControlLabel,
  InputAdornment,
  InputBase,
  OutlinedInput,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Print from "@material-ui/icons/PrintOutlined";
import Time from "@material-ui/icons/HourglassEmpty";

import Build from "@material-ui/icons/BuildOutlined";
import Colorsform from "../image/icon/Colorsform.svg";
import paperoil from "../image/icon/paper-roll.svg";
import Opacity from "@material-ui/icons/Opacity";
import {
  Control,
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import {
  AllInputs,
  allInputsSchema,
  AlphaJetGlobalPrintingCosts,
  CalibrationInputs,
  calibrationInputsSchema,
} from "./roiTypes";
import Prevnext from "./prevnext";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useBackend } from "../backend/BackendProvider";
import SwitchPageInput from "./switchPageInput";
import { whiteSpaceNumber } from "./formatNumber";
import { usersAuthorized } from "../authorizedUsers";
import { useUser } from "@mgi-labs/mgi-id";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      height: "20px",
      border: "1px solid #ced4da",
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.

      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const Calibraton = ({
  register,
  setValue,
  control,
  handleSubmit,
  setCalibrationInputs,
  allInputs,
  isInputForm,
  setIsLoading,
}: {
  register: any;
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined
  ) => void;
  control: Control<AllInputs>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AllInputs>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<AllInputs> | undefined
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  setCalibrationInputs: React.Dispatch<CalibrationInputs>;
  allInputs: AllInputs;
  isInputForm: boolean;
  setIsLoading: React.Dispatch<boolean>;
}) => {
  //const [amortizationPeriod, setAmortizationPeriod] = useState<number>();

  const [monthPerYear, setMonthPerYear] = useState<number>();

  const [productionHoursPerDay, setProductionHoursPerDay] = useState<number>();

  const [workingDaysPerWeek, setWorkingDaysPerWeek] = useState<number>();

  const [foilMasterRollWidth, setFoilMasterRollWidth] = useState<number>();

  const alphaJetConsumableCosts = {
    alphaJetGlobalPrintingCost: 90,
    costPerUvVarnishLiter: 73,
    costPrimer: 6.5,
    table: new AlphaJetGlobalPrintingCosts().table,
  };

  const history = useHistory();
  const location = useLocation();
  const profile = useUser();

  const backend = useBackend();

  const roiIdLocation: number = location.state as number;

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const roiId = useCallback(() => {
    if (routeMatch?.params.roiId) return parseInt(routeMatch.params.roiId);
    else return roiIdLocation;
  }, [roiIdLocation, routeMatch?.params.roiId]);

  const [clicked, setClicked] = useState<string>("job-info");

  const [isSqm, setIsSqm] = useState<boolean>(
    allInputs.calibrationInputs.isSqm
  );

  const roiUpdateCalibrationHandler = useCallback(
    async (data: AllInputs) => {
      if (data) {
        const castedCalibration = calibrationInputsSchema.cast({
          ...data.calibrationInputs,
          alphaJetGlobalPrintingCosts: {
            ...data.calibrationInputs.alphaJetGlobalPrintingCosts,
            table: new AlphaJetGlobalPrintingCosts(),
          },
          isSqm: isSqm,
        }) as unknown as CalibrationInputs;

        if (setCalibrationInputs) {
          setCalibrationInputs(castedCalibration);
        }
        const newAllInputs = new AllInputs(
          castedCalibration,
          allInputs.jobInfoInputs,
          allInputs.inputEstimatedSheets,
          allInputs.overhead,
          allInputs.otherCostType,
          allInputs?.customerInputs,
          allInputs.roiInfo,
          roiId(),
          allInputs.foilSurface
        );
        setIsLoading(true);
        try {
          await backend.updateRoi(
            roiId(),
            allInputsSchema.cast(newAllInputs) as unknown as AllInputs,
            false
          );
        } finally {
          setIsLoading(false);
          history.push(`/${clicked}/${roiId()}`, roiId());
        }
      }
    },
    [
      allInputs?.customerInputs,
      allInputs.foilSurface,
      allInputs.inputEstimatedSheets,
      allInputs.jobInfoInputs,
      allInputs.otherCostType,
      allInputs.overhead,
      allInputs.roiInfo,
      backend,
      clicked,
      history,
      isSqm,
      roiId,
      setCalibrationInputs,
      setIsLoading,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit(roiUpdateCalibrationHandler)}
      style={{
        overflow: !isInputForm ? "scroll" : "",
        height: !isInputForm ? "calc(100%);" : "",
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <SwitchPageInput setClicked={setClicked} allInputs={allInputs} />
      <div className={styles.customer}>
        <Grid
          xs={12}
          className={classNames(
            styles.paddinglr10,
            styles.topform,
            styles.dispflex
          )}
        >
          <Grid className={styles.inputcontent} xs={6}>
            <Grid xs={2}></Grid>
            <Grid xs={10} className={styles.dispflex}>
              <Typography variant="h5">ALPHAJET CAPEX</Typography>
              <FormControl size="small" margin="none">
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  input={<BootstrapInput />}
                  value="€"
                >
                  <MenuItem value={"€"}>€</MenuItem>
                  <MenuItem value={"$"}>$</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className={styles.inputcontent} xs={6}>
            <Grid xs={2}></Grid>
            <Grid xs={10}>
              <Typography variant="h5">ALPHAJET CONSUMABLES COSTS</Typography>
            </Grid>
          </Grid>
        </Grid>
        <FormControl className={styles.formControl}>
          <Grid xs={12} className={styles.dispflex}>
            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Print color="primary" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6" component="h6">
                      Equipement cost
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <FormControl
                      className={styles.yellow}
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2 500 000.00"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.equipementCost
                          ) ?? 3200000
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        required
                        name="calibrationInputs.equipementCost"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Time color="primary" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">
                      Amortization time period
                    </Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    {/* <FormControl
                      className={styles.yellow}
                      size="small"
                      margin="none"
                    >
                      <Controller
                        render={() => (
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            required
                            onChange={(e) => {
                              setAmortizationPeriod(
                                e.target.value as number | undefined
                              );
                              setValue(
                                "calibrationInputs.amortizationPeriod",
                                e.target.value
                              );
                            }}
                            value={amortizationPeriod}
                            input={<BootstrapInput />}
                            name="calibrationInputs.amortizationPeriod"
                            defaultValue={
                              allInputs.calibrationInputs.amortizationPeriod
                            }
                          >
                            <MenuItem value={36}>36</MenuItem>
                            <MenuItem value={48}>48</MenuItem>
                            <MenuItem value={60}>60</MenuItem>
                            <MenuItem value={72}>72</MenuItem>
                            <MenuItem value={84}>84</MenuItem>
                            <MenuItem value={120}>120</MenuItem>
                          </Select>
                        )}
                        name="calibrationInputs.amortizationPeriod"
                        control={control}
                        defaultValue={
                          allInputs.calibrationInputs.amortizationPeriod
                        }
                      />
                    </FormControl> */}
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.amortizationPeriod
                          ) ?? 72
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.amortizationPeriod"
                      />
                    </FormControl>
                    <Typography
                      className={classNames(
                        styles.paddingl10,
                        styles.denomination
                      )}
                    >
                      months
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Interest rate of leasing</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.interestLeasingRate
                          ) ?? 2
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.interestLeasingRate"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Downpayment on lease</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="250 000.00"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.leaseDownpayment
                          ) ?? 250000
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.leaseDownpayment"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Build color="primary" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">
                      Yearly Maintenance Contract
                    </Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="0"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs
                              ?.yearlyMaintenanceContract
                              .yearlyMaintenanceContract
                          ) ?? 0
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.yearlyMaintenanceContract.yearlyMaintenanceContract"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Maintenance contract length</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">
                            year(s)
                          </InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="1"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs
                              ?.yearlyMaintenanceContract
                              .maintenanceContractLength
                          ) ?? 1
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.yearlyMaintenanceContract.maintenanceContractLength"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>
                      MGI Connect <br />
                      Yearly Subscription
                    </Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="1"
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs
                              ?.yearlyMaintenanceContract.mgiConnectSubscription
                          ) ?? 1
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.yearlyMaintenanceContract.mgiConnectSubscription"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Working months /year</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      margin="none"
                    >
                      <Controller
                        render={() => (
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={monthPerYear}
                            required
                            onChange={(e) => {
                              setValue(
                                "calibrationInputs.yearlyMaintenanceContract.monthPerYear",
                                e.target.value
                              );
                              setMonthPerYear(
                                e.target.value as number | undefined
                              );
                            }}
                            input={<BootstrapInput />}
                            defaultValue={
                              allInputs.calibrationInputs
                                ?.yearlyMaintenanceContract.monthPerYear ?? 12
                            }
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                          </Select>
                        )}
                        name="calibrationInputs.yearlyMaintenanceContract.monthPerYear"
                        control={control}
                        defaultValue={
                          allInputs.calibrationInputs?.yearlyMaintenanceContract
                            .monthPerYear ?? 12
                        }
                      />
                    </FormControl>
                    <Typography
                      className={classNames(
                        styles.paddingl10,
                        styles.denomination
                      )}
                    >
                      months
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Working days /month</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">day(s)</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="21"
                        defaultValue={
                          allInputs.calibrationInputs?.yearlyMaintenanceContract
                            .workingDaysPerMonth ?? 21
                        }
                        inputRef={register}
                        name="calibrationInputs.yearlyMaintenanceContract.workingDaysPerMonth"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Working days /week</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      margin="none"
                    >
                      <Controller
                        render={() => (
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            onChange={(e) => {
                              setValue(
                                "calibrationInputs.yearlyMaintenanceContract.workingDaysPerWeek",
                                e.target.value
                              );
                              setWorkingDaysPerWeek(
                                e.target.value as number | undefined
                              );
                            }}
                            required
                            value={workingDaysPerWeek}
                            input={<BootstrapInput />}
                            defaultValue={
                              allInputs.calibrationInputs
                                ?.yearlyMaintenanceContract
                                .workingDaysPerWeek ?? 5
                            }
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                          </Select>
                        )}
                        name="calibrationInputs.yearlyMaintenanceContract.workingDaysPerWeek"
                        control={control}
                        defaultValue={
                          allInputs.calibrationInputs?.yearlyMaintenanceContract
                            .workingDaysPerWeek ?? 5
                        }
                      />
                    </FormControl>
                    <Typography
                      className={classNames(
                        styles.paddingl10,
                        styles.denomination
                      )}
                    >
                      days
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Hours of production /day</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      margin="none"
                    >
                      <Controller
                        render={() => (
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            onChange={(e) => {
                              setValue(
                                "calibrationInputs.yearlyMaintenanceContract.productionHoursPerDay",
                                e.target.value
                              );
                              setProductionHoursPerDay(
                                e.target.value as number | undefined
                              );
                            }}
                            required
                            value={productionHoursPerDay}
                            name="calibrationInputs.yearlyMaintenanceContract.productionHoursPerDay"
                            input={<BootstrapInput />}
                            defaultValue={
                              allInputs.calibrationInputs
                                ?.yearlyMaintenanceContract
                                .productionHoursPerDay ?? 8
                            }
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={21}>21</MenuItem>
                            <MenuItem value={22}>22</MenuItem>
                            <MenuItem value={23}>23</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                          </Select>
                        )}
                        name="calibrationInputs.yearlyMaintenanceContract.productionHoursPerDay"
                        control={control}
                        defaultValue={
                          allInputs.calibrationInputs?.yearlyMaintenanceContract
                            .productionHoursPerDay ?? 8
                        }
                      />
                    </FormControl>
                    <Typography
                      className={classNames(
                        styles.paddingl10,
                        styles.denomination
                      )}
                    >
                      hours
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Electricity: Cost per kW /h</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="0.080"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.electricityCostPerKWHr
                          ) ?? 0.08
                        }
                        inputRef={register}
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.electricityCostPerKWHr"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">
                      Cost of rent per month /sqm
                    </Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="15.00"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.rentCostPerMonthPerSqm2
                          ) ?? 15
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="calibrationInputs.rentCostPerMonthPerSqm2"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ALPHAJET CONSUMables Costs */}

            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <img src={Colorsform} alt="Colors form" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Typography variant="h6">
                    ALPHAJET global printing costs
                  </Typography>
                  {profile?.email &&
                  usersAuthorized.includes(profile?.email) ? (
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2"
                        endAdornment={
                          <InputAdornment position="end">€ / L</InputAdornment>
                        }
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs
                              ?.alphaJetGlobalPrintingCosts
                              .alphaJetGlobalPrintingCost
                          ) ?? 90
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.alphaJetGlobalPrintingCosts.alphaJetGlobalPrintingCost"
                      />
                    </FormControl>
                  ) : (
                    <Typography>
                      {alphaJetConsumableCosts.alphaJetGlobalPrintingCost} € / L
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10}>
                  <Typography variant="h6" color="primary">
                    Included: Inkjet heads, CMYK ink, Pre-coating Ink.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Opacity color="primary"></Opacity>
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Typography variant="h6">
                    Cost per liter of UV varnish
                  </Typography>
                  {profile?.email &&
                  usersAuthorized.includes(profile?.email) ? (
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2"
                        endAdornment={
                          <InputAdornment position="end">€ / L</InputAdornment>
                        }
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs
                              ?.alphaJetGlobalPrintingCosts
                              .costPerUvVarnishLiter
                          ) ?? 73
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter"
                      />
                    </FormControl>
                  ) : (
                    <Typography>
                      {alphaJetConsumableCosts.costPerUvVarnishLiter} € / L
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10}>
                  <Typography variant="h6" color="primary">
                    Included: All other consummables
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Opacity color="primary"></Opacity>
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Typography variant="h6" style={{ width: "100%" }}>
                    Cost of Primer
                  </Typography>
                  {profile?.email &&
                  usersAuthorized.includes(profile?.email) ? (
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2"
                        endAdornment={
                          <InputAdornment position="end">€ / L</InputAdornment>
                        }
                        inputRef={register}
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs
                              ?.alphaJetGlobalPrintingCosts.costPrimer
                          ) ?? 6.5
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        name="calibrationInputs.alphaJetGlobalPrintingCosts.costPrimer"
                      />
                    </FormControl>
                  ) : (
                    <Typography>
                      {alphaJetConsumableCosts.costPrimer} € / L
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  {" "}
                  <img src={paperoil} alt="Paper oil" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={5}>
                    <Typography variant="h6">Foil information</Typography>
                  </Grid>
                  <Grid xs={7} className={styles.alignend}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={isSqm.toString()}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="small" color="primary" />}
                        label="sqm price"
                        name="calibrationInputs.isSqm"
                        onClick={() => setIsSqm(true)}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="small" color="primary" />}
                        label="Linear meter"
                        name="calibrationInputs.isSqm"
                        onClick={() => setIsSqm(false)}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent)}
                xs={12}
                style={{ display: isSqm ? "none" : "flex" }}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Foil master roll width</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      margin="none"
                    >
                      <Controller
                        render={() => (
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            required
                            onChange={(e) => {
                              setValue(
                                "calibrationInputs.foilInformation.foilMasterRollWidth",
                                e.target.value
                              );
                              setFoilMasterRollWidth(
                                e.target.value as number | undefined
                              );
                            }}
                            value={foilMasterRollWidth}
                            input={<BootstrapInput />}
                            defaultValue={
                              allInputs.calibrationInputs?.foilInformation
                                .foilMasterRollWidth ?? 640
                            }
                          >
                            <MenuItem value={400}>400</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                            <MenuItem value={640}>640</MenuItem>
                            <MenuItem value={700}>700</MenuItem>
                          </Select>
                        )}
                        name="calibrationInputs.foilInformation.foilMasterRollWidth"
                        control={control}
                        defaultValue={
                          allInputs.calibrationInputs?.foilInformation
                            .foilMasterRollWidth ?? 640
                        }
                      />
                    </FormControl>
                    <Typography
                      className={classNames(
                        styles.paddingl10,
                        styles.denomination
                      )}
                    >
                      mm
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent)}
                xs={12}
                style={{ display: isSqm ? "none" : "flex" }}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Foil roll length</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">m</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="400"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.foilInformation
                              .foilRollLength
                          ) ?? 400
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="calibrationInputs.foilInformation.foilRollLength"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent)}
                xs={12}
                style={{ display: isSqm ? "none" : "flex" }}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Foil master roll cost</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="171.00"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.foilInformation
                              .foilMasterRollCost
                          ) ?? 110
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="calibrationInputs.foilInformation.foilMasterRollCost"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* A afficher quand sqm est selectionner et cacher la partie du haut */}
              <Grid
                className={classNames(
                  styles.inputcontent
                  //styles.displaynone
                )}
                style={{ display: !isSqm ? "none" : "flex" }}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Price per sqm</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        required
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="171.00"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.calibrationInputs?.sqmPrice
                          ) ?? 110
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="calibrationInputs.sqmPrice"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <Prevnext isInputForm previous="customer" next="job-info" />
    </form>
  );
};
export default Calibraton;
