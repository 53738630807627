import React from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

import Timer from "@material-ui/icons/Timer";
import NoteOutlined from "@material-ui/icons/NoteOutlined";
import Alarm from "@material-ui/icons/Alarm";
import { AllInputs } from "./roiTypes";
import dataTimeLabor from "./dataTimeLabor";
import { formatNumber, whiteSpaceNumber } from "./formatNumber";

const Timelabor = ({ allInputs }: { allInputs: AllInputs }) => {
  const rDataTimeAndLabor = dataTimeLabor(allInputs);

  function generateFinalString() {
    let string: string[] = [];

    const inkFront = allInputs.jobInfoInputs.front.cmykPrintFileInkCoverage > 0;
    const inkBack = allInputs.jobInfoInputs.back.cmykPrintFileInkCoverage > 0;

    const varnishFront =
      allInputs.jobInfoInputs.front.embellishmentSpotUvCoverage > 0 &&
      allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect > 0;
    const varnishBack =
      allInputs.jobInfoInputs.back.embellishmentSpotUvCoverage > 0 &&
      allInputs.jobInfoInputs.back.embellishmentUv2d3dEffect > 0;

    const opvFront = allInputs.jobInfoInputs.front.opv > 0;
    const opvBack = allInputs.jobInfoInputs.back.opv > 0;

    const foilFront =
      allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect > 0;
    const foilBack =
      allInputs.jobInfoInputs.back.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect > 0;

    if (allInputs.jobInfoInputs.doubleSided) {
      if (inkFront && inkBack) {
        string.push("- CMYK Digital Printing Front & Back Sides\n");
      } else if (inkFront && !inkBack) {
        string.push("- CMYK Digital Printing Front Side\n");
      } else if (!inkFront && inkBack) {
        string.push("- CMYK Digital Printing Back Side\n");
      }
    } else {
      string.push("- CMYK Digital Printing Front Side\n");
    }

    if (allInputs.jobInfoInputs.doubleSided) {
      if (varnishFront && varnishBack) {
        string.push("- Digital Spot UV Coating Front & Back Sides\n");
      } else if (varnishFront && !varnishBack) {
        string.push("- Digital Spot UV Coating Front Side\n");
      } else if (!varnishFront && varnishBack) {
        string.push("- Digital Spot UV Coating Back Side\n");
      }
    } else {
      string.push("- Digital Spot UV Coating Front Side\n");
    }

    if (allInputs.jobInfoInputs.doubleSided) {
      if (opvFront && opvBack) {
        string.push("- Digital Over Printing Varnish Front & Back Sides\n");
      } else if (opvFront && !opvBack) {
        string.push("- Digital Over Printing Varnish Front Side\n");
      } else if (!opvFront && opvBack) {
        string.push("- Digital Over Printing Varnish Back Side\n");
      }
    } else {
      string.push("- Digital Over Printing Varnish Front Side\n");
    }

    if (allInputs.jobInfoInputs.doubleSided) {
      if (foilFront && foilBack) {
        string.push("- Digital Hot-Foil Stamping Front & Back Sides\n");
      } else if (foilFront && !foilBack) {
        string.push("- Digital Hot-Foil Stamping Front Side\n");
      } else if (!foilFront && foilBack) {
        string.push("- Digital Hot-Foil Stamping Back Side\n");
      }
    } else {
      string.push("- Digital Hot-Foil Stamping Front Side\n");
    }

    return string;
  }

  const string = generateFinalString();

  return (
    <div className={classNames(styles.customer, styles.height100)}>
      <Grid
        xs={12}
        className={classNames(
          styles.paddinglr10,
          styles.topform,
          styles.dispflex
        )}
      >
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10} className={styles.dispflex}>
            <Typography variant="h5">TIME AND LABOR</Typography>
            <FormControl size="small" margin="none"></FormControl>
          </Grid>
        </Grid>
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10}></Grid>
        </Grid>
      </Grid>
      <FormControl className={styles.formControl}>
        <Grid xs={12} className={styles.dispflex}>
          <Grid xs={6} className={classNames(styles.paddinglr10)}>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}>
                <Timer color="primary" />
              </Grid>
              <Grid xs={10} className={styles.dispflex}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography color="primary"> Total set-up time</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography variant="h6">TOTAL SET-UP TIME</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography
                    className={styles.alignend6}
                    variant="h6"
                    color="primary"
                  >
                    {rDataTimeAndLabor.totalSetUpTime} min
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}>
                <Alarm color="primary" />
              </Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography color="primary">Production Time</Typography>
                </Grid>
                <Grid xs={6}></Grid>
              </Grid>
            </Grid>

            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid className={styles.iconcontent} xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Production time</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(
                      formatNumber(rDataTimeAndLabor.pureProductionTime)
                    )}{" "}
                    min
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={9}>
                  <Typography variant="h6">
                    TOTAL TIME WITH{" "}
                    {allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity}
                    % OEE
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography
                    className={styles.alignend6}
                    variant="h6"
                    color="primary"
                  >
                    {whiteSpaceNumber(
                      formatNumber(rDataTimeAndLabor.totalTime)
                    )}{" "}
                    min
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Labor cost /h</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(
                      formatNumber(rDataTimeAndLabor.hourlyRate)
                    )}{" "}
                    €
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography variant="h6">JOB LABOR COSTS</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography
                    className={styles.alignend6}
                    variant="h6"
                    color="primary"
                  >
                    {whiteSpaceNumber(
                      formatNumber(rDataTimeAndLabor.jobLaborCost)
                    )}{" "}
                    €
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            ></Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}></Grid>
            </Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid className={styles.iconcontent} xs={2}></Grid>
            </Grid>
          </Grid>

          {/* ALPHAJET CONSUMables Costs */}

          <Grid
            xs={6}
            className={classNames(styles.paddinglr10, styles.margint15)}
          >
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid className={styles.iconcontent} xs={2}>
                <NoteOutlined color="primary" />
              </Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluebkg)}
                xs={10}
              >
                <Typography color="primary">Sheets</Typography>
                <Typography>
                  {whiteSpaceNumber(allInputs.jobInfoInputs.numberOfSheets)}{" "}
                  sheets
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluebkg)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Length</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(
                      allInputs.jobInfoInputs.paperFormat.length
                    )}{" "}
                    mm
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames(
                styles.inputcontent,

                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluebkg)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Width</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(
                      allInputs.jobInfoInputs.paperFormat.width
                    )}{" "}
                    mm
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={12}>
                  <Typography variant="h6">
                    Production time at{" "}
                    {allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity}
                    % productivity
                  </Typography>
                </Grid>

                <Grid className={classNames(styles.algnitem)} xs={12}>
                  <Typography variant="h6" color="primary">
                    {rDataTimeAndLabor.productionTimeAt70Productivity}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={10}
              >
                <Grid className={classNames(styles.algnitem)} xs={12}>
                  <Typography variant="h6">
                    Pages per hour at{" "}
                    {allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity}
                    % productivity
                  </Typography>
                </Grid>

                <Grid className={classNames(styles.algnitem)} xs={12}>
                  <Typography variant="h6" color="primary">
                    {whiteSpaceNumber(
                      Math.round(rDataTimeAndLabor.pagePerHourAt70Productivity)
                    )}
                    &nbsp;sheets /h
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid
              className={classNames(
                styles.inputcontent,
                styles.margint15,
                styles.dispflex
              )}
              xs={12}
            ></Grid>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={12}>
                  <Typography>
                    Speed includes Single Pass Process with:
                  </Typography>
                </Grid>
                <Grid
                  className={classNames(styles.algnitem, styles.jobString)}
                  xs={12}
                >
                  {string.map((s) => (
                    <Typography>{s}</Typography>
                  ))}
                </Grid>
                <Grid xs={6}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
};
export default Timelabor;
