import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
import {
  Checkbox,
  createStyles,
  FormControlLabel,
  InputAdornment,
  InputBase,
  OutlinedInput,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Paper from "@material-ui/icons/NoteOutlined";

import Colorsform from "../image/icon/Colorsform.svg";
import paperoil from "../image/icon/paper-roll.svg";
import euro from "../image/icon/euro.svg";
import onek from "../image/icon/1k-24px.svg";
import Opacity from "@material-ui/icons/Opacity";
import { AccountBoxOutlined, SpeedOutlined } from "@material-ui/icons";
import {
  Control,
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import {
  AllInputs,
  allInputsSchema,
  JobInfoInputs,
  jobInfoInputsSchema,
  ScenarioType,
} from "./roiTypes";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useBackend } from "../backend/BackendProvider";
import Prevnext from "./prevnext";
import {
  formatNumber,
  removeWhiteSpaceInNumber,
  whiteSpaceNumber,
} from "./formatNumber";
import SwitchPageInput from "./switchPageInput";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      height: "20px",
      border: "1px solid #ced4da",
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.

      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const Jobinfo = ({
  watch,
  register,
  setValue,
  control,
  handleSubmit,
  setJobInfoInputs,
  allInputs,
  isInputForm,
  setIsLoading,
}: {
  watch: () => AllInputs;
  register: any;
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined
  ) => void;
  control: Control<AllInputs>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AllInputs>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<AllInputs> | undefined
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  setJobInfoInputs: React.Dispatch<JobInfoInputs>;
  allInputs: AllInputs;
  isInputForm: boolean;
  setIsLoading: React.Dispatch<boolean>;
}) => {
  const [embellishmentUv2d3dEffectFront, setEmbellishmentUv2d3dEffectFront] =
    useState<number>(allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect);
  const [embellishmentUv2d3dEffectBack, setEmbellishmentUv2d3dEffectBack] =
    useState<number>(allInputs.jobInfoInputs.back.embellishmentUv2d3dEffect);
  const [opv2d3dEffectFront, setOpv2d3dEffectFront] = useState<number>(
    allInputs.jobInfoInputs.front.opv2d3dEffect
  );
  const [opv2d3dEffectBack, setOpv2d3dEffectBack] = useState<number>(
    allInputs.jobInfoInputs.back.opv2d3dEffect
  );
  const [embellishment2d3dEffectFront, setEmbellishment2d3dEffectFront] =
    useState<number>(allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect);
  const [embellishment2d3dEffectBack, setEmbellishment2d3dEffectBack] =
    useState<number>(allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect);

  const [scenario, setScenario] = useState<ScenarioType>(
    allInputs.jobInfoInputs.alphaJetInfos.scenario
  );

  const [hasOPV] = useState<boolean>(allInputs.jobInfoInputs.front.opv > 0);
  const [internalEcoConso, setInternalEcoConso] = useState<boolean>(
    allInputs.jobInfoInputs.internalEcoConso
  );

  const [isDoubleSided, setIsDoubleSided] = useState<boolean>(
    allInputs.jobInfoInputs.doubleSided
  );

  const [isInKg, setIsInKg] = useState<boolean>(allInputs.jobInfoInputs.isInKg);

  const localLength: number | undefined =
    watch().jobInfoInputs?.paperFormat.length;
  const localWidth: number | undefined =
    watch().jobInfoInputs?.paperFormat.width;

  const sqm2 = useMemo(() => {
    return localWidth && localLength
      ? (removeWhiteSpaceInNumber(localLength) / 1000) *
          (removeWhiteSpaceInNumber(localWidth) / 1000)
      : (removeWhiteSpaceInNumber(allInputs.jobInfoInputs.paperFormat.length) /
          1000) *
          (removeWhiteSpaceInNumber(allInputs.jobInfoInputs.paperFormat.width) /
            1000);
  }, [
    allInputs.jobInfoInputs.paperFormat.length,
    allInputs.jobInfoInputs.paperFormat.width,
    localLength,
    localWidth,
  ]);

  const localSubstratSheet =
    watch().jobInfoInputs?.alphaJetInfos.substratCostPer1000Sheet;

  const localSubstratKg =
    watch().jobInfoInputs?.alphaJetInfos.substratCostPerKg;

  const localSheet: number | undefined = watch().jobInfoInputs?.numberOfSheets;
  const localWeight: number | undefined = watch().jobInfoInputs?.weight;

  const kgValue = useMemo(() => {
    return sqm2 && localSheet && localWeight
      ? sqm2 *
          (removeWhiteSpaceInNumber(localWeight) / 1000) *
          removeWhiteSpaceInNumber(localSheet)
      : sqm2 *
          (removeWhiteSpaceInNumber(allInputs.jobInfoInputs.weight) / 1000) *
          removeWhiteSpaceInNumber(allInputs.jobInfoInputs.numberOfSheets);
  }, [
    allInputs.jobInfoInputs.numberOfSheets,
    allInputs.jobInfoInputs.weight,
    localSheet,
    localWeight,
    sqm2,
  ]);

  const materialCostForJob = useMemo(() => {
    const tempKgValue = localSubstratKg
      ? removeWhiteSpaceInNumber(localSubstratKg) * kgValue
      : removeWhiteSpaceInNumber(
          allInputs.jobInfoInputs.alphaJetInfos.substratCostPerKg
        ) * kgValue;

    const tempSheetValue =
      localSheet && localSubstratSheet
        ? (removeWhiteSpaceInNumber(localSubstratSheet) / 1000) *
          removeWhiteSpaceInNumber(localSheet)
        : (removeWhiteSpaceInNumber(
            allInputs.jobInfoInputs.alphaJetInfos.substratCostPer1000Sheet
          ) /
            1000) *
          removeWhiteSpaceInNumber(allInputs.jobInfoInputs.numberOfSheets);

    return isInKg ? tempKgValue : tempSheetValue;
  }, [
    allInputs.jobInfoInputs.alphaJetInfos.substratCostPer1000Sheet,
    allInputs.jobInfoInputs.alphaJetInfos.substratCostPerKg,
    allInputs.jobInfoInputs.numberOfSheets,
    isInKg,
    kgValue,
    localSheet,
    localSubstratKg,
    localSubstratSheet,
  ]);

  const hasFoilFront =
    (watch().jobInfoInputs?.front.digitalHotFoilCoverage > 0 &&
      watch().jobInfoInputs?.front.digitalHotFoil2d3dEffect !== 0) ||
    (allInputs.jobInfoInputs?.front.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs?.front.digitalHotFoil2d3dEffect !== 0);

  const hasFoilBack =
    (watch().jobInfoInputs?.back.digitalHotFoilCoverage > 0 &&
      watch().jobInfoInputs?.back.digitalHotFoil2d3dEffect !== 0) ||
    (allInputs.jobInfoInputs?.back.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs?.back.digitalHotFoil2d3dEffect !== 0);

  const localMaximizedFoilLengthUsagePerSheetFront =
    watch().jobInfoInputs?.front.optimizedFoilUsagePerSheet
      .maximizedFoilLengthUsagePerSheet;

  const totalFoilLengthUsageSheetFront =
    localMaximizedFoilLengthUsagePerSheetFront
      ? removeWhiteSpaceInNumber(localMaximizedFoilLengthUsagePerSheetFront) +
        50.8
      : allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet
          .maximizedFoilLengthUsagePerSheet + 50.8 ?? 1050.8;

  const localMaximizedFoilLengthUsagePerSheetBack =
    watch().jobInfoInputs?.back.optimizedFoilUsagePerSheet
      ?.maximizedFoilLengthUsagePerSheet;

  const totalFoilLengthUsageSheetBack =
    localMaximizedFoilLengthUsagePerSheetBack
      ? removeWhiteSpaceInNumber(localMaximizedFoilLengthUsagePerSheetBack) +
        50.8
      : allInputs.jobInfoInputs.back.optimizedFoilUsagePerSheet
          .maximizedFoilLengthUsagePerSheet + 50.8 ?? 1050.8;

  const hasDigitalHotFoil = useMemo(
    () => hasFoilFront || hasFoilBack,
    [hasFoilBack, hasFoilFront]
  );
  const thickness = Math.max(
    watch().jobInfoInputs?.front.embellishmentUv2d3dEffect ??
      allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect,
    watch().jobInfoInputs?.front.digitalHotFoil2d3dEffect ??
      allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect,
    watch().jobInfoInputs?.back.embellishmentUv2d3dEffect ??
      allInputs.jobInfoInputs.back.embellishmentUv2d3dEffect,
    watch().jobInfoInputs?.back.digitalHotFoil2d3dEffect ??
      allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect
  );

  const alphaJetSpeed = setPrintingSpeed(hasDigitalHotFoil, thickness);

  const history = useHistory();
  const location = useLocation();

  const backend = useBackend();

  const roiIdLocation: number = location.state as number;

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const roiId = useCallback(() => {
    if (routeMatch?.params.roiId) return parseInt(routeMatch.params.roiId);
    else return roiIdLocation;
  }, [roiIdLocation, routeMatch?.params.roiId]);

  const [clicked, setClicked] = useState<string>("time-labor");

  const roiUpdateJobInfoHandler = useCallback(
    async (data: AllInputs) => {
      if (data) {
        const castedJobInfoInputs = jobInfoInputsSchema.cast({
          ...data.jobInfoInputs,
          digitalHotFoilEffect: hasDigitalHotFoil,
          doubleSided: isDoubleSided,
          isInKg: isInKg,
          opv: hasOPV,
          internalEcoConso: internalEcoConso,
          paperCostForJob: materialCostForJob,
          alphaJetInfos: {
            ...data.jobInfoInputs.alphaJetInfos,
            scenario: scenario,
          },
        }) as unknown as JobInfoInputs;

        if (setJobInfoInputs) {
          setJobInfoInputs(castedJobInfoInputs);
        }

        const newAllInputs = new AllInputs(
          allInputs.calibrationInputs,
          castedJobInfoInputs,
          allInputs.inputEstimatedSheets,
          allInputs.overhead,
          allInputs.otherCostType,
          allInputs?.customerInputs,
          allInputs?.roiInfo,
          roiId(),
          allInputs.foilSurface
        );
        setIsLoading(true);
        try {
          await backend.updateRoi(
            roiId(),
            allInputsSchema.cast(newAllInputs) as unknown as AllInputs,
            false
          );
        } finally {
          history.push(`/${clicked}/${roiId()}`, roiId());
          setIsLoading(false);
        }
      }
    },
    [
      allInputs.calibrationInputs,
      allInputs?.customerInputs,
      allInputs.foilSurface,
      allInputs.inputEstimatedSheets,
      allInputs.otherCostType,
      allInputs.overhead,
      allInputs?.roiInfo,
      backend,
      clicked,
      hasDigitalHotFoil,
      hasOPV,
      history,
      internalEcoConso,
      isDoubleSided,
      isInKg,
      materialCostForJob,
      roiId,
      scenario,
      setIsLoading,
      setJobInfoInputs,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit(roiUpdateJobInfoHandler)}
      style={{
        overflow: !isInputForm ? "scroll" : "",
        height: !isInputForm ? "calc(100% - 85px);" : "",
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <SwitchPageInput setClicked={setClicked} allInputs={allInputs} />
      <div className={styles.customer}>
        <Grid
          xs={12}
          className={classNames(
            styles.paddinglr10,
            styles.topform,
            styles.dispflex,
            styles.marginb12
          )}
        >
          <Grid className={styles.inputcontent} xs={6}>
            <Grid xs={2}></Grid>
            <Grid xs={10} className={styles.dispflex}>
              <Typography variant="h5">
                ALPHAJET JOB COST CALCULATION
              </Typography>
            </Grid>
          </Grid>

          <Grid className={styles.inputcontent} xs={6}>
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid
                xs={12}
                className={classNames(
                  styles.dispflex,
                  styles.borderradiusprint
                )}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={3}>
                    <Typography>Printing</Typography>
                  </Grid>
                  <Grid xs={9} className={styles.alignend}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={isDoubleSided.toString()}
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio size="small" color="primary" />}
                        label="Side A"
                        name="jobInfoInputs.doubleSided"
                        onClick={() => setIsDoubleSided(false)}
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio size="small" color="primary" />}
                        label="Side A + B"
                        name="jobInfoInputs.doubleSided"
                        onClick={() => setIsDoubleSided(true)}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FormControl className={styles.formControl}>
          <Grid xs={12} className={styles.dispflex}>
            <Grid
              xs={12}
              className={classNames(styles.dispflex, styles.paddinglr10)}
            >
              <Grid
                xs={12}
                className={classNames(styles.blueborder, styles.dispflex)}
              >
                <Grid
                  className={classNames(styles.dispflex, styles.inputcontent)}
                  xs={6}
                >
                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15
                    )}
                    xs={12}
                  >
                    <Grid className={styles.iconcontent} xs={2}>
                      <Paper color="primary" />
                    </Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={6}>
                        <Typography variant="h6">Sheet Format</Typography>
                      </Grid>
                      <Grid
                        xs={6}
                        className={classNames(
                          styles.alignend,
                          styles.marginBottom
                        )}
                      >
                        <FormControl
                          className={styles.yellow}
                          fullWidth
                          size="small"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">
                                length mm
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="1020"
                            defaultValue={
                              whiteSpaceNumber(
                                allInputs.jobInfoInputs?.paperFormat.length
                              ) ?? 1020
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                            inputRef={register}
                            name="jobInfoInputs.paperFormat.length"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={6}>
                        <Typography></Typography>
                      </Grid>
                      <Grid
                        xs={6}
                        className={classNames(
                          styles.alignend,
                          styles.marginBottom
                        )}
                      >
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">
                                width mm
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="720"
                            defaultValue={
                              whiteSpaceNumber(
                                allInputs.jobInfoInputs?.paperFormat.width
                              ) ?? 720
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                            inputRef={register}
                            name="jobInfoInputs.paperFormat.width"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={6}>
                        <Typography>Ups Per Sheet</Typography>
                      </Grid>
                      <Grid xs={6} className={classNames(styles.alignend)}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">
                                ups/sheet
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="720"
                            defaultValue={
                              whiteSpaceNumber(
                                allInputs.jobInfoInputs?.upsPerSheet
                              ) ?? 0
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                            inputRef={register}
                            name="jobInfoInputs.upsPerSheet"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={(styles.inputcontent, styles.dispflex)} xs={6}>
                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15
                    )}
                    xs={12}
                  >
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={6}>
                        <Typography>Substrate Weight</Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">
                                gsm
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="100"
                            defaultValue={
                              whiteSpaceNumber(
                                allInputs.jobInfoInputs?.weight
                              ) ?? 100
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                            inputRef={register}
                            name="jobInfoInputs.weight"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15
                    )}
                    xs={12}
                  >
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={6}>
                        <Typography>N° of sheet(s)</Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">
                                sheet(s)
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="1000"
                            defaultValue={
                              whiteSpaceNumber(
                                allInputs.jobInfoInputs?.numberOfSheets
                              ) ?? 1000
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                            inputRef={register}
                            name="jobInfoInputs.numberOfSheets"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames(styles.algnitem, styles.marginb5)}
              xs={12}
            >
              <Grid className={classNames(styles.algnitem)} xs={1}></Grid>
              <Grid xs={11} className={classNames(styles.algnitem)}>
                <Typography className={classNames(styles.bluetext)}>
                  Print parameters
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid className={classNames(styles.blueborder)}>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid className={classNames(styles.algnitem)} xs={10}>
                    <Typography className={classNames(styles.bluetext)}>
                      Side A
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <img src={Colorsform} alt="Colorsform" />
                  </Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>CMYK print file ink coverage</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="30"
                          defaultValue={
                            whiteSpaceNumber(
                              allInputs.jobInfoInputs?.front
                                .cmykPrintFileInkCoverage
                            ) ?? 30
                          }
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.front.cmykPrintFileInkCoverage"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <Opacity color="primary" />
                  </Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography> Spot UV varnish coverage</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="10"
                          defaultValue={
                            whiteSpaceNumber(
                              allInputs.jobInfoInputs?.front
                                .embellishmentSpotUvCoverage
                            ) ?? 10
                          }
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.front.embellishmentSpotUvCoverage"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Varnish 2D / 3D effect </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        margin="none"
                      >
                        <Controller
                          render={() => (
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              onChange={(e) => {
                                setValue(
                                  "jobInfoInputs.front.embellishmentUv2d3dEffect",
                                  e.target.value
                                );
                                setEmbellishmentUv2d3dEffectFront(
                                  e.target.value as number
                                );
                              }}
                              input={<BootstrapInput />}
                              value={embellishmentUv2d3dEffectFront}
                              defaultValue={
                                allInputs.jobInfoInputs?.front
                                  .embellishmentUv2d3dEffect ?? 0
                              }
                            >
                              <MenuItem value={0}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={14}>14</MenuItem>
                              <MenuItem value={28}>28</MenuItem>
                              <MenuItem value={35}>35</MenuItem>
                              <MenuItem value={42}>42</MenuItem>
                              <MenuItem value={56}>56</MenuItem>
                              <MenuItem value={63}>63</MenuItem>
                              <MenuItem value={77}>77</MenuItem>
                              <MenuItem value={112}>112</MenuItem>
                            </Select>
                          )}
                          name="jobInfoInputs.front.embellishmentUv2d3dEffect"
                          control={control}
                          defaultValue={
                            allInputs.jobInfoInputs?.front
                              .embellishmentUv2d3dEffect ?? 7
                          }
                        />
                      </FormControl>
                      <Typography
                        className={classNames(
                          styles.paddingl10,
                          styles.denomination
                        )}
                      >
                        µm
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>OPV (Protective Varnish) covering</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="0"
                          defaultValue={allInputs.jobInfoInputs.front.opv ?? 0}
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.front.opv"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>OPV 2D / 3D effect </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        margin="none"
                      >
                        <Controller
                          render={() => (
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              onChange={(e) => {
                                setValue(
                                  "jobInfoInputs.front.opv2d3dEffect",
                                  e.target.value
                                );
                                setOpv2d3dEffectFront(e.target.value as number);
                              }}
                              input={<BootstrapInput />}
                              value={opv2d3dEffectFront}
                              defaultValue={
                                allInputs.jobInfoInputs?.front.opv2d3dEffect ??
                                0
                              }
                            >
                              <MenuItem value={0}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={3}>3 (Satin / Matte)</MenuItem>
                              <MenuItem value={6}>6 (Semi Glossy)</MenuItem>
                              <MenuItem value={9}>9 (Glossy)</MenuItem>
                              <MenuItem value={12}>12</MenuItem>
                              <MenuItem value={15}>15</MenuItem>
                            </Select>
                          )}
                          name="jobInfoInputs.front.opv2d3dEffect"
                          control={control}
                          defaultValue={
                            allInputs.jobInfoInputs?.front.opv2d3dEffect ?? 7
                          }
                        />
                      </FormControl>
                      <Typography
                        className={classNames(
                          styles.paddingl10,
                          styles.denomination
                        )}
                      >
                        µm
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <img src={paperoil} alt="Paperoil" />
                  </Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography> Digital Hot-Foil coverage</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="0"
                          defaultValue={
                            whiteSpaceNumber(
                              allInputs.jobInfoInputs?.front
                                .digitalHotFoilCoverage
                            ) ?? 0
                          }
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.front.digitalHotFoilCoverage"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Foil 2D / 3D effect </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        margin="none"
                      >
                        <Controller
                          render={() => (
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              onChange={(e) => {
                                setValue(
                                  "jobInfoInputs.front.digitalHotFoil2d3dEffect",
                                  e.target.value
                                );
                                setEmbellishment2d3dEffectFront(
                                  e.target.value as number
                                );
                              }}
                              input={<BootstrapInput />}
                              value={embellishment2d3dEffectFront}
                              defaultValue={
                                allInputs.jobInfoInputs?.front
                                  .digitalHotFoil2d3dEffect ?? 0
                              }
                            >
                              <MenuItem value={0}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={14}>14</MenuItem>
                              <MenuItem value={28}>28</MenuItem>
                              <MenuItem value={35}>35</MenuItem>
                              <MenuItem value={42}>42</MenuItem>
                              <MenuItem value={56}>56</MenuItem>
                              <MenuItem value={63}>63</MenuItem>
                              <MenuItem value={77}>77</MenuItem>
                              <MenuItem value={112}>112</MenuItem>
                            </Select>
                          )}
                          name="jobInfoInputs.front.digitalHotFoil2d3dEffect"
                          control={control}
                          defaultValue={
                            allInputs.jobInfoInputs?.front
                              .digitalHotFoil2d3dEffect ?? 0
                          }
                        />
                      </FormControl>
                      <Typography
                        className={classNames(
                          styles.paddingl10,
                          styles.denomination
                        )}
                      >
                        µm
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(
                  styles.blueborder,
                  !hasFoilFront && styles.displaynone
                )}
              >
                <Grid
                  xs={12}
                  // className={classNames(!hasDigitalHotFoil && styles.disabled)}
                >
                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15
                    )}
                    xs={12}
                  >
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography>Optimized Foil usage /sheet</Typography>
                      </Grid>
                      <Grid xs={4}></Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Maximized Foil width usage-roll(s) width
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={classNames(styles.alignend)}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                          disabled={!hasDigitalHotFoil}
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">mm</InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="700"
                            inputRef={register}
                            name="jobInfoInputs.front.optimizedFoilUsagePerSheet.maximizedFoilWidthUsageRollWidth"
                            defaultValue={
                              (allInputs.jobInfoInputs?.front
                                .optimizedFoilUsagePerSheet
                                ?.maximizedFoilWidthUsageRollWidth &&
                                whiteSpaceNumber(
                                  allInputs.jobInfoInputs?.front
                                    .optimizedFoilUsagePerSheet
                                    ?.maximizedFoilWidthUsageRollWidth
                                )) ??
                              700
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Maximized Foil length usage /sheet
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                          disabled={!hasDigitalHotFoil}
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">mm</InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="1000"
                            inputRef={register}
                            name="jobInfoInputs.front.optimizedFoilUsagePerSheet.maximizedFoilLengthUsagePerSheet"
                            defaultValue={
                              (allInputs.jobInfoInputs?.front
                                .optimizedFoilUsagePerSheet
                                ?.maximizedFoilLengthUsagePerSheet &&
                                whiteSpaceNumber(
                                  allInputs.jobInfoInputs?.front
                                    .optimizedFoilUsagePerSheet
                                    ?.maximizedFoilLengthUsagePerSheet
                                )) ??
                              1000
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Foil waste / Roll Up-&-Down
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend}>
                        <Typography variant="h6">
                          {formatNumber(50.8)} mm
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Total Foil length usage /sheet (incl. waste)
                        </Typography>
                      </Grid>
                      <Grid
                        xs={4}
                        className={classNames(
                          styles.alignend,
                          styles.bluecontent,
                          styles.fitcontent
                        )}
                      >
                        <Typography variant="h6">
                          {whiteSpaceNumber(
                            formatNumber(totalFoilLengthUsageSheetFront)
                          )}{" "}
                          mm
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* ALPHAJET CONSUMables Costs */}

            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid
                className={classNames(
                  styles.blueborder,
                  !isDoubleSided && styles.displaynone
                )}
              >
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid className={classNames(styles.algnitem)} xs={10}>
                    <Typography className={classNames(styles.bluetext)}>
                      Side B
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <img src={Colorsform} alt="Colorsform" />
                  </Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>CMYK print file ink coverage</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="0"
                          defaultValue={
                            whiteSpaceNumber(
                              allInputs.jobInfoInputs.back
                                .cmykPrintFileInkCoverage
                            ) ?? 0
                          }
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.back.cmykPrintFileInkCoverage"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <Opacity color="primary" />
                  </Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography> Spot UV varnish coverage</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="0"
                          defaultValue={
                            whiteSpaceNumber(
                              allInputs.jobInfoInputs.back
                                .embellishmentSpotUvCoverage
                            ) ?? 0
                          }
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.back.embellishmentSpotUvCoverage"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Varnish 2D / 3D effect </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        margin="none"
                      >
                        <Controller
                          render={() => (
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              onChange={(e) => {
                                setValue(
                                  "jobInfoInputs.back.embellishmentUv2d3dEffect",
                                  e.target.value
                                );
                                setEmbellishmentUv2d3dEffectBack(
                                  e.target.value as number
                                );
                              }}
                              input={<BootstrapInput />}
                              value={embellishmentUv2d3dEffectBack}
                              defaultValue={
                                allInputs.jobInfoInputs.back
                                  .embellishmentUv2d3dEffect ?? 7
                              }
                            >
                              <MenuItem value={0}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={14}>14</MenuItem>
                              <MenuItem value={28}>28</MenuItem>
                              <MenuItem value={35}>35</MenuItem>
                              <MenuItem value={42}>42</MenuItem>
                              <MenuItem value={56}>56</MenuItem>
                              <MenuItem value={63}>63</MenuItem>
                              <MenuItem value={77}>77</MenuItem>
                              <MenuItem value={112}>112</MenuItem>
                            </Select>
                          )}
                          name="jobInfoInputs.back.embellishmentUv2d3dEffect"
                          control={control}
                          defaultValue={
                            allInputs.jobInfoInputs.back
                              .embellishmentUv2d3dEffect ?? 7
                          }
                        />
                      </FormControl>
                      <Typography
                        className={classNames(
                          styles.paddingl10,
                          styles.denomination
                        )}
                      >
                        µm
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>OPV (Protective Varnish) covering</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="0"
                          defaultValue={allInputs.jobInfoInputs.back.opv ?? 0}
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.back.opv"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>OPV 2D / 3D effect </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        margin="none"
                      >
                        <Controller
                          render={() => (
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              onChange={(e) => {
                                setValue(
                                  "jobInfoInputs.back.opv2d3dEffect",
                                  e.target.value
                                );
                                setOpv2d3dEffectBack(e.target.value as number);
                              }}
                              input={<BootstrapInput />}
                              value={opv2d3dEffectBack}
                              defaultValue={
                                allInputs.jobInfoInputs?.back.opv2d3dEffect ?? 0
                              }
                            >
                              <MenuItem value={0}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={3}>3 (Satin / Matte)</MenuItem>
                              <MenuItem value={6}>6 (Semi Glossy)</MenuItem>
                              <MenuItem value={9}>9 (Glossy)</MenuItem>
                              <MenuItem value={12}>12</MenuItem>
                              <MenuItem value={15}>15</MenuItem>
                            </Select>
                          )}
                          name="jobInfoInputs.back.opv2d3dEffect"
                          control={control}
                          defaultValue={
                            allInputs.jobInfoInputs?.back.opv2d3dEffect ?? 7
                          }
                        />
                      </FormControl>
                      <Typography
                        className={classNames(
                          styles.paddingl10,
                          styles.denomination
                        )}
                      >
                        µm
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <img src={paperoil} alt="Paperoil" />
                  </Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography> Digital Hot-Foil coverage</Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          placeholder="0"
                          defaultValue={
                            whiteSpaceNumber(
                              allInputs.jobInfoInputs.back
                                .digitalHotFoilCoverage
                            ) ?? 0
                          }
                          onKeyUp={(e) => {
                            e.currentTarget.value = whiteSpaceNumber(
                              e.currentTarget.value
                            );
                          }}
                          inputRef={register}
                          name="jobInfoInputs.back.digitalHotFoilCoverage"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(styles.inputcontent, styles.margint15)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Foil 2D / 3D effect </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.alignend}>
                      <FormControl
                        className={styles.yellow}
                        size="small"
                        margin="none"
                      >
                        <Controller
                          render={() => (
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              onChange={(e) => {
                                setValue(
                                  "jobInfoInputs.back.digitalHotFoil2d3dEffect",
                                  e.target.value
                                );
                                setEmbellishment2d3dEffectBack(
                                  e.target.value as number
                                );
                              }}
                              input={<BootstrapInput />}
                              value={embellishment2d3dEffectBack}
                              defaultValue={
                                allInputs.jobInfoInputs.back
                                  .digitalHotFoil2d3dEffect ?? 0
                              }
                            >
                              <MenuItem value={0}>
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={14}>14</MenuItem>
                              <MenuItem value={28}>28</MenuItem>
                              <MenuItem value={35}>35</MenuItem>
                              <MenuItem value={42}>42</MenuItem>
                              <MenuItem value={56}>56</MenuItem>
                              <MenuItem value={63}>63</MenuItem>
                              <MenuItem value={77}>77</MenuItem>
                              <MenuItem value={112}>112</MenuItem>
                            </Select>
                          )}
                          name="jobInfoInputs.back.digitalHotFoil2d3dEffect"
                          control={control}
                          defaultValue={
                            allInputs.jobInfoInputs.back
                              .digitalHotFoil2d3dEffect ?? 7
                          }
                        />
                      </FormControl>
                      <Typography
                        className={classNames(
                          styles.paddingl10,
                          styles.denomination
                        )}
                      >
                        µm
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Optimized foil usage side B */}
              <Grid
                className={classNames(
                  styles.blueborder,
                  (!isDoubleSided || !hasFoilBack) && styles.displaynone
                )}
              >
                <Grid
                  xs={12}
                  // className={classNames(!hasDigitalHotFoil && styles.disabled)}
                >
                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15
                    )}
                    xs={12}
                  >
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography>Optimized Foil usage /sheet</Typography>
                      </Grid>
                      <Grid xs={4}></Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Maximized Foil width usage-roll(s) width
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={classNames(styles.alignend)}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                          disabled={!hasDigitalHotFoil}
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">mm</InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="700"
                            inputRef={register}
                            name="jobInfoInputs.back.optimizedFoilUsagePerSheet.maximizedFoilWidthUsageRollWidth"
                            defaultValue={
                              (allInputs.jobInfoInputs?.back
                                .optimizedFoilUsagePerSheet
                                ?.maximizedFoilWidthUsageRollWidth &&
                                whiteSpaceNumber(
                                  allInputs.jobInfoInputs?.back
                                    .optimizedFoilUsagePerSheet
                                    ?.maximizedFoilWidthUsageRollWidth
                                )) ??
                              700
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Maximized Foil length usage /sheet
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend}>
                        <FormControl
                          className={styles.yellow}
                          size="small"
                          variant="outlined"
                          disabled={!hasDigitalHotFoil}
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">mm</InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="1000"
                            inputRef={register}
                            name="jobInfoInputs.back.optimizedFoilUsagePerSheet.maximizedFoilLengthUsagePerSheet"
                            defaultValue={
                              (allInputs.jobInfoInputs?.back
                                .optimizedFoilUsagePerSheet
                                ?.maximizedFoilLengthUsagePerSheet &&
                                whiteSpaceNumber(
                                  allInputs.jobInfoInputs?.back
                                    .optimizedFoilUsagePerSheet
                                    ?.maximizedFoilLengthUsagePerSheet
                                )) ??
                              1000
                            }
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Foil waste / Roll Up-&-Down
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend}>
                        <Typography variant="h6">
                          {formatNumber(50.8)} mm
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid className={styles.iconcontent} xs={2}></Grid>
                    <Grid xs={10} className={styles.dispflex}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littletext)}>
                          Total Foil length usage /sheet (incl. waste)
                        </Typography>
                      </Grid>
                      <Grid
                        xs={4}
                        className={classNames(
                          styles.alignend,
                          styles.bluecontent,
                          styles.fitcontent
                        )}
                      >
                        <Typography variant="h6">
                          {whiteSpaceNumber(
                            formatNumber(totalFoilLengthUsageSheetBack)
                          )}{" "}
                          mm
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* END SIDE B */}

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <AccountBoxOutlined color="primary" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>N° of ALPHAJET operators</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">pers</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="1"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.jobInfoInputs?.alphaJetInfos
                              .alphaJetOperatorsNumber
                          ) ?? 1
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="jobInfoInputs.alphaJetInfos.alphaJetOperatorsNumber"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Cost per operator /h</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="30"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.jobInfoInputs?.alphaJetInfos
                              .costPerOperatorPerHour
                          ) ?? 30
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="jobInfoInputs.alphaJetInfos.costPerOperatorPerHour"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={3}>
                    <Typography>Price</Typography>
                  </Grid>
                  <Grid xs={9} className={styles.alignend}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={isInKg.toString()}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="small" color="primary" />}
                        label="per kg"
                        name="jobInfoInputs.isInKg"
                        onClick={() => setIsInKg(true)}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="small" color="primary" />}
                        label="per 1000 sheets"
                        name="jobInfoInputs.isInKg"
                        onClick={() => setIsInKg(false)}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
                style={{ display: !isInKg ? "none" : "flex" }}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <img color="primary" src={onek} alt="onek" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Substrate cost /kg</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="300"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.jobInfoInputs?.alphaJetInfos
                              .substratCostPerKg
                          ) ?? 3
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name={"jobInfoInputs.alphaJetInfos.substratCostPerKg"}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
                style={{ display: isInKg ? "none" : "flex" }}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <img color="primary" src={onek} alt="onek" />
                </Grid>

                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Substrate cost /1000 sheets</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="300"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.jobInfoInputs?.alphaJetInfos
                              .substratCostPer1000Sheet
                          ) ?? 170
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name={
                          "jobInfoInputs.alphaJetInfos.substratCostPer1000Sheet"
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Substrate cost for job</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <Typography
                      className={classNames(
                        styles.bluecontent,
                        styles.fitcontent,
                        styles.paddingtblittle
                      )}
                      variant="h6"
                    >
                      {materialCostForJob &&
                        whiteSpaceNumber(formatNumber(materialCostForJob))}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={12}>
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Internal Eco Consumption </Typography>
                    </Grid>
                    <Grid
                      xs={4}
                      className={classNames(
                        styles.justifyend,
                        styles.inputecoconso
                      )}
                    >
                      <Controller
                        name="jobInfoInputs.internalEcoConso"
                        control={control}
                        render={() => (
                          <Checkbox
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onChange={(e) =>
                              setInternalEcoConso(e.target.checked)
                            }
                            checked={internalEcoConso}
                            defaultValue={"false"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <SpeedOutlined color="primary" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>ALPHAJET printing speed /h</Typography>
                  </Grid>

                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={classNames(styles.fildesetpb)}
                      //className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">/h</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="1800"
                        className={classNames(
                          styles.blueInput,
                          styles.fitcontent
                        )}
                        readOnly
                        // defaultValue={
                        //   whiteSpaceNumber(
                        //     allInputs.jobInfoInputs?.alphaJetInfos
                        //       .alphaJetPrintingSpeedPerHour
                        //   ) ?? 1800
                        // }
                        // onKeyUp={(e) => {
                        //   e.currentTarget.value = whiteSpaceNumber(
                        //     e.currentTarget.value
                        //   );
                        // }}
                        value={whiteSpaceNumber(alphaJetSpeed)}
                        inputRef={register}
                        name="jobInfoInputs.alphaJetInfos.alphaJetPrintingSpeedPerHour"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>ALPHAJET printing speed /h</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <Typography
                      className={classNames(
                        styles.bluecontent,
                        styles.fitcontent
                      )}
                      variant="h6"
                    >
                      {whiteSpaceNumber(alphaJetSpeed) ?? 1800} /h
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>ALPHAJET productivity (OEE)</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="80"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.jobInfoInputs?.alphaJetInfos
                              .alphaJetProductivity
                          ) ?? 80
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="jobInfoInputs.alphaJetInfos.alphaJetProductivity"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Scenario </Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      margin="none"
                    >
                      <Controller
                        render={() => (
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            onChange={(e) => {
                              setValue(
                                "jobInfoInputs.alphaJetInfos.scenario",
                                e.target.value
                              );
                              setScenario(e.target.value as ScenarioType);
                            }}
                            input={<BootstrapInput />}
                            value={scenario}
                            defaultValue={
                              allInputs.jobInfoInputs.alphaJetInfos.scenario ??
                              "job"
                            }
                          >
                            <MenuItem value={"job"}>Job Sale Price</MenuItem>
                            <MenuItem value={"customer"}>
                              Customer Cost of Production
                            </MenuItem>
                            <MenuItem value={"subcontract"}>
                              Customer Subcontracting Price
                            </MenuItem>
                          </Select>
                        )}
                        name="jobInfoInputs.alphaJetInfos.scenario"
                        control={control}
                        defaultValue={
                          allInputs.jobInfoInputs.alphaJetInfos.scenario ??
                          "job"
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <img src={euro} alt="Euro" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>{scenarioText(scenario)}</Typography>
                  </Grid>
                  <Grid xs={4} className={styles.alignend}>
                    <FormControl
                      className={styles.yellow}
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment position="end">€</InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="2 200.00"
                        defaultValue={
                          whiteSpaceNumber(
                            allInputs.jobInfoInputs?.alphaJetInfos.jobSalesPrice
                          ) ?? 2200
                        }
                        onKeyUp={(e) => {
                          e.currentTarget.value = whiteSpaceNumber(
                            e.currentTarget.value
                          );
                        }}
                        inputRef={register}
                        name="jobInfoInputs.alphaJetInfos.jobSalesPrice"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={6}></Grid>
                  <Grid xs={6}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <Prevnext isInputForm previous="calibration" next="time-labor" />
    </form>
  );
};
export default Jobinfo;

function setPrintingSpeed(hasFoil: boolean, thickness: number) {
  if (thickness > 27) {
    return 1440;
  } else return 1800;
}

function scenarioText(scenario: ScenarioType): string {
  switch (scenario) {
    case "job":
      return "Job Sales Price";
    case "customer":
      return "Customer Cost of Production";
    case "subcontract":
      return "Customer Subcontracting Price";
  }
}
