import React, { useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";
import moredetail from "../image/icon/arrow.svg";

import Grid from "@material-ui/core/Grid";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Restart from "@material-ui/icons/Autorenew";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  AllInputs,
  AlphaJetGlobalPrintingCosts,
  totalConsumableCosts,
} from "./roiTypes";
import { formatNumber, whiteSpaceNumber } from "./formatNumber";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.common.black,
    padding: "13px",
    "&:first-child ": {
      border: "none",
      fontWeight: "500",
      fontSize: "1.2rem !important",
    },
  },
  body: {
    padding: "13px",
    border: "none",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",

    "&:first-child ": {
      backgroundColor: "#FFFFFF",
      border: "none",
      fontWeight: "600",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:last-child ": {
      borderBottom: "1px solid #FFFFFF",
    },
  },
}))(TableRow);

function createData(
  name: string,
  lifeTime: string,
  quantity: number,
  price: string,
  costPerHour: string
) {
  return { name, lifeTime, quantity, price, costPerHour };
}

const alphaJetConsumableCosts = new AlphaJetGlobalPrintingCosts();

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  tablecontainer: {
    boxShadow: "none",
  },
});

const Cusumables = ({ allInputs }: { allInputs: AllInputs }) => {
  const classes = useStyles();

  const rows: {
    name: string;
    lifeTime: string;
    quantity: number;
    price: string;
    costPerHour: string;
  }[] = [];

  Object.values(alphaJetConsumableCosts.table).forEach((value) =>
    rows.push(
      createData(
        value.name,
        whiteSpaceNumber(value.lifetime),
        value.quantity,
        isIncluded(value.cost, allInputs),
        isIncluded(value.getCostPerHour(), allInputs)
      )
    )
  );

  const [isVisibledetail, setDetail] = useState(
    allInputs.calibrationInputs.yearlyMaintenanceContract
      .maintenanceContractLength === 0
  );
  const showdetail = () => setDetail((isVisibledetail) => !isVisibledetail);
  return (
    <div className={classNames(styles.customer, styles.height100)}>
      <Grid xs={12} className={classNames(styles.paddinglr10, styles.topform)}>
        <Grid className={styles.inputcontent} xs={12}>
          <Grid xs={1}></Grid>
          <Grid xs={11}>
            <Typography variant="h5">ALPHAJET CONSUMABLES</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} className={classNames(styles.dispflex, styles.margint15)}>
        <Grid xs={1} className={styles.iconcenter}>
          <Restart color="primary" />
        </Grid>
        <Grid xs={11}>
          <TableContainer className={classes.tablecontainer} component={Paper}>
            <Table
              className={classNames(
                styles.showtable,
                !isVisibledetail ? styles.hidetable : ""
              )}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell className={styles.colorblue}>
                    Consumables
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    Lifetime hours
                  </StyledTableCell>
                  <StyledTableCell align="right">Quantity</StyledTableCell>
                  <StyledTableCell align="right">Price in €</StyledTableCell>
                  <StyledTableCell align="right">
                    Cost per hour in €
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.lifeTime}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.price}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.costPerHour}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid xs={12} className={styles.alignend6}>
        <Grid xs={7}>
          <Grid
            className={classNames(
              styles.moredetailbuttonzone,
              styles.noflexend
            )}
            xs={12}
          >
            {!isVisibledetail ? (
              <Grid className={classNames(styles.titletop)}>
                <Typography variant="h6">
                  Recommended AlphaJET Consumables
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            <Grid
              onClick={showdetail}
              className={classNames(styles.moredetailbutton)}
            >
              <p>{isVisibledetail ? "Less details" : "More details"}</p>
              <img
                className={classNames(
                  isVisibledetail ? styles.rotate180 : "",
                  styles.moredetailbutton
                )}
                src={moredetail}
                alt="Arrow"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4}>
          <Grid
            xs={12}
            className={classNames(styles.bluecontent, styles.margint15)}
          >
            <Grid xs={3} className={styles.inputcontent}>
              <Typography variant="h6">TOTAL</Typography>
            </Grid>
            <Grid xs={9} className={styles.alignend6}>
              <Typography variant="h6" color="primary">
                {isIncluded(totalConsumableCosts(), allInputs)}
              </Typography>
              <Typography className={styles.marginr15} variant="h6">
                {isIncluded(totalConsumableCosts(), allInputs) ? "" : "€"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Elements rajoutés lorsque maintenance contract est ajouté */}
      {allInputs.calibrationInputs.yearlyMaintenanceContract
        .maintenanceContractLength > 0 && (
        <Grid xs={12} className={classNames(styles.margint15)}>
          <Grid
            xs={12}
            className={classNames(styles.dispflex, styles.margint15)}
          >
            <Grid xs={1} className={styles.iconcenter}></Grid>
            <Grid xs={11} className={classNames(styles.dispflex)}>
              <Grid xs={8}>
                <Typography variant="h6">
                  MGI Predict IoT 4.0 Maintenance
                </Typography>
              </Grid>
              <Grid xs={4} className={classNames(styles.alignend6)}>
                <Typography variant="h6">Included</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} className={classNames(styles.dispflex)}>
            <Grid xs={1} className={styles.iconcenter}></Grid>
            <Grid xs={11} className={classNames(styles.dispflex)}>
              <Grid xs={8}>
                <Typography variant="h6">
                  MGI Dashboards Production Live Follow-up & Tracking{" "}
                </Typography>
              </Grid>
              <Grid xs={4} className={classNames(styles.alignend6)}>
                <Typography variant="h6">Included</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} className={classNames(styles.dispflex)}>
            <Grid xs={1} className={styles.iconcenter}></Grid>
            <Grid xs={11} className={classNames(styles.dispflex)}>
              <Grid xs={8}>
                <Typography variant="h6">
                  x2 Monthly Engineers Predictive Visits
                </Typography>
              </Grid>
              <Grid xs={4} className={classNames(styles.alignend6)}>
                <Typography variant="h6">Included</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} className={classNames(styles.dispflex)}>
            <Grid xs={1} className={styles.iconcenter}></Grid>
            <Grid xs={11} className={classNames(styles.dispflex)}>
              <Grid xs={8}>
                <Typography variant="h6">
                  MGI Hotline Unlimited Access
                </Typography>
              </Grid>
              <Grid xs={4} className={classNames(styles.alignend6)}>
                <Typography variant="h6">Included</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} className={classNames(styles.dispflex)}>
            <Grid xs={1} className={styles.iconcenter}></Grid>
            <Grid xs={11} className={classNames(styles.dispflex)}>
              <Grid xs={8}>
                <Typography variant="h6">
                  Technician Equipment Interventions if needed
                </Typography>
              </Grid>
              <Grid xs={4} className={classNames(styles.alignend6)}>
                <Typography variant="h6">Included</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default Cusumables;

export function isIncluded(value: number, allInputs: AllInputs) {
  return allInputs.calibrationInputs.yearlyMaintenanceContract
    .maintenanceContractLength > 0
    ? "Included"
    : whiteSpaceNumber(formatNumber(value));
}
