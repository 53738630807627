import React from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import customer from "../image/icon/customer.svg";
import calibration from "../image/icon/calibration.svg";
import jobinfo from "../image/icon/jobinfo.svg";
import timelabor from "../image/icon/timelabor.svg";
import consummable from "../image/icon/consummable.svg";
import capex from "../image/icon/capex.svg";
import foilcost from "../image/icon/foilcost.svg";
import machinetimecost from "../image/icon/machinetimecost.svg";
import result from "../image/icon/result.svg";
import roi from "../image/icon/roi.svg";
import { useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { AllInputs } from "./roiTypes";

const Switchpage = ({
  setClicked,
  allInputs,
}: {
  setClicked: React.Dispatch<string>;
  allInputs: AllInputs;
}) => {
  const location = useLocation();

  const pathName = location.pathname.substring(1);
  const isInputForm =
    !location.pathname.includes("customer") &&
    !location.pathname.includes("calibration") &&
    !location.pathname.includes("job-info") &&
    !location.pathname.includes("result") &&
    !location.pathname.includes("roi");
  return (
    <Grid
      style={{
        width: !isInputForm ? "100%" : "",
        bottom: !isInputForm ? "-85px" : "",
        //  maxWidth: !isInputForm ? "calc(100% - 220px)" : "",
        //   marginLeft: !isInputForm ? "110px" : "",
        position: !isInputForm ? "absolute" : "absolute",
        paddingLeft: !isInputForm ? "0" : "",
        paddingRight: !isInputForm ? "0" : "",
      }}
      className={styles.topswitchpagezone}
      xs={12}
    >
      <Grid className={styles.content} xs={12} sm={12} md={12} lg={11} xl={10}>
        <Grid
          className={classNames(
            styles.onezone,
            styles.zonethree,
            styles.separation
          )}
          xs={3}
        >
          <div>
            <button
              className={classNames(
                pathName.includes("customer") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("customer")}
            >
              <img src={customer} alt="customer" />
              <p>Customer</p>
            </button>
          </div>
          <div>
            <button
              className={classNames(
                pathName.includes("calibration") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("calibration")}
            >
              <img src={calibration} alt="calibration" />
              <p>Calibration</p>
            </button>
          </div>
          <div>
            <button
              className={classNames(
                pathName.includes("job-info") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("job-info")}
            >
              <img src={jobinfo} alt="jobinfo" />
              <p>Job Info</p>
            </button>
          </div>
        </Grid>

        <Grid
          className={classNames(
            styles.onezone,
            styles.zonefive,
            styles.separation
          )}
          xs={5}
        >
          <div>
            <button
              className={classNames(
                pathName.includes("time-labor") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("time-labor")}
            >
              <img src={timelabor} alt="timelabor" />
              <p>time &amp; labor</p>
            </button>
          </div>
          <div>
            <button
              className={classNames(
                pathName.includes("consumables") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("consumables")}
            >
              <img src={consummable} alt="consummable" />
              <p>Consumables</p>
            </button>
          </div>
          <div>
            <button
              className={classNames(
                pathName.includes("capex") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("capex")}
            >
              <img src={capex} alt="capex" />
              <p>Capex Breakdown </p>
            </button>
          </div>

          {allInputs.jobInfoInputs.front?.digitalHotFoilCoverage > 0 ||
          allInputs.jobInfoInputs.back?.digitalHotFoilCoverage > 0 ? (
            <div>
              <button
                className={classNames(
                  pathName.includes("foil-costs") && styles.selectedbutton
                )}
                type="submit"
                onClick={() => setClicked("foil-costs")}
              >
                <img src={foilcost} alt="foilcost" />
                <p>Foil cost</p>
              </button>
            </div>
          ) : (
            ""
          )}
          <div>
            <button
              className={classNames(
                pathName.includes("machine-time-costs") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("machine-time-costs")}
            >
              <img src={machinetimecost} alt="machinetimecost" />
              <p>Machine time cost</p>
            </button>
          </div>
        </Grid>

        <Grid className={classNames(styles.onezone, styles.zonetwo)} xs={2}>
          <div>
            <button
              className={classNames(
                pathName.includes("result") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("result")}
            >
              <img src={result} alt="result" />
              <p>Result</p>
            </button>
          </div>
          <div>
            <button
              className={classNames(
                pathName.includes("roi") && styles.selectedbutton
              )}
              type="submit"
              onClick={() => setClicked("roi")}
            >
              <img src={roi} alt="roi" />
              <p>ROI</p>
            </button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Switchpage;
