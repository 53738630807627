import dataCapex from "./dataCapex";
import dataFoilCost from "./dataFoilCost";
import dataMachineCost from "./dataMachineCost";
import dataTimeLabor from "./dataTimeLabor";
import { AllInputs, FaceInfos, TotalSetUpTime } from "./roiTypes";

export default function dataResult(allInputs: AllInputs, overhead: number) {
  const rDataTimeLabor = dataTimeLabor(allInputs);

  const rDataMachineTimeCost = dataMachineCost(allInputs);

  const totalSetUpTime = new TotalSetUpTime();

  const totalNOSheetByJob = allInputs.jobInfoInputs
    ? allInputs.jobInfoInputs?.numberOfSheets
    : 0;
  const costOfMaterial = allInputs.jobInfoInputs
    ? allInputs.jobInfoInputs.paperCostForJob ??
      (allInputs.jobInfoInputs?.alphaJetInfos.substratCostPer1000Sheet / 1000) *
        totalNOSheetByJob
    : 0;
  const costOfPrimer =
    (allInputs.jobInfoInputs
      ? (allInputs.calibrationInputs.alphaJetGlobalPrintingCosts.costPrimer /
          1360) *
        totalNOSheetByJob
      : 0) *
    ((((allInputs.jobInfoInputs.paperFormat.length *
      allInputs.jobInfoInputs.paperFormat.width) /
      700) *
      1000) /
      1000000);

  const internalEcoConso = allInputs.jobInfoInputs.internalEcoConso ? 0.8 : 1;

  const costofCMYKPrintFront = allInputs.jobInfoInputs
    ? 0.00617 *
      allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
        .alphaJetGlobalPrintingCost *
      (allInputs.jobInfoInputs.front.cmykPrintFileInkCoverage / 100) *
      internalEcoConso *
      totalNOSheetByJob
    : 0;

  const costofCMYKPrintBack = allInputs.jobInfoInputs
    ? 0.00617 *
      allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
        .alphaJetGlobalPrintingCost *
      (allInputs.jobInfoInputs.back.cmykPrintFileInkCoverage / 100) *
      internalEcoConso *
      totalNOSheetByJob
    : 0;

  const tempCostCMYKFront =
    (costofCMYKPrintFront *
      (((allInputs.jobInfoInputs.paperFormat.length *
        allInputs.jobInfoInputs.paperFormat.width) /
        700) *
        1000)) /
    1000000;
  const tempCostCMYKBack =
    (costofCMYKPrintBack *
      (((allInputs.jobInfoInputs.paperFormat.length *
        allInputs.jobInfoInputs.paperFormat.width) /
        700) *
        1000)) /
    1000000;

  const finalCostOfCMYKPrint = allInputs.jobInfoInputs.doubleSided
    ? tempCostCMYKFront + tempCostCMYKBack
    : tempCostCMYKFront;

  //=Input!C22*  -> Sales Calculation'!C15 -> ='Spot UV Cost'!G27 -> G22 -> =PRODUIT(G6;G21)

  //G6 -> allInputs.calibrationInputs?.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter;

  //G21 -> G19 / G17
  //G17 -> getSurfaceVarnish(allInputs.jobInfoInputs.embellishment2d3dEffect);
  // G19 ->G18 * H8
  //G18 -> =((G7*H7*G20)/1000000)
  //G7 -> D6 -> paper format length allInputs.jobInfoInputs.paperFormat.length
  //H7 -> allInputs.jobInfoInputs.paperFormat.width
  // G20 -> =PRODUIT(D5;J20)+D5 ->
  // D5 -> allInputs.jobInfoInputs.numberOfSheets
  // J20 -> 0 en dur
  // H8 ->allInputs.jobInfoInputs.embellishmentSpotUvCoverage

  const finalFoilCost = getFinalFoilCost();

  const costofVarnishUsedFront = getFaceVarnishCost(
    allInputs.jobInfoInputs.front
  );

  const costofVarnishUsedBack = getFaceVarnishCost(
    allInputs.jobInfoInputs.back
  );

  const opvCostFront = getFaceOpvCost(allInputs.jobInfoInputs.front);
  const opvCostBack = getFaceOpvCost(allInputs.jobInfoInputs.back);

  const finalOpvCost = allInputs.jobInfoInputs.doubleSided
    ? opvCostFront + opvCostBack
    : opvCostFront;

  const finalCostOfVarnishUsed = allInputs.jobInfoInputs.doubleSided
    ? costofVarnishUsedFront + costofVarnishUsedBack
    : costofVarnishUsedFront;

  function getFaceVarnishCost(face: FaceInfos) {
    const g18 = allInputs.jobInfoInputs
      ? (allInputs.jobInfoInputs.paperFormat.length *
          allInputs.jobInfoInputs.paperFormat.width *
          allInputs.jobInfoInputs.numberOfSheets) /
        1000000
      : 0;

    const g19 = allInputs.jobInfoInputs
      ? g18 * (face.embellishmentSpotUvCoverage / 100)
      : 0;
    const g21 = allInputs.jobInfoInputs
      ? g19 / getSurfaceVarnish(face.embellishmentUv2d3dEffect)
      : 0;

    const costofVarnishUsedFront = allInputs.calibrationInputs
      ?.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter
      ? allInputs.calibrationInputs?.alphaJetGlobalPrintingCosts
          .costPerUvVarnishLiter * g21
      : 0;

    return costofVarnishUsedFront;
  }

  function getFaceOpvCost(face: FaceInfos) {
    const g18 = allInputs.jobInfoInputs
      ? (allInputs.jobInfoInputs.paperFormat.length *
          allInputs.jobInfoInputs.paperFormat.width *
          allInputs.jobInfoInputs.numberOfSheets) /
        1000000
      : 0;

    const opvCost = allInputs.jobInfoInputs
      ? (g18 * (face.opv / 100)) / getSurfaceVarnish(face.opv2d3dEffect)
      : 0;

    const costOfVarnishWithOPVFront = allInputs.calibrationInputs
      ?.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter
      ? allInputs.calibrationInputs?.alphaJetGlobalPrintingCosts
          .costPerUvVarnishLiter * opvCost
      : 0;

    return costOfVarnishWithOPVFront;
  }

  function getSurfaceVarnish(thickness: number): number {
    return Math.round(995.4 / thickness);
  }

  const foilCosts = dataFoilCost(allInputs).costPerJobIn;
  // D40 -> C12*60 -> C12 : Time and labor B14 ->
  //  -> B12 + B8
  // B12 -> H26 ->
  // =('Job Information'!B11)/(H23/60)
  // Job Information'!B11 -> allInputs.jobInfoInputs.numberOfSheets
  // H23 -> 1800* (70/100)
  //
  // B8 -> somme de timeAndLabor: {totalSetUpTime: {

  function getFinalFoilCost() {
    const g18 = allInputs.jobInfoInputs
      ? (allInputs.jobInfoInputs.paperFormat.length *
          allInputs.jobInfoInputs.paperFormat.width *
          allInputs.jobInfoInputs.numberOfSheets) /
        1000000
      : 0;

    const g19Front = allInputs.jobInfoInputs
      ? g18 * (allInputs.jobInfoInputs.front.digitalHotFoilCoverage / 100)
      : 0;
    const g19Back = allInputs.jobInfoInputs
      ? g18 * (allInputs.jobInfoInputs.back.digitalHotFoilCoverage / 100)
      : 0;

    const coverageFront =
      g19Front /
      getSurfaceVarnish(allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect);
    const coverageBack =
      g19Back /
      getSurfaceVarnish(allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect);

    const costofVarnishUsedFront = allInputs.calibrationInputs
      ?.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter
      ? allInputs.calibrationInputs?.alphaJetGlobalPrintingCosts
          .costPerUvVarnishLiter * coverageFront
      : 0;

    const costofVarnishUsedBack = allInputs.calibrationInputs
      ?.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter
      ? allInputs.calibrationInputs?.alphaJetGlobalPrintingCosts
          .costPerUvVarnishLiter * coverageBack
      : 0;

    return {
      front: costofVarnishUsedFront,
      back: costofVarnishUsedBack,
      total: allInputs.jobInfoInputs.doubleSided
        ? allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0 ||
          allInputs.jobInfoInputs.back.digitalHotFoilCoverage > 0
          ? costofVarnishUsedFront + costofVarnishUsedBack
          : 0
        : allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0
        ? costofVarnishUsedFront
        : 0,
    };
  }

  const totalTimeToProduceAt = secondsToDhms(rDataTimeLabor.totalTime * 60);

  function secondsToDhms(seconds: number) {
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);

    var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes") : "";
    return dDisplay + hDisplay + mDisplay;
  }

  const dataFMachineCost = dataMachineCost(allInputs);

  const localPaperLoading =
    (totalSetUpTime.getTotalTime(allInputs.jobInfoInputs.doubleSided) +
      rDataTimeLabor.pureProductionTime) /
    60;

  const localTotalCostWithoutCapex = dataFMachineCost.totalCostWithouthCapex
    ? dataFMachineCost.totalCostWithouthCapex
    : 0;
  const amortizedCosts = localPaperLoading * localTotalCostWithoutCapex;

  const totalCostofJob =
    finalCostOfVarnishUsed +
    costOfMaterial +
    costOfPrimer +
    finalCostOfCMYKPrint +
    amortizedCosts +
    foilCosts +
    finalFoilCost.total +
    finalOpvCost;

  const retailCostofJob = allInputs.jobInfoInputs
    ? allInputs.jobInfoInputs?.alphaJetInfos.jobSalesPrice
    : 0;

  const grossProfitMargin = retailCostofJob - totalCostofJob;

  const totalGrossProfitMargin = grossProfitMargin - overhead;
  const grossProfit = 100 * (grossProfitMargin / retailCostofJob);
  const localNumbOfsheet = allInputs.jobInfoInputs
    ? allInputs.jobInfoInputs?.numberOfSheets
    : 0;

  const dataFCapex = dataCapex(allInputs);

  const c43 = dataFCapex.investment
    ? dataFCapex.investment +
      dataFCapex.interest +
      dataFCapex.maintenanceContract
    : 0;
  const c41 = allInputs.calibrationInputs
    ? allInputs.calibrationInputs?.amortizationPeriod / 12
    : 0;
  const c45 = c43 / c41 / 12;
  const c47 = totalGrossProfitMargin;
  const c48 = c45 / c47;
  const e47 = localNumbOfsheet;
  const monthlySheetsNeededToReachBreakEvent = c48 * e47;

  const monthlyJobsNeededBreakEvent = Math.floor(c45 / c47);
  const localMonthByYear = allInputs.calibrationInputs
    ? allInputs.calibrationInputs.yearlyMaintenanceContract.monthPerYear
    : 0;

  const yearVolumeOfSheetsToReachBreakEvent =
    monthlySheetsNeededToReachBreakEvent * localMonthByYear;
  const rOIAmortizationToBreakEvenMonthlyRent =
    dataFCapex.monthlyCosttoAmortizeEquipment;

  const hourlyCapexCost = rDataMachineTimeCost.hourlyCapexAmortization;
  //(rDataTimeLabor.totalTime / 60) * rDataMachineTimeCost.totalCostWithCapex;

  return {
    totalNOSheetByJob: totalNOSheetByJob,
    costOfMaterial: costOfMaterial,
    costOfPrimer: costOfPrimer,
    costofCMYKPrint: finalCostOfCMYKPrint,
    costofVarnishUsed: finalCostOfVarnishUsed,
    finalOpvCost: finalOpvCost,
    foilCosts: foilCosts + finalFoilCost.total,
    totaltimetoproduceAt: totalTimeToProduceAt,
    amortizedCosts: amortizedCosts,
    totalCostofJob: totalCostofJob,
    retailCostofJob: retailCostofJob,
    totalGrossProfitMargin: grossProfitMargin,
    grossProfit: grossProfit,
    rOIAmortizationToBreakEvenMonthlyRent:
      rOIAmortizationToBreakEvenMonthlyRent,
    yearVolumeOfSheetsToReachBreakEvent: yearVolumeOfSheetsToReachBreakEvent,
    monthlySheetsNeededToReachBreakEvent: monthlySheetsNeededToReachBreakEvent,
    monthlyJobsNeededBreakEvent: monthlyJobsNeededBreakEvent,
    hourlyCapexCost: (hourlyCapexCost * rDataTimeLabor.totalTime) / 60,
    conso: {
      front: {
        cmyk:
          tempCostCMYKFront /
          allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
            .alphaJetGlobalPrintingCost,
        foil:
          finalFoilCost.front /
          allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
            .costPerUvVarnishLiter,
        varnish:
          costofVarnishUsedFront /
            allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
              .costPerUvVarnishLiter +
          finalFoilCost.front /
            allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
              .costPerUvVarnishLiter,
        opv:
          opvCostFront /
          allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
            .costPerUvVarnishLiter,
      },
      back: {
        cmyk:
          tempCostCMYKBack /
          allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
            .alphaJetGlobalPrintingCost,
        foil:
          finalFoilCost.back /
          allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
            .costPerUvVarnishLiter,
        varnish:
          costofVarnishUsedBack /
            allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
              .costPerUvVarnishLiter +
          finalFoilCost.back /
            allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
              .costPerUvVarnishLiter,
        opv:
          opvCostBack /
          allInputs.calibrationInputs.alphaJetGlobalPrintingCosts
            .costPerUvVarnishLiter,
      },
    },
  };
}
