import React from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Paperoil from "../image/icon/paper-roll.svg";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { AllInputs } from "./roiTypes";
import dataFoilCost from "./dataFoilCost";
import { formatNumber, whiteSpaceNumber } from "./formatNumber";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.common.black,

    padding: "13px",

    "&:first-child ": {
      border: "none",
      width: "11vw",
      color: "blue",
      fontWeight: "500",
      fontSize: "1.2rem !important",
    },
  },
  body: {
    padding: "13px",
    border: "none",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child ": {
      backgroundColor: "#FFFFFF",
      border: "none",
      width: "11vw",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:last-child ": {
      borderBottom: "1px solid #FFFFFF",
    },
  },
}))(TableRow);
function createData(
  name: string,
  calories: string,
  fat: string,
  carbs: string,
  protein: string,
  scrubs: string
) {
  return { name, calories, fat, carbs, protein, scrubs };
}
function createData2(
  name: string,
  calories: string,
  fat: string,
  carbs: string,
  protein: string,
  scrubs: string,
  scru: string
) {
  return { name, calories, fat, carbs, protein, scrubs, scru };
}

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  tablecontainer: {
    boxShadow: "none",
  },
});
const Foilcost = ({ allInputs }: { allInputs: AllInputs }) => {
  const classes = useStyles();

  const dataFCost = dataFoilCost(allInputs);

  const rows = [
    createData(
      "",
      !allInputs.calibrationInputs.isSqm
        ? whiteSpaceNumber(dataFCost.widthofMasterFoilRoll)
        : "Not defined",
      !allInputs.calibrationInputs.isSqm
        ? whiteSpaceNumber(dataFCost.totalLengthofRoll)
        : "Not defined",
      !allInputs.calibrationInputs.isSqm
        ? whiteSpaceNumber(dataFCost.costofMasterRoll)
        : "Not defined",
      whiteSpaceNumber(formatNumber(dataFCost.costPerSquare)),
      ""
    ),
  ];
  const rows2 = [
    createData2(
      "",
      allInputs.jobInfoInputs.doubleSided
        ? whiteSpaceNumber(dataFCost.widthofFoilRollSlitDownFront) +
            " A | " +
            whiteSpaceNumber(dataFCost.widthofFoilRollSlitDownBack) +
            " B"
        : whiteSpaceNumber(dataFCost.widthofFoilRollSlitDownFront),
      allInputs.jobInfoInputs.doubleSided
        ? whiteSpaceNumber(dataFCost.lengthWithWasteFront) +
            " A | " +
            whiteSpaceNumber(dataFCost.lengthWithWasteBack) +
            " B"
        : whiteSpaceNumber(dataFCost.lengthWithWasteFront),
      whiteSpaceNumber(formatNumber(dataFCost.sQMofFoilUsedper, 4)),
      whiteSpaceNumber(formatNumber(dataFCost.costofFoilPerSheetin, 4)),
      whiteSpaceNumber(formatNumber(dataFCost.costPerJobIn, 4)),
      ""
    ),
  ];

  return (
    <div className={styles.customer}>
      <Grid xs={12} className={classNames(styles.paddinglr10, styles.topform)}>
        <Grid className={styles.inputcontent} xs={12}>
          <Grid xs={1}></Grid>
          <Grid xs={11}>
            <Typography variant="h5">FOIL COST</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>

      <Grid xs={11} className={classNames(styles.dispflex, styles.margint15)}>
        <Grid xs={1} className={styles.iconcenter}>
          <img src={Paperoil} alt="paper foil" />
        </Grid>
        <Grid xs={11}>
          <TableContainer className={classes.tablecontainer} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Foil Master Roll</StyledTableCell>
                  <StyledTableCell align="center">
                    Width of Master Foil Roll (mm)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Total Length of Roll (mm)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Cost of Master Roll
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Cost Per Square sqm
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.calories}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.fat}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.carbs}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.protein}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid xs={11} className={styles.dispflex}>
        <Grid xs={1}></Grid>

        <Grid xs={11}>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>

          <TableContainer className={classes.tablecontainer} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Foil File</StyledTableCell>
                  <StyledTableCell align="center">
                    Width of Foil Roll Slit Down (mm)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Length With Waste of Foil / Sheet (mm)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    SQM of Foil Used per Sheet
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Cost of Foil Per Sheet in €
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Cost Per Job in €
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row2) => (
                  <StyledTableRow key={row2.name}>
                    <StyledTableCell component="th" scope="row">
                      {row2.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row2.calories}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row2.fat}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row2.carbs}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classNames(styles.bluecontent)}
                      align="center"
                    >
                      {row2.protein}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classNames(styles.bluecontent)}
                      align="center"
                    >
                      {row2.scrubs}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};
export default Foilcost;
