import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style/Allprojects.module.scss";
import MenuIcon from "@material-ui/icons/Menu";
//import arrowbottom from "../../img/icons/arrowbottom.svg";
//import arrowtop from "../../img/icons/arrowtop.svg";
import mgikm from "../image/logo/kmmgi.svg";
import deleteproj from "../image/icon/delete.svg";

import alphajet from "../image/logo/alphajet.svg";

import {
  Column,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Roi } from "./roiTypes";
import { Link } from "react-router-dom";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import searchImg from "../image/icon/searchimg.svg";
import { useBackend } from "../backend/BackendProvider";
import { KeyedMutator } from "swr";
import HoverActions from "./hoverActions";
import { ThemeType, TopBar, useAuth } from "@mgi-labs/mgi-id";
import i18n from "@mgi-labs/mgi-id/dist/Components/Internationalization/i18n";
//import LogsFilter from "./LogFilter";
import logo from "../image/img/alphajet.png";

const RoiTable = ({
  events,
  filterCustom,
  setFilterCustom,
  selectedRows,
  setSelectedRows,
  mutate,
}: {
  events: Roi[];
  filterCustom: {
    search?: string;
  };
  setFilterCustom: React.Dispatch<
    React.SetStateAction<{
      search?: string;
    }>
  >;
  selectedRows: number[];
  setSelectedRows: React.Dispatch<number[]>;
  mutate: KeyedMutator<Roi[]>;
}) => {
  const handleChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterCustom({ ...filterCustom, search: e.target.value });
    },
    [filterCustom, setFilterCustom]
  );
  const { t } = useTranslation();

  const columns: Column<Roi>[] = useMemo(
    () => [
      {
        Header: t<string>("Name"),
        id: "name",
        accessor: (roi) => {
          return roi.data.roiInfo?.roiName ?? "";
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("Company Name"),
        id: "companyName",
        accessor: (roi) => {
          return roi.data.customerInputs?.companyName ?? "";
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("User"),
        accessor: (roi) => {
          return roi.userName;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("Created"),
        accessor: (roi) => {
          return roi.created;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("Modified"),
        accessor: (roi) => {
          return roi.modified;
        },
        style: classNames(styles.borderright),
      },
      // {
      //   Header: t<string>("Id"),
      //   accessor: (roi) => {
      //     return roi.id;
      //   },
      //   style: classNames(styles.borderright),
      // },
      // {
      //   Header: t<string>("Table.confirm"),
      //   accessor: (roi) => {
      //     return roi.confirm;
      //   },
      //   style: classNames(styles.borderright),
      // },
    ],
    [t]
  );

  const backend = useBackend();

  const handleDuplicateRoi = useCallback(
    async (id: number) => {
      try {
        await backend.duplicateRoi(id);
      } finally {
        mutate();
      }
    },
    [backend, mutate]
  );

  const handleCheckboxChange = useCallback(
    async (val: number) => {
      if (selectedRows.includes(val)) {
        const newselected = selectedRows.filter((s) => s !== val);
        setSelectedRows(newselected);
      } else {
        setSelectedRows([...selectedRows, val]);
      }
    },
    [selectedRows, setSelectedRows]
  );

  // const history = useHistory();

  const handleDeleteProject = useCallback(async () => {
    if (
      window.confirm(
        "Voulez-vous vraiment supprimer les éléments sélectionnés ?"
      )
    ) {
      try {
        await backend.deleteRoi(selectedRows);
      } finally {
        mutate();
      }
    }
  }, [backend, mutate, selectedRows]);

  const handleDeleteOneProject = useCallback(
    async (id: number) => {
      if (
        window.confirm(
          "Voulez-vous vraiment supprimer les éléments sélectionnés ?"
        )
      ) {
        try {
          await backend.deleteRoi([id]);
        } finally {
          mutate();
        }
      }
    },
    [backend, mutate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: events,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    setGlobalFilter(filterCustom.search);
  }, [filterCustom, setGlobalFilter]);

  const { initialized, auth } = useAuth();
  const changeLang = useCallback((lang) => {
    i18n.changeLanguage(lang);
  }, []);
  const [theme] = useState<ThemeType>("light");
  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return (
    <>
      <AppBar
        position="fixed"
        //className={clsx(classes.appBar, {
        //  [classes.appBarShift]: open,
        //})}
        className={classNames(styles.colorBar)}
      >
        <Toolbar className={classNames(styles.toolbar, styles.toolbar2)}>
          <div className={classNames(styles.divHeight)}>
            <img src={mgikm} alt="Logo KMMGI" />
            <img src={alphajet} alt="Logo ALPHAJET" />

            <div className={classNames(styles.navbarzone2)}>
              <TopBar
                logo={logo}
                pageName="CostCalculator"
                currentLang={i18n.language}
                changeLang={changeLang}
                theme={theme}
                // handleUpdateUserTheme={handleUpdateUserTheme}
                backendUrl={process.env["REACT_APP_BACKEND_CONNECT_URL"]}
                // currentConnectPage={location.pathname}
                connectUrl={process.env["REACT_APP_CONNECT_URL"]}
              />
            </div>
          </div>

          <IconButton
            className={classNames(styles.buttonmenu)}
            color="inherit"
            aria-label="open drawer"
            edge="end"
            //className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className={classNames(styles.Allproject_project)}>
        <div className={classNames(styles.inputDiv)}>
          <button
            className={styles.buttonDelete}
            onClick={handleDeleteProject}
            style={{
              visibility: selectedRows.length > 0 ? "visible" : "hidden",
            }}
          >
            <img src={deleteproj} title="Delete" alt="Supprimer le projet" />
          </button>

          <div className={classNames(styles.searchDiv)}>
            <img
              className={classNames(styles.marginRightImg)}
              src={searchImg}
              alt="search"
            />
            <div className={classNames(styles.search)}>
              <input
                defaultValue={filterCustom.search}
                onChange={(e) => handleChangeSearch(e)}
                type="text"
              />
            </div>
          </div>
        </div>
        {/* <div className={classNames(styles.filter_container)}>
          <LogsFilter filter={filterCustom} setFilter={setFilterCustom} />
        </div> */}
        <div className={classNames(styles.table)}>
          <table
            className={classNames(styles.Allproject_projects_section)}
            {...getTableProps()}
          >
            <thead
              className={classNames(
                styles.Allproject_projects_section_sort,
                styles.col_12
              )}
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classNames(styles.Allproject_padding)}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className={classNames(
                        styles.Allproject_projects_sort,
                        i === 0 || i === 1 ? styles.col_3 : styles.col_2
                      )}
                      //{...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {/* <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={arrowtop} alt="arrow top" />
                          ) : (
                            <img src={arrowbottom} alt="arrow bottom" />
                          )
                        ) : (
                          ""
                        )}
                      </span> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={classNames(
                styles.Allproject_projects_content,
                styles.col_12,
                styles.row
              )}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.original.id}>
                    <Link to={"/customer/" + row.original.id}>
                      <tr className={classNames(styles.col_12)}>
                        <td
                          className={classNames(styles.col_3)}
                          style={{ justifyContent: "space-around" }}
                        >
                          <div
                            className={classNames(styles.onecell)}
                            style={{ alignItems: "center" }}
                          >
                            <div
                              className={classNames(styles.borderright)}
                            ></div>
                            <input
                              className={classNames(styles.bat_checkmark)}
                              type="checkbox"
                              id="color"
                              name="process"
                              value="color"
                              checked={selectedRows.includes(row.original.id)}
                              onClick={(e) => e.stopPropagation()}
                              onChange={() =>
                                handleCheckboxChange(row.original.id)
                              }
                            />
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[0].value}</p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_3)}>
                          <div className={classNames(styles.onecell)}>
                            <div
                              className={classNames(styles.borderright)}
                            ></div>

                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[1].value}</p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div
                              className={classNames(styles.borderright)}
                            ></div>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[2].value}</p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>
                                {new Date(
                                  row.cells[3].value
                                ).toLocaleDateString(undefined, {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>
                                {new Date(
                                  row.cells[4].value
                                ).toLocaleDateString(undefined, {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* <td className={classNames(styles.col_3)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[3].value}</p>
                            </div>
                          </div>
                        </td> */}
                        {/* <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div
                              className={classNames(styles.borderright)}
                            ></div>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[4].value.toString()}</p>
                            </div>
                          </div>
                        </td> */}

                        <HoverActions
                          id={row.original.id}
                          handleDuplicateRoi={handleDuplicateRoi}
                          handleDeleteOneProject={handleDeleteOneProject}
                        />
                      </tr>
                    </Link>
                  </React.Fragment>
                );
              })}
              <tr
                className={classNames(
                  styles.Allproject_project,
                  styles.Allproject_project_add,
                  "col-12 row"
                )}
              >
                <td
                  className={classNames(
                    styles.Allproject_project_Addicon,
                    "col-12"
                  )}
                >
                  <div className={classNames(styles.Allproject_project_icon)}>
                    <Link to="/customer">
                      <div
                        className={classNames(
                          styles.Allproject_project_iconText
                        )}
                      >
                        <p>Ajouter un ROI</p>
                      </div>
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export function eventsColors(value: string | undefined) {
  if (value === "ERROR") {
    return "#EFAA53";
  } else if (value === "WARNING") {
    return "#EFAA53";
  } else if (value === "EMERGENCY STOP") {
    return "#E8615B";
  } else if (value === "STOP") {
    return "#E8615B";
  } else {
    return "#7C8FF8";
  }
}
export default RoiTable;
