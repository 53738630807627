import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import { AppBar, Button, Toolbar } from "@material-ui/core";

import img from "../image/img/MGIAlphaJET.png";
import logo from "../image/img/alphajet.png";
import { Link } from "react-router-dom";
import { ThemeType, TopBar, useAuth, useUser } from "@mgi-labs/mgi-id";
import { usersAuthorized } from "../authorizedUsers";
import i18n from "@mgi-labs/mgi-id/dist/Components/Internationalization/i18n";
import mgikm from "../image/logo/kmmgi.svg";

import alphajet from "../image/logo/alphajet.svg";
const Acceuil = () => {
  const profile = useUser();
  const { initialized, auth } = useAuth();
  const changeLang = useCallback((lang) => {
    i18n.changeLanguage(lang);
  }, []);
  const [theme] = useState<ThemeType>("light");
  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return (
    <>
      <AppBar
        position="fixed"
        //className={clsx(classes.appBar, {
        //  [classes.appBarShift]: open,
        //})}
        className={classNames(styles.colorBaraccueil)}
      >
        <Toolbar className={classNames(styles.toolbar, styles.toolbar2)}>
          <div
            className={classNames(styles.divHeight)}
            // onClick={() => {
            //   history.push("/");
            // }}
          >
            <img src={mgikm} alt="Logo KMMGI" />
            <img src={alphajet} alt="Logo ALPHAJET" />

            <div className={classNames(styles.navbarzone)}>
              <TopBar
                logo={logo}
                pageName="CostCalculator"
                currentLang={i18n.language}
                changeLang={changeLang}
                theme={theme}
                // handleUpdateUserTheme={handleUpdateUserTheme}
                backendUrl={process.env["REACT_APP_BACKEND_CONNECT_URL"]}
                // currentConnectPage={location.pathname}
                connectUrl={process.env["REACT_APP_CONNECT_URL"]}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classNames(styles.title)}>
        <h1>ALPHAJET return on investment</h1>
      </div>
      <div className={classNames(styles.bg)}>
        <img src={img} alt="ALPHAJET" />
      </div>
      <div className={classNames(styles.bottom)}>
        <div className={classNames(styles.bottomzone)}>
          <img src={logo} alt="Logo ALPHAJET" />
          <p>
            This roi tool is for sales presentation only and cannot be given to
            customers
          </p>
          <div>
            {profile?.email && usersAuthorized.includes(profile?.email) && (
              <Link to="/roiTable">
                <Button
                  className={classNames(styles.right, styles.accueilButton)}
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  type="submit"
                >
                  List
                </Button>
              </Link>
            )}
            <Link to="/customer">
              <Button
                className={classNames(
                  styles.right,
                  styles.accueilButton,
                  styles.marginleft
                )}
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                type="submit"
              >
                Start
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Acceuil;
