import dataCapex from "./dataCapex";
import dataResult from "./dataResult";
import { AllInputs } from "./roiTypes";

export default function dataRoiResult(
  allInputs: AllInputs,
  estimatedSheets: number,
  overhead: number
) {
  const dataFResult = dataResult(allInputs, overhead);

  const dataFCapex = dataCapex(allInputs);

  const estimatedJobs =
    estimatedSheets /
    allInputs.calibrationInputs.yearlyMaintenanceContract.monthPerYear /
    allInputs.jobInfoInputs.numberOfSheets;

  const estimatedSheetsPerMonth =
    estimatedJobs * allInputs.jobInfoInputs.numberOfSheets;

  const netMarginPerSheet =
    (allInputs.jobInfoInputs.alphaJetInfos.jobSalesPrice -
      dataFResult.totalCostofJob -
      overhead) /
    allInputs.jobInfoInputs.numberOfSheets;

  const estimatedTurnoverPerMonth = estimatedSheetsPerMonth * netMarginPerSheet;
  const estimatedTurnoverPerYear = 12 * estimatedTurnoverPerMonth;

  const estimatedTurnoverOvertermof60MonthLease =
    estimatedTurnoverPerMonth * allInputs.calibrationInputs.amortizationPeriod;

  const nOfsheetsproducedOverTermof60Months =
    estimatedSheetsPerMonth * allInputs.calibrationInputs.amortizationPeriod;

  const machinePaidOffValue =
    (allInputs.calibrationInputs?.equipementCost + dataFCapex.interest) /
    estimatedTurnoverPerYear;

  const timeUntilMachineIsPaidOff =
    Math.floor(machinePaidOffValue) +
    " YEARS AND " +
    Math.floor((machinePaidOffValue - Math.floor(machinePaidOffValue)) * 12) +
    " MONTHS";

  const ret = {
    estimatedJobs: estimatedJobs,
    estimatedSheetsPerMonth: estimatedSheetsPerMonth,
    netMarginPerSheet: netMarginPerSheet,
    estimatedTurnoverPerMonth: estimatedTurnoverPerMonth,
    estimatedTurnoverPerYear: estimatedTurnoverPerYear,
    estimatedTurnoverOvertermof60MonthLease:
      estimatedTurnoverOvertermof60MonthLease,
    nOfsheetsproducedOverTermof60Months: nOfsheetsproducedOverTermof60Months,
    machinePaidOffValue: machinePaidOffValue,
    timeUntilMachineIsPaidOff: timeUntilMachineIsPaidOff,
  };
  return ret;
}
