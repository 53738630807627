import dataCapex from "./dataCapex";
import dataTimeLabor from "./dataTimeLabor";
import { AllInputs, Round2, totalConsumableCosts } from "./roiTypes";

export default function dataMachineCost(allInputs: AllInputs) {
  const hasFoilFront =
    allInputs.jobInfoInputs?.front.digitalHotFoilCoverage > 0 &&
    allInputs.jobInfoInputs?.front.digitalHotFoil2d3dEffect !== 0;

  const hasFoilBack =
    allInputs.jobInfoInputs?.back.digitalHotFoilCoverage > 0 &&
    allInputs.jobInfoInputs?.back.digitalHotFoil2d3dEffect !== 0;

  const hasFoil = hasFoilFront || hasFoilBack;

  const hasVarnishOrOPVFront =
    (allInputs.jobInfoInputs?.front.embellishmentSpotUvCoverage > 0 &&
      allInputs.jobInfoInputs?.front.embellishmentUv2d3dEffect !== 0) ||
    (allInputs.jobInfoInputs?.front.opv > 0 &&
      allInputs.jobInfoInputs?.front.opv2d3dEffect !== 0);

  const hasVarnishOrOPVBack =
    (allInputs.jobInfoInputs?.back.embellishmentSpotUvCoverage > 0 &&
      allInputs.jobInfoInputs?.back.embellishmentUv2d3dEffect !== 0) ||
    (allInputs.jobInfoInputs?.back.opv > 0 &&
      allInputs.jobInfoInputs?.back.opv2d3dEffect !== 0);

  const hasVarnishOrOPV = hasVarnishOrOPVFront || hasVarnishOrOPVBack;

  const powerConsumption =
    allInputs.calibrationInputs.electricityCostPerKWHr *
    (hasFoil && hasVarnishOrOPV
      ? 63
      : hasVarnishOrOPV && !hasFoil
      ? 74.6
      : 74.6);

  const rDataTimeLabor = dataTimeLabor(allInputs);

  const rDataCapex = dataCapex(allInputs);

  const labor = rDataTimeLabor.hourlyRate;

  const rentPerHour =
    allInputs.calibrationInputs &&
    Round2(
      (allInputs.calibrationInputs?.rentCostPerMonthPerSqm2 /
        21 /
        allInputs.calibrationInputs?.yearlyMaintenanceContract
          .productionHoursPerDay) *
        150
    );

  const totalCostWithouthCapex =
    labor &&
    rentPerHour &&
    allInputs.calibrationInputs?.yearlyMaintenanceContract
      .maintenanceContractLength > 0
      ? powerConsumption +
        labor +
        rentPerHour +
        rDataCapex.hourlyMaintenanceContract +
        rDataCapex.hourlyMgiConnectSubscription
      : powerConsumption +
        labor +
        rentPerHour +
        rDataCapex.hourlyMaintenanceContract +
        rDataCapex.hourlyMgiConnectSubscription +
        totalConsumableCosts();

  const hourlyCapexAmortization = rDataCapex.totalCostPerHour;

  const totalCostWithCapex =
    totalCostWithouthCapex &&
    Round2(totalCostWithouthCapex + hourlyCapexAmortization);

  return {
    powerConsumption: powerConsumption,
    labor: labor,
    rentPerHour: rentPerHour,
    hourlyMgiConnectSubscription: rDataCapex.hourlyMgiConnectSubscription,
    hourlyMaintenanceContract: rDataCapex.hourlyMaintenanceContract,
    totalCostWithouthCapex: totalCostWithouthCapex,
    totalCostWithCapex: totalCostWithCapex,
    hourlyCapexAmortization: hourlyCapexAmortization,
  };
}
