import React from "react";
import styles from "./style/home.module.scss";
import Customer from "./customer";
import Calibration from "./calibration";
import { Route, Switch } from "react-router";
import Jobinfo from "./jobinfo";
import Timelabor from "./timelabor";
import Capex from "./capex";
import Consumables from "./consumables";
import Foilcost from "./foilcost";
import Machinetimecost from "./machinetimecost";
import Result from "./result";
import Roiresult from "./roiresult";
import Dashboard from "./dashboard";
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import {
  AllInputs,
  CalibrationInputs,
  CustomerInputs,
  JobInfoInputs,
  OtherCostType,
  RoiInfo,
} from "./roiTypes";
import Prevnext from "./prevnext";

const Formcontent = ({
  register,
  setValue,
  control,
  watch,
  errors,
  handleSubmit,
  allInputs,
  setAllInputsId,
  setJobInfoInputs,
  inputEstimatedSheets,
  setInputEstimatedSheets,
  setCustomerInputs,
  setCalibrationInputs,
  overhead,
  setOverHead,
  isInputForm,
  roiInfo,
  setRoiInfo,
  otherCost,
  setOtherCost,
  isLoading,
  setIsLoading,
  setIsOpenModal,
}: {
  register: any;
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined
  ) => void;
  control: Control<AllInputs>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AllInputs>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<AllInputs> | undefined
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  watch: () => AllInputs;
  errors: DeepMap<AllInputs, FieldError>;
  allInputs: AllInputs;
  setAllInputsId: React.Dispatch<number | undefined>;
  setJobInfoInputs: React.Dispatch<JobInfoInputs>;
  inputEstimatedSheets: { [key: string]: number };
  setInputEstimatedSheets: React.Dispatch<{ [key: string]: number }>;
  setCustomerInputs: React.Dispatch<CustomerInputs | undefined>;
  setCalibrationInputs: React.Dispatch<CalibrationInputs>;
  overhead: number;
  setOverHead: React.Dispatch<number>;
  isInputForm: boolean;
  roiInfo: RoiInfo | undefined;
  setRoiInfo: React.Dispatch<RoiInfo | undefined>;
  otherCost: OtherCostType;
  setOtherCost: React.Dispatch<OtherCostType>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<boolean>;
  setIsOpenModal: React.Dispatch<boolean>;
}) => {
  return (
    <div
      className={styles.formcontent}
      style={{
        overflow: !isInputForm ? "visible" : "",
        position: !isInputForm ? "relative" : "relative",
        height: !isInputForm ? "height: calc(100% - 150px)" : "",
        paddingBottom: !isInputForm ? "20px" : "",
      }}
    >
      <Switch>
        <Route path="/customer/:roiId?">
          <Customer
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            setAllInputsId={setAllInputsId}
            setCustomerInputs={setCustomerInputs}
            allInputs={allInputs}
            isInputForm={isInputForm}
            roiInfo={roiInfo}
            setRoiInfo={setRoiInfo}
            setIsLoading={setIsLoading}
          />
        </Route>
        <Route path="/calibration/:roiId?">
          <Calibration
            register={register}
            setValue={setValue}
            control={control}
            handleSubmit={handleSubmit}
            setCalibrationInputs={setCalibrationInputs}
            allInputs={allInputs}
            isInputForm={isInputForm}
            setIsLoading={setIsLoading}
          />
        </Route>
        <Route path="/job-info/:roiId?">
          <Jobinfo
            watch={watch}
            register={register}
            setValue={setValue}
            control={control}
            handleSubmit={handleSubmit}
            setJobInfoInputs={setJobInfoInputs}
            allInputs={allInputs}
            isInputForm={isInputForm}
            setIsLoading={setIsLoading}
          />
        </Route>
        <Route path="/time-labor/:roiId?">
          <Timelabor allInputs={allInputs} />
          <Prevnext
            isInputForm={isInputForm}
            previous="job-info"
            next="consumables"
          />
        </Route>
        <Route path="/consumables/:roiId?">
          <Consumables allInputs={allInputs} />
          <Prevnext
            isInputForm={isInputForm}
            previous="time-labor"
            next="capex"
          />
        </Route>
        <Route path="/capex/:roiId?">
          <Capex allInputs={allInputs} />
          {allInputs.jobInfoInputs.front?.digitalHotFoil2d3dEffect ? (
            <Prevnext
              isInputForm={isInputForm}
              previous="consumables"
              next="foil-costs"
            />
          ) : (
            <Prevnext
              isInputForm={isInputForm}
              previous="consumables"
              next="machine-time-costs"
            />
          )}
        </Route>
        <Route path="/foil-costs/:roiId?">
          <Foilcost allInputs={allInputs} />
          <Prevnext
            isInputForm={isInputForm}
            previous="capex"
            next="machine-time-costs"
          />
        </Route>
        <Route path="/machine-time-costs/:roiId?">
          <Machinetimecost allInputs={allInputs} />

          {allInputs.jobInfoInputs.front?.digitalHotFoil2d3dEffect ? (
            <Prevnext
              isInputForm={isInputForm}
              previous="foil-costs"
              next="result"
            />
          ) : (
            <Prevnext
              isInputForm={isInputForm}
              previous="capex"
              next="result"
            />
          )}
        </Route>
        <Route path="/result/:roiId?">
          <Result
            allInputs={allInputs}
            overhead={overhead}
            setOverHead={setOverHead}
            handleSubmit={handleSubmit}
            isInputForm={isInputForm}
            register={register}
            watch={watch}
            setValue={setValue}
            control={control}
            otherCost={otherCost}
            setOtherCost={setOtherCost}
            setIsLoading={setIsLoading}
          />
        </Route>
        <Route path="/roi/:roiId?">
          <Roiresult
            allInputs={allInputs}
            watch={watch}
            register={register}
            inputEstimatedSheets={inputEstimatedSheets}
            setInputEstimatedSheets={setInputEstimatedSheets}
            overhead={overhead}
            setIsOpenModal={setIsOpenModal}
            handleSubmit={handleSubmit}
            isInputForm={isInputForm}
            setIsLoading={setIsLoading}
          />
        </Route>
        <Route path="/dashboard/:roiId?">
          <Dashboard
            allInputs={allInputs}
            inputEstimatedSheets={inputEstimatedSheets}
          />
        </Route>
      </Switch>
    </div>
  );
};
export default Formcontent;
