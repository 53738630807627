import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";
import Grid from "@material-ui/core/Grid";
import { OutlinedInput, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import alphajetlogo from "../image/img/alphajet.png";
import NoteOutlined from "@material-ui/icons/NoteOutlined";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { AllInputs, allInputsSchema } from "./roiTypes";
import dataRoiResult from "./dataRoiResult";
import Prevnext from "./prevnext";
import moredetail from "../image/icon/arrow.svg";
import {
  formatNumber,
  removeWhiteSpaceInNumber,
  whiteSpaceNumber,
} from "./formatNumber";
import dataResult from "./dataResult";
import { useBackend } from "../backend/BackendProvider";
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import SwitchPageInput from "./switchPageInput";

const Roiresult = ({
  allInputs,
  watch,
  register,
  inputEstimatedSheets,
  setInputEstimatedSheets,
  overhead,
  setIsOpenModal,
  setIsLoading,
  handleSubmit,
  isInputForm,
}: {
  allInputs: AllInputs;
  watch: () => AllInputs;
  register: any;
  inputEstimatedSheets: { [key: string]: number };
  setInputEstimatedSheets: React.Dispatch<{ [key: string]: number }>;
  overhead: number;
  setIsOpenModal: React.Dispatch<boolean>;
  setIsLoading: React.Dispatch<boolean>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AllInputs>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<AllInputs> | undefined
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  isInputForm: boolean;
}) => {
  const [isVisibledetail, setDetail] = useState(false);
  const showdetail = () => setDetail((isVisibledetail) => !isVisibledetail);

  const inputNumber = Math.ceil(
    allInputs.calibrationInputs.amortizationPeriod / 12
  );

  const rDataRoiResult = dataRoiResult(
    allInputs,
    removeWhiteSpaceInNumber(
      Math.ceil(
        Object.values(inputEstimatedSheets).reduce((a, b) => a + b, 0) /
          Object.keys(inputEstimatedSheets).length
      )
    ),
    overhead
  );

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const dataFResult = dataResult(allInputs, allInputs.overhead);
  const defaultInputEstimatedSheets: { [key: string]: number } = useMemo(() => {
    const object: { [key: string]: number } = {};
    Array.from(Array(inputNumber).keys()).forEach((i) => {
      object["year" + i] = dataFResult.yearVolumeOfSheetsToReachBreakEvent;
    });
    return object;
  }, [dataFResult.yearVolumeOfSheetsToReachBreakEvent, inputNumber]);

  useEffect(() => {
    const allValues = Object.values(inputEstimatedSheets);
    if (allValues.every((e) => e === 0)) {
      setInputEstimatedSheets(defaultInputEstimatedSheets);
    }
  }, [
    dataFResult.yearVolumeOfSheetsToReachBreakEvent,
    defaultInputEstimatedSheets,
    inputEstimatedSheets,
    inputNumber,
    setInputEstimatedSheets,
  ]);
  const backend = useBackend();
  const location = useLocation();
  const history = useHistory();
  const roiIdLocation: number = location.state as number;
  const roiId = useCallback(() => {
    if (routeMatch?.params.roiId) return parseInt(routeMatch.params.roiId);
    else return roiIdLocation;
  }, [roiIdLocation, routeMatch?.params.roiId]);

  const [clicked, setClicked] = useState<string>("roi");

  const roiUpdateResultHandler = useCallback(
    async (data: AllInputs) => {
      if (data) {
        const newAllInputs = new AllInputs(
          allInputs.calibrationInputs,
          allInputs.jobInfoInputs,
          inputEstimatedSheets ?? allInputs.inputEstimatedSheets,
          allInputs.overhead,
          allInputs.otherCostType,
          allInputs?.customerInputs,
          allInputs?.roiInfo,
          roiId()
        );

        setIsLoading(true);
        try {
          await backend.updateRoi(
            roiId(),
            allInputsSchema.cast(newAllInputs) as unknown as AllInputs,
            false
          );
        } finally {
          setIsLoading(false);
          history.push(`/${clicked}/${roiId()}`, roiId());
        }
      }
    },
    [
      allInputs.calibrationInputs,
      allInputs?.customerInputs,
      allInputs.inputEstimatedSheets,
      allInputs.jobInfoInputs,
      allInputs.otherCostType,
      allInputs.overhead,
      allInputs?.roiInfo,
      backend,
      clicked,
      history,
      inputEstimatedSheets,
      roiId,
      setIsLoading,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit(roiUpdateResultHandler)}
      style={{
        overflow: !isInputForm ? "scroll" : "",
        height: !isInputForm ? "calc(100%);" : "",
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <SwitchPageInput setClicked={setClicked} allInputs={allInputs} />
      <div className={classNames(styles.customer, styles.height100)}>
        <Grid
          xs={12}
          className={classNames(styles.paddinglr10, styles.topform)}
        >
          <Grid className={styles.inputcontent} xs={12}>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.titleroi)}
            >
              <Typography variant="h5">R.O.I</Typography>{" "}
              <Link to={"/dashboard/" + roiId()}>
                <button className={styles.costbtn}>
                  <p>Cost Per Page Breakdown</p>
                </button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <FormControl className={styles.formControl}>
          <Grid xs={12} className={styles.dispflex}>
            <Grid
              xs={12}
              className={classNames(styles.paddinglr10, styles.dispflex)}
            >
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.flexstart,
                  styles.dispflex
                )}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={1}>
                  <NoteOutlined color="primary" />
                </Grid>
                <Grid xs={11} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={4}>
                    <Typography variant="h6">
                      CUSTOMER’S ESTIMATED VOLUME / YEAR
                      <span className={classNames(styles.italic)}>
                        &nbsp;(Min. Per Year to Break-Even :{" "}
                        {whiteSpaceNumber(
                          Math.round(
                            dataFResult.yearVolumeOfSheetsToReachBreakEvent
                          )
                        )}
                        )
                      </span>
                    </Typography>
                  </Grid>
                  <Grid className={classNames(styles.algnitem)} xs={7}></Grid>
                </Grid>
              </Grid>
              {Array.from(Array(inputNumber).keys()).map(
                (i) =>
                  (inputEstimatedSheets ?? allInputs.inputEstimatedSheets) && (
                    <>
                      <Grid
                        xs={6}
                        className={classNames(
                          styles.inputcontent,

                          styles.dispflex
                        )}
                      >
                        <Grid
                          className={classNames(styles.algnitem)}
                          xs={2}
                        ></Grid>
                        <Grid className={classNames(styles.algnitem)} xs={7}>
                          <Typography variant="h6">Year {i + 1}</Typography>
                        </Grid>
                        <Grid xs={3}>
                          <FormControl
                            className={classNames(
                              styles.yellow,
                              styles.textalignend,
                              styles.margint15
                            )}
                            size="small"
                            variant="outlined"
                          >
                            <OutlinedInput
                              className={styles.textalignend}
                              id="outlined-adornment-weight"
                              aria-describedby="outlined-weight-helper-text"
                              placeholder={whiteSpaceNumber(
                                Math.round(
                                  dataFResult.yearVolumeOfSheetsToReachBreakEvent
                                )
                              )}
                              defaultValue={whiteSpaceNumber(
                                Math.round(
                                  inputEstimatedSheets["year" + i] !== undefined
                                    ? inputEstimatedSheets["year" + i]
                                    : allInputs.inputEstimatedSheets[
                                        "year" + i
                                      ] !== undefined
                                    ? allInputs.inputEstimatedSheets["year" + i]
                                    : Math.round(
                                        dataFResult.yearVolumeOfSheetsToReachBreakEvent
                                      )
                                )
                              )}
                              inputRef={register}
                              onKeyUp={(e) => {
                                e.currentTarget.value = whiteSpaceNumber(
                                  e.currentTarget.value
                                );
                                setInputEstimatedSheets({
                                  ...inputEstimatedSheets,
                                  ["year" + i]: removeWhiteSpaceInNumber(
                                    e.currentTarget.value
                                  ),
                                });
                              }}
                              name="inputEstimatedSheets"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        className={classNames(
                          styles.inputcontent,
                          styles.margint15,
                          styles.dispflex,
                          styles.height50
                        )}
                        xs={6}
                      >
                        <Grid className={styles.iconcontent} xs={1}></Grid>
                        <Grid className={classNames(styles.dispflex)} xs={11}>
                          <Typography variant="h6">SHEETS / YEAR</Typography>
                          <Typography color="primary">
                            {formatNumber(
                              inputEstimatedSheets["year" + i] /
                                allInputs.jobInfoInputs.numberOfSheets /
                                allInputs.calibrationInputs
                                  .yearlyMaintenanceContract.monthPerYear /
                                allInputs.calibrationInputs
                                  .yearlyMaintenanceContract.workingDaysPerMonth
                            )}{" "}
                            Jobs per days
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )
              )}

              <Grid className={classNames(styles.margint15)} xs={12}></Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={6}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">AVERAGE SHEETS / YEAR</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(
                        styles.alignend,
                        styles.bluecontent
                      )}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        Math.round(
                          Object.values(inputEstimatedSheets).reduce(
                            (a, b) => a + b,
                            0
                          ) / Object.keys(inputEstimatedSheets).length
                        )
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classNames(styles.margint15)} xs={12}></Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={6}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">
                      EQUIVALENT VOLUME IN JOBS / MONTH
                      <span className={classNames(styles.italic)}>
                        &nbsp;(MIN OF{" "}
                        {whiteSpaceNumber(
                          Math.round(dataFResult.monthlyJobsNeededBreakEvent)
                        )}
                        )
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(
                        styles.alignend,
                        styles.bluecontent
                      )}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        Math.round(rDataRoiResult.estimatedJobs)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.toptext)}
                xs={6}
              >
                <Grid className={styles.iconcontent} xs={1}></Grid>
                <Grid className={styles.dispflex} xs={11}>
                  <Typography variant="h6">Jobs</Typography>
                  <Typography color="primary"></Typography>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint05vw,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint05vw,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>

              <Grid className={classNames(styles.inputcontent)} xs={6}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Estimated Sheets Per Month</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend}>
                      {whiteSpaceNumber(
                        Math.round(rDataRoiResult.estimatedSheetsPerMonth)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={styles.inputcontent} xs={6}>
                <Grid className={styles.iconcontent} xs={1}></Grid>
                <Grid className={styles.dispflex} xs={11}>
                  <Typography>
                    Or{" "}
                    {whiteSpaceNumber(Math.round(rDataRoiResult.estimatedJobs))}{" "}
                    jobs x{" "}
                    {whiteSpaceNumber(allInputs.jobInfoInputs?.numberOfSheets)}{" "}
                    Sheets per Job
                  </Typography>
                  <Typography color="primary"></Typography>
                </Grid>
              </Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint05vw,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={6}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6" color="primary">
                      ESTIMATED TURNOVER PER MONTH
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(
                        styles.alignend,
                        styles.bluecontent
                      )}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        Math.round(rDataRoiResult.estimatedTurnoverPerMonth)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={6}>
                <Grid className={styles.iconcontent} xs={1}></Grid>
                <Grid className={styles.dispflex} xs={11}>
                  <Typography>
                    Or{" "}
                    {whiteSpaceNumber(
                      Math.round(rDataRoiResult.estimatedSheetsPerMonth)
                    )}{" "}
                    sheets x €
                    {whiteSpaceNumber(
                      formatNumber(rDataRoiResult.netMarginPerSheet)
                    )}{" "}
                    Net Margin / Sheet
                  </Typography>
                  <Typography color="primary"></Typography>
                </Grid>
              </Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint05vw,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid
                className={classNames(
                  !isVisibledetail ? styles.moredetailhidden : "",
                  styles.moredetail
                )}
                xs={12}
              >
                <Grid className={classNames(styles.inputcontent)} xs={6}>
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid className={styles.dispflex} xs={10}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Estimated Turnover Per Year</Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography className={styles.alignend}>
                        {whiteSpaceNumber(
                          Math.round(rDataRoiResult.estimatedTurnoverPerYear)
                        )}{" "}
                        €
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={styles.inputcontent} xs={6}>
                  <Grid className={styles.iconcontent} xs={1}></Grid>
                  <Grid className={styles.dispflex} xs={11}>
                    <Typography>
                      Or{" "}
                      {whiteSpaceNumber(
                        Math.round(rDataRoiResult.estimatedTurnoverPerMonth)
                      )}{" "}
                      € x 12 Months
                    </Typography>
                    <Typography color="primary"></Typography>
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(
                    styles.inputcontent,
                    styles.margint05vw,
                    styles.dispflex
                  )}
                  xs={12}
                ></Grid>
                <Grid className={classNames(styles.inputcontent)} xs={6}>
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid className={styles.dispflex} xs={10}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>
                        Estimated Turnover Over Term of{" "}
                        {allInputs.calibrationInputs?.amortizationPeriod} Months
                        Lease
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography className={styles.alignend}>
                        {whiteSpaceNumber(
                          Math.round(
                            rDataRoiResult.estimatedTurnoverOvertermof60MonthLease
                          )
                        )}{" "}
                        €
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={styles.inputcontent} xs={6}>
                  <Grid className={styles.iconcontent} xs={1}></Grid>
                  <Grid className={styles.dispflex} xs={11}>
                    <Typography>
                      Or{" "}
                      {whiteSpaceNumber(
                        Math.round(rDataRoiResult.estimatedTurnoverPerMonth)
                      )}{" "}
                      € x{" "}
                      {whiteSpaceNumber(
                        allInputs.calibrationInputs?.amortizationPeriod
                      )}{" "}
                      Months
                    </Typography>
                    <Typography color="primary"></Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(
                    styles.inputcontent,
                    styles.margint05vw,
                    styles.dispflex
                  )}
                  xs={12}
                ></Grid>
                {/* <Grid className={classNames(styles.inputcontent)} xs={6}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>
                      N° of Sheets Produced Over Term of{" "}
                      {whiteSpaceNumber(
                        allInputs.calibrationInputs?.amortizationPeriod
                      )}{" "}
                      Months
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend}>
                      {whiteSpaceNumber(
                        Math.round(
                          rDataRoiResult.nOfsheetsproducedOverTermof60Months
                        )
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> 
              <Grid className={styles.inputcontent} xs={6}>
                <Grid className={styles.iconcontent} xs={1}></Grid>
                <Grid className={styles.dispflex} xs={11}>
                  <Typography>
                    Or{" "}
                    {whiteSpaceNumber(
                      Math.round(rDataRoiResult.estimatedSheetsPerMonth)
                    )}{" "}
                    sheets x{" "}
                    {whiteSpaceNumber(
                      allInputs.calibrationInputs?.amortizationPeriod
                    )}{" "}
                    Months
                  </Typography>
                  <Typography color="primary"></Typography>
                </Grid>
              </Grid>
              */}
              </Grid>
              <Grid className={classNames(styles.moredetailbuttonzone)} xs={12}>
                <Grid
                  onClick={showdetail}
                  className={classNames(styles.moredetailbutton)}
                >
                  <p>{isVisibledetail ? "Less" : "More"} details</p>
                  <img
                    className={classNames(
                      isVisibledetail ? styles.rotate180 : "",
                      styles.moredetailbutton
                    )}
                    src={moredetail}
                    alt="Arrow"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={6} className={classNames(styles.paddinglr10)}></Grid>
          </Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>

          <Grid className={styles.inputcontent} xs={12}>
            <Grid className={styles.alphajetimg} xs={3}>
              <img src={alphajetlogo} alt="alphajetlogo" />
            </Grid>
            <Grid
              className={classNames(
                styles.dispflex,
                styles.bluecontent,
                styles.fontW600,
                styles.paddingr4vw,
                styles.paddingtbm
              )}
              xs={9}
            >
              <Grid xs={7}>
                <Typography variant="h5">
                  Amortisation period :
                  {/* <span className={classNames(styles.italic)}>
                  <br /> According to Customer’s Estimated volume :
                </span> */}
                </Typography>
              </Grid>
              <Grid xs={5}>
                <Typography color="primary" variant="h5">
                  {rDataRoiResult.timeUntilMachineIsPaidOff}
                </Typography>
                {/* <Typography
                className={classNames(styles.littlefont)}
                color="primary"
              >
                You need{" "}
                {
                  allInputs.calibrationInputs.yearlyMaintenanceContract
                    .productionHoursPerDay
                }{" "}
                hour of production / day to amortize the machine
              </Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <Prevnext
        isInputForm
        previous="result"
        next="confirm"
        setIsOpenModal={setIsOpenModal}
      />
    </form>
  );
};
export default Roiresult;
