import React from "react";
import { useAuth, useUser } from "@mgi-labs/mgi-id";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { BackendProvider } from "./backend/BackendProvider";
import Backend from "./backend/Backend";
import "./App.css";
import RoiFetchTest from "./components/roiFetchTest";
import RoiListFetch from "./components/roiFetch";
import Acceuil from "./components/Acceuil";
import NoAccessIndicator from "./NoAccessIndicator";
import { usersAuthorized } from "./authorizedUsers";
import "bootstrap/dist/css/bootstrap.min.css";

function App({ backendUrl }: { backendUrl: string }) {
  const { initialized, auth } = useAuth();
  const profile = useUser();
  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  const availableApps =
    typeof profile === "object" ? profile.availableApps : undefined;

  return availableApps?.includes("roi") ? (
    <BackendProvider value={new Backend(backendUrl, auth)}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/accueil" />
          </Route>
          <Route exact path="/accueil">
            <Acceuil />
          </Route>
          {profile?.email && usersAuthorized.includes(profile?.email) && (
            <Route exact path="/roiTable">
              <RoiListFetch />
            </Route>
          )}
          <Route>
            <RoiFetchTest />
          </Route>
        </Switch>
      </BrowserRouter>
    </BackendProvider>
  ) : (
    <NoAccessIndicator />
  );
}

export default App;
