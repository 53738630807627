import { useUser } from "@mgi-labs/mgi-id";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { usersAuthorized } from "../authorizedUsers";
import { useRoi } from "../hooks/useRoi";

import Roi from "./roi";
import {
  AllInputs,
  CalibrationInputs,
  CustomerInputs,
  defaultCalibrationInput,
  defaultInputEstimatedSheet,
  defaultJobInfoInput,
  defaultOverHead,
  JobInfoInputs,
  RoiInfo,
} from "./roiTypes";

function RoiFetchTest() {
  const location = useLocation();
  const id = useMemo(
    () => location.pathname.split("/")[location.pathname.split("/").length - 1],
    [location.pathname]
  );

  const { data: roiTest } = useRoi(parseInt(id));

  const [loaded, setLoaded] = useState<boolean>(false);

  const profile = useUser();

  const [allInputsId, setAllInputsId] = useState<number | undefined>(
    id ? parseInt(id) : undefined
  );

  const [customerInputs, setCustomerInputs] = useState<
    CustomerInputs | undefined
  >(undefined);
  const [calibrationInputs, setCalibrationInputs] = useState<CalibrationInputs>(
    defaultCalibrationInput
  );
  const [jobInfoInputs, setJobInfoInputs] =
    useState<JobInfoInputs>(defaultJobInfoInput);
  const [inputEstimatedSheets, setInputEstimatedSheets] = useState<{
    [key: string]: number;
  }>(defaultInputEstimatedSheet);
  const [overhead, setOverHead] = useState<number>(defaultOverHead);
  const [otherCost, setOtherCost] = useState<"fix" | "total" | "retail">("fix");
  const [roiInfo, setRoiInfo] = useState<RoiInfo | undefined>(undefined);
  const [foilSurface, setFoilSurface] = useState<number>();

  const allInputs: AllInputs | undefined = useMemo(() => {
    return new AllInputs(
      calibrationInputs,
      jobInfoInputs,
      inputEstimatedSheets,
      overhead,
      otherCost,
      customerInputs,
      roiInfo,
      allInputsId,
      foilSurface
    );
  }, [
    allInputsId,
    calibrationInputs,
    customerInputs,
    foilSurface,
    inputEstimatedSheets,
    jobInfoInputs,
    otherCost,
    overhead,
    roiInfo,
  ]);

  useEffect(() => {
    if (
      profile?.email &&
      !usersAuthorized.includes(profile?.email) &&
      roiTest &&
      !(roiTest === "Error")
    ) {
      window.location.href = "/";
    }

    if (isNaN(parseInt(id))) {
      setLoaded(true);
    } else if (!(typeof roiTest === "string")) {
      if (parseInt(id) === roiTest?.id) {
        setCustomerInputs(roiTest?.data?.customerInputs ?? undefined);
        roiTest &&
          setCalibrationInputs(
            roiTest.data?.calibrationInputs ?? defaultCalibrationInput
          );
        roiTest &&
          setJobInfoInputs(roiTest.data?.jobInfoInputs ?? defaultJobInfoInput);
        if (roiTest) {
          if (typeof roiTest.data?.inputEstimatedSheets === "number") {
            setInputEstimatedSheets(
              { year0: roiTest.data?.inputEstimatedSheets } ??
                defaultInputEstimatedSheet
            );
          } else {
            setInputEstimatedSheets(
              roiTest.data?.inputEstimatedSheets ?? defaultInputEstimatedSheet
            );
          }
        }

        roiTest && setOverHead(roiTest.data?.overhead ?? defaultOverHead);
        roiTest && setOtherCost(roiTest.data?.otherCostType ?? "fix");
        roiTest && setRoiInfo(roiTest.data?.roiInfo ?? undefined);
        roiTest && setFoilSurface(roiTest.data?.foilSurface ?? undefined);

        setLoaded(true);
      }
    }
  }, [id, profile?.email, roiTest]);

  return loaded ? (
    <Roi
      allInputs={allInputs}
      setAllInputsId={setAllInputsId}
      setCustomerInputs={setCustomerInputs}
      setCalibrationInputs={setCalibrationInputs}
      setJobInfoInputs={setJobInfoInputs}
      setInputEstimatedSheets={setInputEstimatedSheets}
      overhead={overhead}
      setOverHead={setOverHead}
      otherCost={otherCost}
      setOtherCost={setOtherCost}
      roiInfo={roiInfo}
      setRoiInfo={setRoiInfo}
      inputEstimatedSheets={inputEstimatedSheets}
    />
  ) : null;
}

export default RoiFetchTest;
