import {
  AllInputs,
  TotalSetUpTime,
  mapMicronToValue,
  Round2,
} from "./roiTypes";

export default function dataTimeLabor(allInputs: AllInputs) {
  const totalSetUpTime = new TotalSetUpTime();

  const totalSetUpTimeTotal = totalSetUpTime.getTotalTime(
    allInputs.jobInfoInputs.doubleSided
  );

  function productionTime(productivity: number, sideB: boolean) {
    const temp =
      allInputs.jobInfoInputs.alphaJetInfos.alphaJetPrintingSpeedPerHour *
      (productivity / 100);

    const pureProductionTime =
      allInputs.jobInfoInputs.numberOfSheets / (temp / 60);

    const micronValueVarnish = mapMicronToValue(
      sideB
        ? allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect
        : allInputs.jobInfoInputs.back.embellishmentUv2d3dEffect
    );

    const micronValueFoil = mapMicronToValue(
      sideB
        ? allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect
        : allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect
    );

    function getSlowestMicronValue(foil: number, varnish: number) {
      if (foil === 0 && varnish === 0) {
        return 0;
      } else if (foil === 0 && varnish !== 0) {
        return varnish;
      } else if (varnish === 0 && foil !== 0) {
        return foil;
      } else return Math.min(varnish, foil);
    }

    //const c7 = allInputs.jobInfoInputs.paperFormat.length;
    //const m4 = c7 / 1000 + 0.1;
    const m4 = 1;
    const l4 = 3600;
    const slowestMicronValue = getSlowestMicronValue(
      micronValueFoil,
      micronValueVarnish
    );
    const i22 = slowestMicronValue ? l4 / (m4 / 1) : l4 / (m4 / 1);

    //const l19 = slowestMicronValue ? i22 / 60 : 3600 / 60;
    const l19 = slowestMicronValue ? i22 / 60 : 3600 / 60;
    const c14 = pureProductionTime * 60;
    const l20 = c14 / l19;
    const m21 = l20 * 60;
    return m21;
  }

  const pureProductionTimeA =
    productionTime(
      allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity,
      false
    ) / 60;
  const pureProductionTimeB =
    productionTime(
      allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity,
      true
    ) / 60;

  const totalTime = allInputs.jobInfoInputs.doubleSided
    ? totalSetUpTimeTotal + pureProductionTimeA + pureProductionTimeB
    : totalSetUpTimeTotal + pureProductionTimeA;

  const hourlyRate =
    allInputs.jobInfoInputs.alphaJetInfos.costPerOperatorPerHour *
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetOperatorsNumber;

  const jobLaborCost = (hourlyRate / 60) * totalTime;

  // const tempTime =
  //   (allInputs.jobInfoInputs.numberOfSheets /
  //     (3600 /
  //       ((allInputs.jobInfoInputs.paperFormat.length / 1000 + 0.1) /
  //         mapMicronToValue(
  //           allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect
  //         )) /
  //       60)) *
  //   60;

  const prodTimeA = productionTime(
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity,
    false
  );

  const prodTimeB = productionTime(
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity,
    true
  );

  const totalProdTime = allInputs.jobInfoInputs.doubleSided
    ? prodTimeA + prodTimeB
    : prodTimeA;

  const productionTimeAt70Productivity =
    Math.floor(totalProdTime / 86400) +
    " days " +
    Math.floor((totalProdTime % 86400) / 3600) +
    " hours " +
    Math.floor((totalProdTime % 3600) / 60) +
    " minutes " +
    Round2(totalProdTime % 60) +
    " seconds";

  const pagePerHourAt70Productivity =
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetPrintingSpeedPerHour *
    (allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity / 100);

  return {
    editingAndPreFlight: totalSetUpTime.editingAndPreFlight,
    RIP: totalSetUpTime.RIP,
    paperLoading: totalSetUpTime.paperLoading,
    testSheets: totalSetUpTime.testSheets,
    qualityControl: totalSetUpTime.qualityControl,
    totalSetUpTime: totalSetUpTimeTotal,
    pureProductionTime: allInputs.jobInfoInputs.doubleSided
      ? pureProductionTimeA + pureProductionTimeB
      : pureProductionTimeA,
    totalTime,
    hourlyRate,
    jobLaborCost,
    productionTimeAt70Productivity,
    pagePerHourAt70Productivity,
  };
}
