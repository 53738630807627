import React from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import customer from "../image/icon/customer.svg";
import calibration from "../image/icon/calibration.svg";
import jobinfo from "../image/icon/jobinfo.svg";
import timelabor from "../image/icon/timelabor.svg";
import consummable from "../image/icon/consummable.svg";
import capex from "../image/icon/capex.svg";
import foilcost from "../image/icon/foilcost.svg";
import machinetimecost from "../image/icon/machinetimecost.svg";
import result from "../image/icon/result.svg";
import roi from "../image/icon/roi.svg";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { AllInputs } from "./roiTypes";

const Switchpage = ({ allInputs }: { allInputs: AllInputs }) => {
  const location = useLocation();

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const roiId = () => {
    if (routeMatch?.params.roiId) return routeMatch.params.roiId;
    else return "";
  };

  const pathName = location.pathname.substring(1);

  return (
    <Grid className={styles.topswitchpagezone} xs={12}>
      <Grid className={styles.content} xs={12} sm={12} md={12} lg={11} xl={10}>
        <Grid
          className={classNames(
            styles.onezone,
            styles.zonethree,
            styles.separation
          )}
          xs={3}
        >
          <div>
            <Link to={"/customer/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("customer") && styles.selectedbutton
                )}
              >
                <img src={customer} alt="customer" />
                <p>Customer</p>
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/calibration/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("calibration") && styles.selectedbutton
                )}
              >
                <img src={calibration} alt="calibration" />
                <p>Calibration</p>
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/job-info/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("job-info") && styles.selectedbutton
                )}
              >
                <img src={jobinfo} alt="jobinfo" />
                <p>Job Info</p>
              </button>
            </Link>
          </div>
        </Grid>

        <Grid
          className={classNames(
            styles.onezone,
            styles.zonefive,
            styles.separation
          )}
          xs={5}
        >
          <div>
            <Link to={"/time-labor/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("time-labor") && styles.selectedbutton
                )}
              >
                <img src={timelabor} alt="timelabor" />
                <p>time &amp; labor</p>
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/consumables/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("consumables") && styles.selectedbutton
                )}
              >
                <img src={consummable} alt="consummable" />
                <p>Consumables</p>
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/capex/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("capex") && styles.selectedbutton
                )}
              >
                <img src={capex} alt="capex" />
                <p>Capex Breakdown </p>
              </button>
            </Link>
          </div>
          {allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0 ||
          allInputs.jobInfoInputs.back.digitalHotFoilCoverage > 0 ? (
            <div>
              <Link to={"/foil-costs/" + roiId()}>
                <button
                  className={classNames(
                    pathName.includes("foil-costs") && styles.selectedbutton
                  )}
                >
                  <img src={foilcost} alt="foilcost" />
                  <p>Foil cost</p>
                </button>
              </Link>
            </div>
          ) : (
            ""
          )}
          <div>
            <Link to={"/machine-time-costs/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("machine-time-costs") &&
                    styles.selectedbutton
                )}
              >
                <img src={machinetimecost} alt="machinetimecost" />
                <p>Machine time cost</p>
              </button>
            </Link>
          </div>
        </Grid>

        <Grid className={classNames(styles.onezone, styles.zonetwo)} xs={2}>
          <div>
            <Link to={"/result/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("result") && styles.selectedbutton
                )}
              >
                <img src={result} alt="result" />
                <p>Result</p>
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/roi/" + roiId()}>
              <button
                className={classNames(
                  pathName.includes("roi") && styles.selectedbutton
                )}
              >
                <img src={roi} alt="roi" />
                <p>ROI</p>
              </button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Switchpage;
