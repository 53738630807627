import React, { useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { EuroOutlined, HourglassEmpty } from "@material-ui/icons";
import { AllInputs } from "./roiTypes";
import dataCapex from "./dataCapex";
import { whiteSpaceNumber } from "./formatNumber";

function whiteSpace(number: number) {
  return number.toLocaleString().replaceAll(" ", " ");
}

const Capex = ({ allInputs }: { allInputs: AllInputs }) => {
  const dataFCapex = dataCapex(allInputs);

  const [showTable, setShowTable] = useState<boolean>(false);
  return (
    <div className={classNames(styles.customer, styles.height100)}>
      <Grid xs={12} className={classNames(styles.paddinglr10, styles.topform)}>
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10}>
            <Typography variant="h5">CAPITAL COST AMORTIZATION</Typography>
          </Grid>
        </Grid>
      </Grid>
      <FormControl className={styles.formControl}>
        <Grid xs={12} className={styles.dispflex}>
          <Grid xs={6} className={classNames(styles.paddinglr10)}>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}>
                <EuroOutlined color="primary" />
              </Grid>
              <Grid xs={10} className={styles.dispflex}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography color="primary">MGI ALPHAJET</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Investment</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(dataFCapex.investment)} €
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Downpayment on lease</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(
                      allInputs.calibrationInputs.leaseDownpayment
                    )}{" "}
                    €
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}>
                <HourglassEmpty color="primary" />
              </Grid>
              <Grid xs={10} className={styles.dispflex}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography color="primary">
                    Interest and depreciation
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Interest</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(Math.round(dataFCapex.interest))} €
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={2}></Grid>
              <Grid className={styles.dispflex} xs={10}>
                <Grid className={classNames(styles.algnitem)} xs={6}>
                  <Typography>Maintenance contract</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={styles.alignend6}>
                    {whiteSpaceNumber(dataFCapex.maintenanceContract)} €
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid xs={5} className={classNames(styles.paddinglr10)}>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid xs={6}>
                <Typography>Interest rate</Typography>
              </Grid>
              <Grid className={styles.iconcontent} xs={3}>
                <Typography>
                  {whiteSpaceNumber(dataFCapex.interestRate)} %
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography className={styles.iconcontent} color="primary">
                  {whiteSpaceNumber(Math.round(dataFCapex.LeaseAmountPerMonth))}{" "}
                  €
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid xs={6}>
                <Typography>Over</Typography>
              </Grid>
              <Grid className={styles.iconcontent} xs={3}>
                <Typography>{dataFCapex.over} months</Typography>
              </Grid>
              <Grid className={styles.iconcontent} xs={3}></Grid>
            </Grid>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid xs={6}>
                <Typography className={styles.littletext}>
                  Yearly maintenance contract
                </Typography>
              </Grid>
              <Grid className={styles.iconcontent} xs={3}>
                <Typography>
                  {whiteSpaceNumber(dataFCapex.yearlyMaintenanceContract)}
                </Typography>
              </Grid>
              <Grid className={styles.iconcontent} xs={3}>
                <Typography>{dataFCapex.yearMaintenance} year(s)</Typography>
              </Grid>
            </Grid>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid xs={9}>
                <Typography className={styles.littletext}>
                  Maintenance contract
                </Typography>
              </Grid>
              <Grid className={styles.iconcontent} xs={3}>
                <Typography>
                  {whiteSpaceNumber(dataFCapex.maintenanceContract)} €
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* TOTAL */}

          <Grid
            xs={12}
            className={classNames(styles.paddinglr10, styles.margint15)}
          >
            <Grid className={classNames(styles.inputcontent)} xs={12}>
              <Grid xs={1}></Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.bluecontent)}
                xs={11}
              >
                <Grid className={(styles.iconcontent, styles.dispflex)} xs={6}>
                  <Typography variant="h6">TOTAL:</Typography>
                  <Typography
                    className={classNames(styles.marginr225vw)}
                    variant="h6"
                  >
                    {whiteSpaceNumber(Math.round(dataFCapex.total))} €
                  </Typography>
                </Grid>
                <Grid className={styles.iconcontent} xs={6}>
                  <Typography variant="h6">
                    {dataFCapex.yearsAmortization} Years amortization
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={(styles.iconcontent, styles.margint15)}
            xs={12}
          ></Grid>
          {/* PER year */}
          <Grid
            xs={12}
            className={classNames(styles.paddinglr10, styles.margint15)}
          >
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid className={classNames(styles.dispflex)} xs={5}>
                <Grid xs={6}>
                  <Typography>Cost per Year</Typography>
                </Grid>
                <Grid className={classNames(styles.alignend6)} xs={6}>
                  <Typography>
                    {whiteSpaceNumber(Math.round(dataFCapex.costPerYear))} €
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid className={styles.iconcontent} xs={3}></Grid>
              <Grid className={styles.iconcontent} xs={2}></Grid>
            </Grid>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={5}
              >
                <Grid xs={8}>
                  <Typography variant="h6">
                    Monthly cost to amortize equipment
                  </Typography>
                </Grid>
                <Grid className={classNames(styles.alignend6)} xs={4}>
                  <Typography variant="h6" color="primary">
                    {whiteSpaceNumber(
                      Math.round(dataFCapex.monthlyCosttoAmortizeEquipment)
                    )}{" "}
                    €
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={1}></Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.bluebkg,
                  styles.dispflex
                )}
                xs={3}
              >
                <Grid className={styles.paddinglr10} xs={12}>
                  <Typography>
                    {dataFCapex.dayByMonth} Days /month (average)
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={2}></Grid>
            </Grid>

            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid className={classNames(styles.dispflex)} xs={5}>
                <Grid xs={8}>
                  <Typography>Cost per week</Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography className={classNames(styles.alignend6)}>
                    {whiteSpaceNumber(Math.round(dataFCapex.costPerWeek))} €
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.bluebkg,
                  styles.dispflex
                )}
                xs={3}
              >
                <Grid className={styles.paddinglr10} xs={12}>
                  <Typography>{dataFCapex.dayByWeek} Days /week</Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={2}></Grid>
            </Grid>
            <Grid
              className={classNames(styles.inputcontent, styles.margint15)}
              xs={12}
            >
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid
                className={classNames(styles.dispflex, styles.bluecontent)}
                xs={5}
              >
                <Grid xs={8}>
                  <Typography variant="h6">
                    Total (amortization) cost per hour
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography
                    className={classNames(styles.alignend6)}
                    variant="h6"
                    color="primary"
                  >
                    {whiteSpaceNumber(Math.round(dataFCapex.totalCostPerHour))}{" "}
                    €
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.bluebkg,
                  styles.dispflex
                )}
                xs={3}
              >
                <Grid className={styles.paddinglr10} xs={12}>
                  <Typography color="primary">
                    {dataFCapex.hoursByDay} Hours /day
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.iconcontent} xs={2}></Grid>
            </Grid>
          </Grid>

          <Grid
            xs={5}
            className={classNames(styles.paddinglr10, styles.dispflex)}
          >
            <Grid
              className={(styles.iconcontent, styles.margint15)}
              xs={12}
            ></Grid>

            {/* 
            <Grid className={classNames(styles.justifend)} xs={6}>
              <button className={styles.breacktbtn}>
                <p>Lease Term Breakdown</p>
              </button>
            </Grid>
            */}
          </Grid>
        </Grid>

        <Grid
          className={classNames(styles.inputcontent, styles.margint15)}
          xs={12}
        >
          <Grid className={styles.iconcontent} xs={12}>
            <Grid className={styles.iconcontent} xs={4}></Grid>
            <Grid xs={8} className={styles.dispflex}>
              <Grid className={classNames(styles.algnitem)} xs={4}>
                <Typography>Show Amortization Table </Typography>
              </Grid>
              <Grid
                xs={8}
                className={classNames(styles.justifyend, styles.inputecoconso)}
              >
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={(e) => setShowTable(e.target.checked)}
                  checked={showTable}
                  defaultValue={"false"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {showTable && (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>Month</TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    Book value
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    Lease Term
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    Redemption
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    Interest
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    Residual Book Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataFCapex.amortizationTable.map((row) => (
                  <TableRow key={row.month}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classNames(styles.amortTable)}
                    >
                      {whiteSpace(parseFloat(row.month.toFixed(2)))}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(styles.amortTable)}
                    >
                      {whiteSpace(parseFloat(row.bookValue.toFixed(2)))}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(styles.amortTable)}
                    >
                      {whiteSpace(parseFloat(row.leaseTerm.toFixed(2)))}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(styles.amortTable)}
                    >
                      {whiteSpace(parseFloat(row.redemption.toFixed(2)))}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(styles.amortTable)}
                    >
                      {whiteSpace(parseFloat(row.interest.toFixed(2)))}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classNames(styles.amortTable)}
                    >
                      {whiteSpace(parseFloat(row.residualBookValue.toFixed(2)))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </FormControl>
    </div>
  );
};
export default Capex;
