import { PDFDocument, PDFFont, PDFPage, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
import { AllInputs, ScenarioType } from "./roiTypes";
import dataResult from "./dataResult";
import dataRoiResult from "./dataRoiResult";
import dataCostBreakdown from "./dataCostBreakdown";
import { MissingOrgError, UserProfile } from "@mgi-labs/mgi-id";
import { formatNumber, removeWhiteSpaceInNumber } from "./formatNumber";
import { RoiArrayType } from "./dashboard";
import dataCapex from "./dataCapex";
import { amortizationTable } from "./generateAmortization";

export async function testPdf(
  allInputs: AllInputs,
  profile: UserProfile | typeof MissingOrgError | undefined,
  overhead: number,
  userPdfInfos: {
    name: string;
    email: string | undefined;
    phone: string;
    title: string;
  }
) {
  const url = "/pdf/ROI_AlphaJet_VF_blank.pdf";
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const finalPdf = await PDFDocument.create();
  const amortizationPdf = await PDFDocument.create();

  const rDataCapex = dataCapex(allInputs);
  const arrayValue = rDataCapex.amortizationTable;

  const helveticaFont = await finalPdf.embedFont(StandardFonts.Helvetica);
  const helveticaFontAmortization = await amortizationPdf.embedFont(
    StandardFonts.Helvetica
  );
  await finalPdf.embedFont(StandardFonts.CourierBold);
  const boldHelveticaFontAmortization = await amortizationPdf.embedFont(
    StandardFonts.HelveticaBold
  );

  const amortizationTablePdf = drawTableV2(
    amortizationPdf,
    arrayValue,
    {
      width: pdfDoc.getPage(0).getWidth(),
      height: pdfDoc.getPage(0).getHeight(),
    },
    helveticaFontAmortization,
    boldHelveticaFontAmortization
  );

  const rDataResult = dataResult(allInputs, allInputs.overhead);

  const rDataRoiResult = dataRoiResult(
    allInputs,
    Object.values(allInputs.inputEstimatedSheets).reduce((a, b) => a + b, 0) /
      Object.keys(allInputs.inputEstimatedSheets).length,
    overhead
  );

  const hasFoil =
    (allInputs.jobInfoInputs?.front.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs?.front.digitalHotFoil2d3dEffect !== 0) ||
    (allInputs.jobInfoInputs?.back.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs?.back.digitalHotFoil2d3dEffect !== 0);
  const rDataCostBreakdown = dataCostBreakdown(allInputs);

  const amortizationPdfPages = await finalPdf.copyPages(
    amortizationTablePdf,
    amortizationTablePdf.getPageIndices()
  );

  const pdfDocPages = await finalPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());

  for (
    let i = 0;
    i < pdfDoc.getPageCount() + amortizationTablePdf.getPageCount();
    i++
  ) {
    if (i >= 8 && i < 8 + amortizationTablePdf.getPageCount()) {
      finalPdf.addPage(amortizationPdfPages[i - 8]);
    } else {
      if (i >= 8 + amortizationTablePdf.getPageCount()) {
        const page = finalPdf.addPage(
          pdfDocPages[i - amortizationTablePdf.getPageCount()]
        );
        page.setWidth(finalPdf.getPage(0).getWidth());
        page.setHeight(finalPdf.getPage(0).getHeight());
      } else {
        finalPdf.addPage(pdfDocPages[i]);
      }
    }
  }

  const pages = finalPdf.getPages();
  const firstPage = pages[0];
  const secondPage = pages[1];
  const thirdPage = pages[2];
  const fourthPage = pages[3];
  const fifthPage = pages[4];
  const sixthPage = pages[5];
  const sevenPage = pages[6];
  const eightPage = pages[7];
  const ninthPage = pages[8 + amortizationPdf.getPageCount()];
  const tenPage = pages[9 + amortizationPdf.getPageCount()];
  const elevenPage = pages[10 + amortizationPdf.getPageCount()];

  function getPercentage(number: number, capex: boolean) {
    return capex
      ? number / rDataCostBreakdown.totalCostPerPageWithCapex
      : number / rDataCostBreakdown.totalCostPerPage;
  }

  function drawChartData(capex: boolean, page: PDFPage) {
    const chartData = [
      {
        percent: getPercentage(rDataCostBreakdown.substrate, capex),
        name: "substrat",
        color: rgb(0.47, 0.02, 0.58),
      },
      {
        percent: getPercentage(rDataCostBreakdown.cmykPrint, capex),
        name: "cmykPrint",
        color: rgb(0.87, 0.22, 0.06),
      },
      {
        percent: getPercentage(rDataCostBreakdown.spotUvCoating, capex),
        name: "spotUvCoating",
        color: rgb(0.09, 0.59, 0.09),
      },
      {
        percent: getPercentage(rDataCostBreakdown.digitalHotFoil, capex),
        name: "digitalHotFoil",
        color: rgb(0.2, 0.4, 0.8),
      },
      {
        percent: getPercentage(rDataCostBreakdown.laborAndSetUp, capex),
        name: "laborAndSetUp",
        color: rgb(0.13, 0.76, 0.89),
      },
      {
        percent: getPercentage(rDataCostBreakdown.otherConsumables, capex),
        name: "otherConsumables",
        color: rgb(0.99, 0.6, 0),
      },
      {
        percent: getPercentage(
          allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets,
          capex
        ),
        name: "otherCosts",
        color: rgb(0.98, 0.42, 0.68),
      },
    ];

    if (capex) {
      chartData.push({
        percent: getPercentage(rDataCostBreakdown.capexCostPerPage, capex),
        name: "capexPerPage",
        color: rgb(0.99, 0.8, 0),
      });
    }

    let cumulativePercent = 0;

    function getCoordinatesForPercent(percent: number) {
      const x = Math.cos(2 * Math.PI * percent);
      const y = Math.sin(2 * Math.PI * percent);
      return [x, y];
    }

    chartData.forEach((slice) => {
      // destructuring assignment sets the two variables at once
      const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

      // each slice starts where the last slice ended, so keep a cumulative percent
      cumulativePercent += slice.percent;

      const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

      // if the slice is more than 50%, take the large arc (the long way around)
      const largeArcFlag = slice.percent > 0.5 ? 1 : 0;

      // create an array and join it just for code readability
      const pathData = [
        `M ${startX * 250} ${startY * 250}`, // Move
        `A 250 250 0 ${largeArcFlag} 1 ${endX * 250} ${endY * 250}`, // Arc
        `L 0 0`, // Line
      ].join(" ");

      slice.percent > 0 &&
        page.drawSvgPath(pathData, {
          x: page.getWidth() / 4 - 25,
          y: page.getHeight() / 2 - 70,
          color: slice.color,
        });
    });
  }

  drawChartData(false, ninthPage);
  drawChartData(true, tenPage);

  //Values needed to initalize
  const salesPricePerSheet =
    allInputs.jobInfoInputs.alphaJetInfos.jobSalesPrice /
    allInputs.jobInfoInputs.numberOfSheets;

  const salesVolumePerMonth = Math.round(
    Object.values(allInputs.inputEstimatedSheets).reduce((a, b) => a + b, 0) /
      Object.keys(allInputs.inputEstimatedSheets).length /
      12
  );
  const totalCostPerPage = rDataCostBreakdown.totalCostPerPage;

  const totalCostPerPageOverhead =
    rDataCostBreakdown.totalCostPerPage +
    allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets;

  //Data arrays used to draw graphs
  const roiArray = RoiArray(totalCostPerPage);
  const roiArrayOverhead = RoiArray(totalCostPerPageOverhead);
  const breakEvenArray = BreakEvenAnalysis();

  function getNetProfit() {
    if (allInputs.otherCostType === "fix") {
      return (
        rDataResult.totalGrossProfitMargin -
        removeWhiteSpaceInNumber(overhead ?? allInputs.overhead)
      );
    } else if (allInputs.otherCostType === "total") {
      return (
        rDataResult.totalGrossProfitMargin -
        (removeWhiteSpaceInNumber(overhead ?? allInputs.overhead) / 100) *
          rDataResult.totalCostofJob
      );
    } else {
      return (
        rDataResult.totalGrossProfitMargin -
        (removeWhiteSpaceInNumber(overhead ?? allInputs.overhead) / 100) *
          rDataResult.retailCostofJob
      );
    }
  }

  const netProfit = getNetProfit();

  //Function to draw graph
  function drawRoiGraph(
    line1Start: number,
    line1End: number,
    line2Start: number,
    line2End: number,
    XValue: number,
    X: number,
    Y: number
  ) {
    const graphHeight = 250;
    const graphWidth = 375;

    const nearestWholeNumber = getNearestWholeNumber(line1End, line2End);

    //Axes
    const axeVertical = [`M ${0} -${graphHeight}`, `L ${0},-${0}`].join(" ");
    const axeHorizontal = [`M ${0} -${0}`, `L ${graphWidth},-${0}`].join(" ");

    //Lines
    const totalSalesPathData = [
      `M ${0} -${(line1Start * graphHeight) / nearestWholeNumber}`,
      `L ${graphWidth},-${(line1End * graphHeight) / nearestWholeNumber}`,
    ].join(" ");
    const totalCostPathData = [
      `M ${0} -${(line2Start * graphHeight) / line2End}`,
      `L ${graphWidth},-${(line2End * graphHeight) / nearestWholeNumber}`,
    ].join(" ");

    //Draw axes
    elevenPage.drawSvgPath(axeVertical, {
      x: X,
      y: Y,
      color: rgb(0, 0, 0),
    });
    elevenPage.drawSvgPath(axeHorizontal, {
      x: X,
      y: Y,
      color: rgb(0, 0, 0),
    });
    //Draw coordinates
    elevenPage.drawText("0", {
      x: X - 15,
      y: Y,
    });

    elevenPage.drawText(XValue.toString(), {
      x: X + graphWidth,
      y: Y - 20,
    });
    elevenPage.drawText(Math.round(XValue / 2).toString(), {
      x: X + graphWidth / 2,
      y: Y - 20,
    });

    // Y
    elevenPage.drawText((nearestWholeNumber / 2).toString(), {
      x: X - 10 - (nearestWholeNumber / 2).toString().length * 12,
      y: Y + graphHeight / 2,
    });
    elevenPage.drawText(nearestWholeNumber.toString(), {
      x: X - 10 - nearestWholeNumber.toString().length * 12,
      y: Y + graphHeight,
    });

    //Draw lines
    elevenPage.drawSvgPath(totalSalesPathData, {
      x: X,
      y: Y,
      color: rgb(0.87, 0.22, 0.06),
    });
    elevenPage.drawSvgPath(totalCostPathData, {
      x: X,
      y: Y,
      color: rgb(0.2, 0.4, 0.8),
    });
  }

  //Coordinates to drawn graphs on page
  const X = elevenPage.getWidth() / 2 - 175;
  const Y = elevenPage.getHeight() / 2 - 70;

  //Draw graph
  drawRoiGraph(
    roiArray[0].lifeTimeTotalSales,
    roiArray[roiArray.length - 1].lifeTimeTotalSales,
    roiArray[0].lifeTimeTotalCost,
    roiArray[roiArray.length - 1].lifeTimeTotalCost,
    roiArray[roiArray.length - 1].month,
    X - 400,
    Y - 400
  );
  drawRoiGraph(
    roiArrayOverhead[0].lifeTimeTotalSales,
    roiArrayOverhead[roiArrayOverhead.length - 1].lifeTimeTotalSales,
    roiArrayOverhead[0].lifeTimeTotalCost,
    roiArrayOverhead[roiArrayOverhead.length - 1].lifeTimeTotalCost,
    roiArrayOverhead[roiArrayOverhead.length - 1].month,
    X + 400,
    Y - 400
  );
  drawRoiGraph(
    breakEvenArray[0].totalSales,
    breakEvenArray[breakEvenArray.length - 1].totalSales,
    breakEvenArray[0].totalCost,
    breakEvenArray[breakEvenArray.length - 1].totalCost,
    Math.round(breakEvenArray[breakEvenArray.length - 1].sheetPerMonth),
    X,
    Y
  );
  //drawRoiGraph(roiArrayOverhead, X + 500, Y);

  // RECTANGLE à afficher si foil = False
  // Et cacher les valeurs
  !hasFoil &&
    fourthPage.drawRectangle({
      x: 150,
      y: 180,
      width: 800,
      height: 295,
      borderWidth: 0,
      color: rgb(1, 1, 1),
      opacity: 1,
    });

  allInputs.calibrationInputs.isSqm
    ? thirdPage.drawRectangle({
        x: 1050,
        y: 130,
        width: 1200,
        height: 295,
        borderWidth: 0,
        color: rgb(1, 1, 1),
        opacity: 1,
      })
    : thirdPage.drawRectangle({
        x: 1050,
        y: 430,
        width: 1200,
        height: 50,
        borderWidth: 0,
        color: rgb(1, 1, 1),
        opacity: 1,
      });

  thirdPage.drawRectangle({
    x: 1300,
    y: 465,
    width: 500,
    height: 50,
    borderWidth: 0,
    color: rgb(1, 1, 1),
    opacity: 1,
  });

  firstPage.drawRectangle({
    x: 0,
    y: 300,
    width: 1200,
    height: 290,
    borderWidth: 0,
    color: rgb(1, 1, 1),
    opacity: 1,
  });

  const jpgUrl = "/pdf/alphajet.png";
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());

  const jpgImage = jpgImageBytes && (await finalPdf.embedPng(jpgImageBytes));
  const jpgDims = jpgImage.scale(0.7);

  jpgImage &&
    firstPage.drawImage(jpgImage, {
      x: 240,
      y: 230,
      width: jpgDims.width,
      height: jpgDims.height,
    });

  firstPage.drawText(new Date().toLocaleDateString(), {
    x: 1575,
    y: 727,
    size: 35,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(
    replaceChars(allInputs.customerInputs?.companyName) ?? "Company name",
    {
      x: 1510,
      y: 527,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  secondPage.drawText(replaceChars(userPdfInfos.name) ?? "Mehdi Labane", {
    x: 248,
    y: 718,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(replaceChars(userPdfInfos.title) ?? "MGI seller", {
    x: 248,
    y: 651,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(
    replaceChars(allInputs.customerInputs?.companyName) ?? "MGI",
    {
      x: 248,
      y: 429.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  secondPage.drawText(
    replaceChars(allInputs.customerInputs?.contactName) ?? "Jean-Denis Koeck",
    {
      x: 248,
      y: 344.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  secondPage.drawText(
    replaceChars(allInputs.customerInputs?.contactMail) ?? "jdkoeck@mgi-fr.com",
    {
      x: 248,
      y: 263.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  secondPage.drawText(
    replaceChars(allInputs.customerInputs?.contactPhone) ?? "06 00 00 00 00",
    {
      x: 248,
      y: 174.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  secondPage.drawText(
    allInputs.customerInputs?.mainProductionFormat.join(", ") ??
      "Main production format : B2 (520 x 740 mm)",
    {
      x: 248,
      y: 90,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  // right
  secondPage.drawText(
    replaceChars(userPdfInfos.email) ?? "mlabanel@mgi-fr.com",
    {
      x: 1142,
      y: 718,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  secondPage.drawText(replaceChars(userPdfInfos.phone) ?? "", {
    x: 1142,
    y: 651,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(allInputs.customerInputs?.country ?? "France", {
    x: 1142,
    y: 429.5,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(
    replaceChars(allInputs.customerInputs?.companyAddress) ??
      "Company’s Address",
    {
      x: 1142,
      y: 344.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  secondPage.drawText(
    allInputs.customerInputs?.companyTurnover ??
      "Turnover 2 Million - 5 Million",
    {
      x: 1142,
      y: 263.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  secondPage.drawText(
    allInputs.customerInputs?.customerMarket.join(", ") ??
      "Labels, Digital Printing, Packaging, Web-on-Line",
    {
      x: 1142,
      y: 174.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  secondPage.drawText(
    allInputs.customerInputs?.investmentProject ?? "Investment 1 year ",
    {
      x: 1142,
      y: 90,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // PAGE 3 LEFT/ Tous ce tas de  et de
  thirdPage.drawText(
    whiteSpace(allInputs.calibrationInputs.equipementCost) ?? "",
    {
      x: 715,
      y: 782,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.amortizationPeriod.toString() ?? "",
    {
      x: 715,
      y: 718,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.interestLeasingRate.toString() ?? "",
    {
      x: 715,
      y: 672,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    whiteSpace(allInputs.calibrationInputs.leaseDownpayment) ?? "",
    {
      x: 715,
      y: 629,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    whiteSpace(
      allInputs.calibrationInputs.yearlyMaintenanceContract
        .yearlyMaintenanceContract
    ) ?? "",
    {
      x: 715,
      y: 563,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.yearlyMaintenanceContract.maintenanceContractLength.toString() ??
      "",
    {
      x: 715,
      y: 520,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.yearlyMaintenanceContract.mgiConnectSubscription.toString() ??
      "",
    {
      x: 715,
      y: 453,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.yearlyMaintenanceContract.monthPerYear.toString() ??
      "",
    {
      x: 715,
      y: 383,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.yearlyMaintenanceContract.workingDaysPerMonth.toString() ??
      "",
    {
      x: 715,
      y: 340,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.yearlyMaintenanceContract.workingDaysPerWeek.toString() ??
      "",
    {
      x: 715,
      y: 296,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.yearlyMaintenanceContract.productionHoursPerDay.toString() ??
      "",
    {
      x: 715,
      y: 252,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.electricityCostPerKWHr.toString() ?? "",
    {
      x: 715,
      y: 206,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.rentCostPerMonthPerSqm2.toString() ?? "",
    {
      x: 715,
      y: 162,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // PAGE 3 RIGHT / Tous ce tas de  et de
  thirdPage.drawText(
    allInputs.calibrationInputs.alphaJetGlobalPrintingCosts.alphaJetGlobalPrintingCost.toString() ??
      "",
    {
      x: 1571,
      y: 782,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter.toString() ??
      "",
    {
      x: 1571,
      y: 688,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  thirdPage.drawText(
    allInputs.calibrationInputs.alphaJetGlobalPrintingCosts.costPrimer.toString() ??
      "",
    {
      x: 1571,
      y: 600,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  allInputs.calibrationInputs.isSqm &&
    thirdPage.drawText(allInputs.calibrationInputs.sqmPrice.toString() ?? "", {
      x: 1571,
      y: 440,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  !allInputs.calibrationInputs.isSqm &&
    thirdPage.drawText(
      allInputs.calibrationInputs.foilInformation.foilMasterRollWidth.toString() ??
        "",
      {
        x: 1571,
        y: 391,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  !allInputs.calibrationInputs.isSqm &&
    thirdPage.drawText(
      allInputs.calibrationInputs.foilInformation.foilRollLength.toString() ??
        "",
      {
        x: 1571,
        y: 347,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  !allInputs.calibrationInputs.isSqm &&
    thirdPage.drawText(
      allInputs.calibrationInputs.foilInformation.foilMasterRollCost.toString() ??
        "",
      {
        x: 1571,
        y: 303,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

  // thirdPage.drawCircle({
  //   x: 1585,
  //   y: 607,
  //   size: 5,
  //   borderWidth: 0,
  //   color: rgb(0.07, 0.45, 0.91),
  //   opacity: 1,
  // });
  // thirdPage.drawCircle({
  //   x: 1432,
  //   y: 607,
  //   size: 5,
  //   borderWidth: 0,
  //   color: rgb(0.07, 0.45, 0.91),
  //   opacity: 1,
  // });

  // PAGE 4 LEFT
  fourthPage.drawText(
    allInputs.jobInfoInputs.paperFormat.length.toString() ?? "1020",
    {
      x: 725,
      y: 777,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.paperFormat.width.toString() ?? "740",
    {
      x: 725,
      y: 736,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  fourthPage.drawText(
    allInputs.jobInfoInputs.numberOfSheets.toString() ?? "1000",
    {
      x: 725,
      y: 683,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.doubleSided
      ? (
          allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0 ||
          allInputs.jobInfoInputs.back.digitalHotFoilCoverage > 0
        ).toString()
      : (allInputs.jobInfoInputs.front.digitalHotFoilCoverage > 0).toString(),
    {
      x: 725,
      y: 583,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  hasFoil &&
    fourthPage.drawText(
      allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet &&
        allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet
          .maximizedFoilWidthUsageRollWidth
        ? allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet.maximizedFoilWidthUsageRollWidth.toString()
        : "700",
      {
        x: 725,
        y: 489,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  hasFoil &&
    fourthPage.drawText(
      allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet &&
        allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet
          .maximizedFoilLengthUsagePerSheet
        ? allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet.maximizedFoilLengthUsagePerSheet.toString()
        : "1000",
      {
        x: 725,
        y: 445,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  hasFoil &&
    fourthPage.drawText(
      allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet &&
        allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet
          .FoilMasterPerRollUpDown
        ? allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet.FoilMasterPerRollUpDown.toString()
        : "50.8",
      {
        x: 725,
        y: 400,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  hasFoil &&
    fourthPage.drawText(
      allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet &&
        allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet
          .TotalFoilLengthUsagePerSheet
        ? allInputs.jobInfoInputs.front.optimizedFoilUsagePerSheet.TotalFoilLengthUsagePerSheet.toString()
        : "1050.8",
      {
        x: 725,
        y: 349,
        size: 24,
        font: helveticaFont,
        color: rgb(0.184, 0.21, 0.88),
      }
    );

  // RIGHT
  fourthPage.drawText(
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetOperatorsNumber.toString() ??
      "1",
    {
      x: 1570,
      y: 777,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.alphaJetInfos.costPerOperatorPerHour.toString() ??
      "30",
    {
      x: 1570,
      y: 736,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // choix /1000 sheet ou /kg
  fourthPage.drawText(
    allInputs.jobInfoInputs.isInKg ? "/ kg" : "/ 1000 Sheets",
    {
      x: 1337,
      y: 661,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // End
  fourthPage.drawText(
    allInputs.jobInfoInputs.isInKg
      ? allInputs.jobInfoInputs.alphaJetInfos.substratCostPerKg.toFixed(2)
      : allInputs.jobInfoInputs.alphaJetInfos.substratCostPer1000Sheet.toFixed(
          2
        ),
    {
      x: 1570,
      y: 665,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.paperCostForJob.toFixed(2) ?? "75",
    {
      x: 1570,
      y: 624,
      size: 24,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetPrintingSpeedPerHour.toString() ??
      "1800",
    {
      x: 1570,
      y: 544,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity.toString() ??
      "70",
    {
      x: 1570,
      y: 496,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fourthPage.drawText(
    allInputs.jobInfoInputs.alphaJetInfos.jobSalesPrice.toString() ??
      "2 200.00",
    {
      x: 1570,
      y: 388,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // PAGE 5 LEFT

  fifthPage.drawText(
    allInputs.jobInfoInputs.front.cmykPrintFileInkCoverage.toString() ?? "50",
    {
      x: 725,
      y: 776,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(
    allInputs.jobInfoInputs.front.embellishmentSpotUvCoverage.toString() ??
      "10.00",
    {
      x: 725,
      y: 712,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(
    allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect.toString() ?? "43",
    {
      x: 725,
      y: 650,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(
    allInputs.jobInfoInputs.front.digitalHotFoilCoverage.toString() ?? "43",
    {
      x: 740,
      y: 582,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  fifthPage.drawText(
    allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect.toString() ?? "43",
    {
      x: 740,
      y: 520,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(allInputs.jobInfoInputs.front.opv.toString() ?? "43", {
    x: 740,
    y: 441,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  // PAGE 5 RIGHT

  fifthPage.drawText(
    allInputs.jobInfoInputs.back.cmykPrintFileInkCoverage.toString() ?? "50",
    {
      x: 1619,
      y: 776,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(
    allInputs.jobInfoInputs.back.embellishmentSpotUvCoverage.toString() ??
      "10.00",
    {
      x: 1619,
      y: 712,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(
    allInputs.jobInfoInputs.back.embellishmentUv2d3dEffect.toString() ?? "43",
    {
      x: 1619,
      y: 650,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(
    allInputs.jobInfoInputs.back.digitalHotFoilCoverage.toString() ?? "43",
    {
      x: 1619,
      y: 582,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  fifthPage.drawText(
    allInputs.jobInfoInputs.back.digitalHotFoil2d3dEffect.toString() ?? "43",
    {
      x: 1619,
      y: 520,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  fifthPage.drawText(allInputs.jobInfoInputs.back.opv.toString() ?? "43", {
    x: 1619,
    y: 441,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  // PAGE 6 LEFT
  sixthPage.drawText(
    Math.round(rDataResult.totalNOSheetByJob).toString() ?? "1000",
    {
      x: 740,
      y: 751,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  sixthPage.drawText(rDataResult.costOfMaterial.toFixed(2) ?? "75.00", {
    x: 740,
    y: 694,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sixthPage.drawText(rDataResult.costofCMYKPrint.toFixed(2) ?? "236.47", {
    x: 740,
    y: 645,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sixthPage.drawText(rDataResult.costofVarnishUsed.toFixed(2) ?? "216.57", {
    x: 740,
    y: 594,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  sixthPage.drawText(rDataResult.finalOpvCost.toFixed(2) ?? "491.33", {
    x: 740,
    y: 548,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sixthPage.drawText(rDataResult.costOfPrimer.toFixed(2) ?? "491.33", {
    x: 740,
    y: 500,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sixthPage.drawText(rDataResult.foilCosts.toFixed(2) ?? "491.33", {
    x: 740,
    y: 455,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  // Total time to produce at pourcent ...
  sixthPage.drawText(
    allInputs.jobInfoInputs.alphaJetInfos.alphaJetProductivity.toString() +
      "%" ?? "70%",
    {
      x: 530,
      y: 383,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // End

  sixthPage.drawText(
    ":  " + rDataResult.totaltimetoproduceAt.toString() ?? ": 62",
    {
      x: 725,
      y: 382.5,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  sixthPage.drawText(rDataResult.amortizedCosts.toFixed(2) ?? "74.96", {
    x: 740,
    y: 306,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  sixthPage.drawText(
    "Includes: Labor, Maintenance Contract, Electricity & Overhead (Rent)",
    {
      x: 248,
      y: 277,
      size: 18,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  sixthPage.drawText(rDataResult.totalCostofJob.toFixed(2) ?? "1 094.33", {
    x: 740,
    y: 242,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  sixthPage.drawText(rDataResult.retailCostofJob.toString() ?? "2 200.00", {
    x: 740,
    y: 198,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sixthPage.drawText(
    scenarioText(allInputs.jobInfoInputs.alphaJetInfos.scenario) ??
      "Customer Subcontracting Price",
    {
      x: 248,
      y: 198,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // RIGHT

  sixthPage.drawText(
    rDataResult.totalGrossProfitMargin.toFixed(2) ?? "1 106.00",
    {
      x: 1610,
      y: 424,
      size: 26,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );
  //Profit of
  sixthPage.drawText(rDataResult.grossProfit.toFixed(1) ?? "1 106.00", {
    x: 1675,
    y: 397,
    size: 23,
    font: helveticaFont,
    color: rgb(0.184, 0.21, 0.88),
  });
  //Other costs
  sixthPage.drawText(allInputs.overhead.toString() ?? "300.00", {
    x: 1615,
    y: 357,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sixthPage.drawRectangle({
    width: 20,
    height: 20,
    x: 1745,
    y: 357,
    color: rgb(1, 1, 1),
  });

  sixthPage.drawText(allInputs.otherCostType === "fix" ? "€" : "%", {
    x: 1748,
    y: 357,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  //Gross profit margin on Job
  sixthPage.drawText(netProfit.toFixed(2) ?? "300.00", {
    x: 1615,
    y: 311,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  //Profit of
  sixthPage.drawText(
    (100 * (netProfit / rDataResult.retailCostofJob)).toFixed(1) ?? "1 106.00",
    {
      x: 1675,
      y: 283,
      size: 23,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );
  //Capex
  sixthPage.drawText(rDataResult.hourlyCapexCost.toFixed(2) ?? "300.00", {
    x: 1615,
    y: 242,
    size: 24,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });
  //Net profit margin on Job
  sixthPage.drawText(
    (netProfit - rDataResult.hourlyCapexCost).toFixed(2) ?? "300.00",
    {
      x: 1615,
      y: 197,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  //Profit of
  sixthPage.drawText(
    (
      100 *
      ((netProfit - rDataResult.hourlyCapexCost) / rDataResult.retailCostofJob)
    ).toFixed(1) ?? "1 106.00",
    {
      x: 1675,
      y: 167,
      size: 23,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );
  // end
  sixthPage.drawText(
    whiteSpace(
      parseFloat(rDataResult.rOIAmortizationToBreakEvenMonthlyRent.toFixed(2))
    ) ?? "46 047,18",
    {
      x: 1580,
      y: 755,
      size: 20,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );
  sixthPage.drawText(
    whiteSpace(Math.round(rDataResult.yearVolumeOfSheetsToReachBreakEvent)) ??
      "499 757",
    {
      x: 1555,
      y: 680,
      size: 22,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );
  sixthPage.drawText(
    whiteSpace(Math.round(rDataResult.monthlySheetsNeededToReachBreakEvent)) ??
      "41 646",
    {
      x: 1555,
      y: 620,
      size: 22,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  sixthPage.drawText(
    whiteSpace(Math.round(rDataResult.monthlyJobsNeededBreakEvent)) ?? "42",
    {
      x: 1555,
      y: 563,
      size: 22,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  // PAGE 6 LEFT

  sevenPage.drawRectangle({
    x: 828,
    y: 775,
    width: 200,
    height: 250,
    borderWidth: 0,
    color: rgb(1, 1, 1),
    opacity: 1,
  });

  sevenPage.drawText(
    whiteSpace(Math.round(rDataResult.yearVolumeOfSheetsToReachBreakEvent)) ??
      "499 757",
    {
      x: 837,
      y: 783,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0.9),
    }
  );

  sevenPage.drawText(")", {
    x:
      860 +
      rDataResult.yearVolumeOfSheetsToReachBreakEvent.toString().length * 4,
    y: 783,
    size: 28,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  sevenPage.drawText(
    whiteSpace(
      Object.values(allInputs.inputEstimatedSheets).reduce((a, b) => a + b, 0) /
        Object.keys(allInputs.inputEstimatedSheets).length
    ) ?? "480 734",
    {
      x: 1110,
      y: 782,
      size: 32,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  Object.values(allInputs.inputEstimatedSheets).forEach((e, i) => {
    sevenPage.drawText("Year " + (i + 1) + " :", {
      x: 270,
      y: 723 - i * 50,
      size: 25,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    sevenPage.drawText(whiteSpace(Math.round(e)) ?? "499 757", {
      x: 370,
      y: 723 - i * 50,
      size: 25,
      font: helveticaFont,
      color: rgb(0, 0, 0.9),
    });

    sevenPage.drawText("SHEETS / YEAR", {
      x: 520,
      y: 723 - i * 50,
      size: 25,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    sevenPage.drawText(
      whiteSpace(
        allInputs.inputEstimatedSheets["year" + i] /
          allInputs.jobInfoInputs.numberOfSheets /
          allInputs.calibrationInputs.yearlyMaintenanceContract.monthPerYear /
          allInputs.calibrationInputs.yearlyMaintenanceContract
            .workingDaysPerMonth ?? "499 757"
      ) + " Jobs per days",
      {
        x: 770,
        y: 723 - i * 50,
        size: 25,
        font: helveticaFont,
        color: rgb(0, 0, 0.9),
      }
    );
  });

  eightPage.drawText(
    whiteSpace(Math.round(rDataResult.monthlyJobsNeededBreakEvent)) ?? "40",
    {
      x: 808,
      y: 732,
      size: 26,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );

  eightPage.drawText(rDataRoiResult.estimatedJobs.toFixed(2) ?? "40", {
    x: 1110,
    y: 732,
    size: 32,
    font: helveticaFont,
    color: rgb(0.184, 0.21, 0.88),
  });

  eightPage.drawText(
    whiteSpace(formatNumber(rDataRoiResult.estimatedSheetsPerMonth)) ??
      "40 061",
    {
      x: 1110,
      y: 624,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  eightPage.drawText(
    whiteSpace(formatNumber(rDataRoiResult.estimatedTurnoverPerMonth)) ??
      "44 294.40 €",
    {
      x: 1110,
      y: 573,
      size: 24,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );

  eightPage.drawText(
    whiteSpace(formatNumber(rDataRoiResult.estimatedTurnoverPerYear)) ??
      "531 532.85 €",
    {
      x: 1110,
      y: 522,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  eightPage.drawText(
    whiteSpace(
      formatNumber(rDataRoiResult.estimatedTurnoverOvertermof60MonthLease)
    ) ?? "2 657 664.25 €",
    {
      x: 1110,
      y: 471,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  eightPage.drawText(
    whiteSpace(
      formatNumber(rDataRoiResult.nOfsheetsproducedOverTermof60Months)
    ) ?? "2 403 670.00 €",
    {
      x: 1110,
      y: 420,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  // partie OR
  eightPage.drawText(
    whiteSpace(Math.round(rDataRoiResult.estimatedJobs)) +
      " jobs x " +
      whiteSpace(allInputs.jobInfoInputs?.numberOfSheets) +
      " Sheets per Job" ?? "40.06 jobs x 1000 Sheets per job",
    {
      x: 1390,
      y: 626,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  eightPage.drawText(
    whiteSpace(Math.round(rDataRoiResult.estimatedSheetsPerMonth)) +
      " sheets x € " +
      whiteSpace(formatNumber(rDataRoiResult.netMarginPerSheet)) +
      " Net Margin / Sheet" ?? "40061.17 sheets x €1.11 Net Margin / sheet",
    {
      x: 1390,
      y: 576,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  eightPage.drawText(
    whiteSpace(Math.round(rDataRoiResult.estimatedTurnoverPerMonth)) +
      " € x 12 Months" ?? "40061.17 sheets x €1.11 Net Margin / sheet",
    {
      x: 1390,
      y: 525,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  eightPage.drawText(
    whiteSpace(Math.round(rDataRoiResult.estimatedTurnoverPerMonth)) +
      " € x " +
      whiteSpace(allInputs.calibrationInputs?.amortizationPeriod) +
      " Months" ?? "40061.17 sheets x €1.11 Net Margin / sheet",
    {
      x: 1390,
      y: 474,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  eightPage.drawText(
    whiteSpace(Math.round(rDataRoiResult.estimatedSheetsPerMonth)) +
      " sheets x " +
      whiteSpace(allInputs.calibrationInputs?.amortizationPeriod) +
      " Months" ?? "40061.17 sheets x €1.11 Net Margin / sheet",
    {
      x: 1390,
      y: 423,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  // End

  //turnover month
  eightPage.drawText(
    whiteSpace(allInputs.calibrationInputs?.amortizationPeriod) +
      " Months Lease" ?? "60 Months Lease",
    {
      x: 611,
      y: 472,
      size: 21,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  //sheet produced over over term
  eightPage.drawText(
    whiteSpace(allInputs.calibrationInputs?.amortizationPeriod) + " Months" ??
      "60 Months",
    {
      x: 643,
      y: 421,
      size: 21,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  eightPage.drawText(
    rDataRoiResult.timeUntilMachineIsPaidOff ?? "4 years and 11 months",
    {
      x: 1270,
      y: 184,
      size: 38,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );

  // PAGE 8 LEFT
  ninthPage.drawText(
    rDataCostBreakdown.substrate.toFixed(3) + " €" ?? "0.075 €",
    {
      x: 1628,
      y: 571,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  ninthPage.drawText(
    rDataCostBreakdown.cmykPrint.toFixed(3) + " €" ?? "0.236 €",
    {
      x: 1628,
      y: 524,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    rDataCostBreakdown.spotUvCoating.toFixed(3) + " €" ?? "0.217 €",
    {
      x: 1628,
      y: 477,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    rDataCostBreakdown.digitalHotFoil.toFixed(3) + " €" ?? "0.491 €",
    {
      x: 1628,
      y: 430,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    rDataCostBreakdown.laborAndSetUp.toFixed(3) + " €" ?? "0.047 €",
    {
      x: 1628,
      y: 383,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  ninthPage.drawText(
    rDataCostBreakdown.otherConsumables.toFixed(3) + " €" ?? "0.019 €",
    {
      x: 1628,
      y: 336,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  ninthPage.drawText(
    (allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets).toFixed(3) +
      " €" ?? "0.019 €",
    {
      x: 1628,
      y: 289,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  ninthPage.drawText(
    rDataCostBreakdown.totalCostPerPage.toFixed(3) + " €" ?? "1.08 €",
    {
      x: 1628,
      y: 213,
      size: 28,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );

  // Draw percents
  ninthPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.substrate / rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.075 €",
    {
      x: 1720,
      y: 571,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.cmykPrint / rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.236 €",
    {
      x: 1720,
      y: 524,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.spotUvCoating /
          rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.217 €",
    {
      x: 1720,
      y: 477,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.digitalHotFoil /
          rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.491 €",
    {
      x: 1720,
      y: 430,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  ninthPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.laborAndSetUp /
          rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.047 €",
    {
      x: 1720,
      y: 383,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  ninthPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.otherConsumables /
          rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.019 €",
    {
      x: 1720,
      y: 336,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  ninthPage.drawText(
    "| " +
      (
        (allInputs.overhead /
          allInputs.jobInfoInputs.numberOfSheets /
          rDataCostBreakdown.totalCostPerPage) *
        100
      ).toFixed(2) +
      "%" ?? "0.019 €",
    {
      x: 1720,
      y: 289,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  // PAGE 9 LEFT
  tenPage.drawText(
    rDataCostBreakdown.substrate.toFixed(3) + " €" ?? "0.075 €",
    {
      x: 1628,
      y: 571,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    rDataCostBreakdown.cmykPrint.toFixed(3) + " €" ?? "0.236 €",
    {
      x: 1628,
      y: 524,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    rDataCostBreakdown.spotUvCoating.toFixed(3) + " €" ?? "0.217 €",
    {
      x: 1628,
      y: 477,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    rDataCostBreakdown.digitalHotFoil.toFixed(3) + " €" ?? "0.491 €",
    {
      x: 1628,
      y: 430,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    rDataCostBreakdown.laborAndSetUp.toFixed(3) + " €" ?? "0.047 €",
    {
      x: 1628,
      y: 383,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    rDataCostBreakdown.otherConsumables.toFixed(3) + " €" ?? "0.019 €",
    {
      x: 1628,
      y: 336,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    (allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets).toFixed(3) +
      " €" ?? "0.019 €",
    {
      x: 1628,
      y: 289,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    rDataCostBreakdown.capexCostPerPage.toFixed(3) + " €" ?? "0.019 €",
    {
      x: 1628,
      y: 242,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    rDataCostBreakdown.totalCostPerPageWithCapex.toFixed(3) + " €" ?? "1.08 €",
    {
      x: 1628,
      y: 166,
      size: 28,
      font: helveticaFont,
      color: rgb(0.184, 0.21, 0.88),
    }
  );

  // Draw percents
  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.substrate /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.075 €",
    {
      x: 1720,
      y: 571,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.cmykPrint /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.236 €",
    {
      x: 1720,
      y: 524,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.spotUvCoating /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.217 €",
    {
      x: 1720,
      y: 477,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.digitalHotFoil /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.491 €",
    {
      x: 1720,
      y: 430,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.laborAndSetUp /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.047 €",
    {
      x: 1720,
      y: 383,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.otherConsumables /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.019 €",
    {
      x: 1720,
      y: 336,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    "| " +
      (
        (allInputs.overhead /
          allInputs.jobInfoInputs.numberOfSheets /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.019 €",
    {
      x: 1720,
      y: 289,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );
  tenPage.drawText(
    "| " +
      (
        (rDataCostBreakdown.capexCostPerPage /
          rDataCostBreakdown.totalCostPerPageWithCapex) *
        100
      ).toFixed(2) +
      "%" ?? "0.019 €",
    {
      x: 1720,
      y: 242,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  //const pdfBytes = await pdfDoc.save();
  const pdfBytes = await finalPdf.save();
  download(
    pdfBytes,
    `ALPHAJET_ROI_${allInputs.roiInfo?.roiName}.pdf`,
    "application/pdf"
  );

  function getNearestWholeNumber(line1End: number, line2End: number) {
    const max = Math.max(line1End, line2End);

    const string = Math.round(max).toString();
    var numberEnd = "";
    if (parseInt(string[1]) < 5) {
      numberEnd = "5";
      for (let i = 0; i < string.length - 2; i++) {
        numberEnd += "0";
      }
      return parseInt(string[0] + numberEnd);
    } else {
      for (let i = 0; i < string.length - 1; i++) {
        numberEnd += "0";
      }
      return parseInt((parseInt(string[0]) + 1).toString() + numberEnd);
    }
  }

  //Function to get get array for graph
  function RoiArray(totalCostPerPage: number) {
    const equipement = allInputs.calibrationInputs.equipementCost;
    const lifeTimeFixedCost =
      allInputs.calibrationInputs.electricityCostPerKWHr * salesVolumePerMonth +
      allInputs.calibrationInputs.yearlyMaintenanceContract
        .yearlyMaintenanceContract;
    const lifeTimeVariableCost = totalCostPerPage * salesVolumePerMonth;
    const lifeTimeTotalCost =
      equipement + lifeTimeFixedCost + lifeTimeVariableCost;
    const lifeTimeTotalSales = salesPricePerSheet * salesVolumePerMonth;

    function roiArray(): RoiArrayType[] {
      const roiInitial: RoiArrayType = {
        month: 1,
        equipement: equipement,
        lifeTimeFixedCost: lifeTimeFixedCost,
        lifeTimeVariableCost: lifeTimeVariableCost,
        lifeTimeTotalCost: lifeTimeTotalCost,
        lifeTimeTotalSales: lifeTimeTotalSales,
        netProfit: lifeTimeTotalSales - lifeTimeTotalCost,
      };
      const array = [roiInitial];
      let breakEven = true;
      let i = 1;
      while (breakEven) {
        const tempLifeTimeFixedCost =
          array[i - 1].lifeTimeFixedCost + lifeTimeFixedCost;
        const tempLifeTimeVariableCost =
          array[i - 1].lifeTimeVariableCost + lifeTimeVariableCost;
        const tempLifeTimeTotalCost =
          equipement + tempLifeTimeFixedCost + tempLifeTimeVariableCost;
        const tempLifeTimeTotalSales =
          array[i - 1].lifeTimeTotalSales + lifeTimeTotalSales;
        const tempNetProfit = tempLifeTimeTotalSales - tempLifeTimeTotalCost;
        array.push({
          month: i + 1,
          equipement: equipement,
          lifeTimeFixedCost: tempLifeTimeFixedCost,
          lifeTimeVariableCost: tempLifeTimeVariableCost,
          lifeTimeTotalCost: tempLifeTimeTotalCost,
          lifeTimeTotalSales: tempLifeTimeTotalSales,
          netProfit: tempNetProfit,
        });
        i += 1;
        if (tempLifeTimeTotalSales * 0.95 > tempLifeTimeTotalCost || i === 99)
          breakEven = false;
      }
      return array;
    }

    return roiArray();
  }

  function BreakEvenAnalysis() {
    const sheetPerMonth = (salesVolumePerMonth * 0) / 10;

    const fixedCosts =
      rDataCapex.LeaseAmountPerMonth +
      allInputs.calibrationInputs.yearlyMaintenanceContract
        .yearlyMaintenanceContract /
        12;

    const variableCost = formatNumber(totalCostPerPage * sheetPerMonth);

    const totalCost = variableCost + fixedCosts;

    const totalSales = formatNumber(sheetPerMonth * salesPricePerSheet);

    const breakEvenInitial = {
      sheetPerMonth: sheetPerMonth,
      variableCost: variableCost,
      totalCost: totalCost,
      totalSales: totalSales,
    };
    const array = [breakEvenInitial];
    let breakEven = true;
    let i = 1;

    while (breakEven) {
      const tempSheetPerMonth = (salesVolumePerMonth * i) / 10;
      const tempVariableCost = formatNumber(
        totalCostPerPage * tempSheetPerMonth
      );
      const tempTotalCost = tempVariableCost + fixedCosts;
      const tempTotalSales = formatNumber(
        tempSheetPerMonth * salesPricePerSheet
      );
      array.push({
        sheetPerMonth: tempSheetPerMonth,
        variableCost: tempVariableCost,
        totalCost: tempTotalCost,
        totalSales: tempTotalSales,
      });
      i += 1;
      if (tempTotalSales * 0.95 > tempTotalCost || i === 99) breakEven = false;
    }
    return array;
  }
}

function whiteSpace(number: number) {
  return number.toLocaleString().replaceAll(" ", " ");
}

function replaceChars(text: string | undefined) {
  return text?.replaceAll("​", "");
}

function drawTableV2(
  amortizationPdf: PDFDocument,
  array: any[],
  dimension: {
    width: number;
    height: number;
  },
  font: PDFFont,
  boldFont: PDFFont
) {
  // Define the number of rows and columns in the table
  const numRows = array.length;
  const numCols = 6;

  // Define the space between cells
  const cellMargin = 0;

  const firstPage = amortizationPdf.addPage();
  firstPage.setWidth(dimension.width);
  firstPage.setHeight(dimension.height);

  // Define the width and height of each cell in the table
  const cellHeight = 30;
  const cellWidth =
    (firstPage.getWidth() - 0.1 * firstPage.getWidth() - cellMargin * numCols) /
    numCols;

  const numberOfTableToDraw = Math.ceil(
    (numRows * cellHeight) /
      (firstPage.getHeight() - 0.1 * firstPage.getHeight())
  );

  //Remove first page after getting width and height
  amortizationPdf.removePage(0);

  //Add all pages to draw
  for (let i = 0; i < numberOfTableToDraw; i++) {
    const page = amortizationPdf.addPage();
    page.setWidth(dimension.width);
    page.setHeight(dimension.height);
    drawTableOnPageFromIndex(
      page,
      i * Math.round((page.getHeight() - 0.1 * page.getHeight()) / cellHeight),
      numRows,
      i === numberOfTableToDraw - 1,
      array,
      font,
      boldFont
    );
  }
  return amortizationPdf;

  function drawTableOnPageFromIndex(
    page: PDFPage,
    arrayIndex: number,
    numRows: number,
    lastPage: boolean,
    array: amortizationTable[],
    font: PDFFont,
    boldFont: PDFFont
  ) {
    //Define the top left corner of the table
    const tableX = 0.05 * page.getWidth();
    const tableY = page.getHeight() - 0.05 * page.getHeight();

    // Number of rows you can draw on a page
    const numRowsOnPage = Math.round(
      (page.getHeight() - 0.1 * page.getHeight()) / cellHeight
    );

    const arrayValue: string[][] = [];
    const firstRow = arrayIndex;

    for (
      let i = arrayIndex;
      i < (lastPage ? numRows : numRowsOnPage + arrayIndex);
      i++
    ) {
      i === firstRow &&
        arrayValue.push([
          "Month",
          "Book value",
          "Lease Term",
          "Redemption",
          "Interest",
          "Residual Book Value",
        ]);
      arrayValue.push([
        array[i].month.toString(),
        array[i].bookValue.toFixed(2).toString(),
        array[i].leaseTerm.toFixed(2).toString(),
        array[i].redemption.toFixed(2).toString(),
        array[i].interest.toFixed(2).toString(),
        array[i].residualBookValue.toFixed(2).toString(),
      ]);
    }

    // Add rectangles and lines for each cell in the table

    for (let row = 0; row < arrayValue.length; row++) {
      for (let col = 0; col < numCols; col++) {
        const x1 = tableX + col * (cellWidth + cellMargin);
        const y1 = tableY - row * (cellHeight + cellMargin);

        // Draw a rectangle for the cell
        page.drawRectangle({
          x: x1,
          y: y1,
          width: cellWidth,
          height: cellHeight,
          borderWidth: 0.1,
          color: rgb(0.9, 0.9, 0.9),
          opacity: 1,
        });

        page.drawText(
          row === 0
            ? arrayValue[row][col]
            : whiteSpace(parseFloat(arrayValue[row][col])),
          {
            x: x1 + 0.2 * cellWidth,
            y: y1 + 0.3 * cellHeight,
            size: 20,
            font: row === 0 ? boldFont : font,
          }
        );
      }
    }
  }
}

function scenarioText(scenario: ScenarioType): string {
  switch (scenario) {
    case "job":
      return "Job Sales Price";
    case "customer":
      return "Customer Cost of Production";
    case "subcontract":
      return "Customer Subcontracting Price";
  }
}
