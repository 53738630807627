import classNames from "classnames";
import React from "react";
import styles from "./Approuve.module.scss";

export default function NoAccessIndicator() {
  return (
    <div className={classNames(styles.col12, styles.bat_modals_container)}>
      <div
        className={classNames(styles.col12, styles.bat_modals_authentificating)}
      >
        <div className={classNames(styles.col10, styles.bat_modal_margin2)}>
          <div className={classNames(styles.bat_modals_title, styles.col12)}>
            <h5 className={classNames(styles.bat_modals_titlecontent)}>
              Access denied
            </h5>
          </div>

          <div className={classNames(styles.bat_modals_import, styles.col12)}>
            You do not have the rights to access this app. Please contact an
            administrator of your group
          </div>
        </div>
      </div>
    </div>
  );
}
