import dataMachineCost from "./dataMachineCost";
import dataResult from "./dataResult";
import dataTimeLabor from "./dataTimeLabor";
import { AllInputs, totalConsumableCosts } from "./roiTypes";

export default function dataCostBreakdown(allInputs: AllInputs) {
  const nOfSheets = allInputs.jobInfoInputs.numberOfSheets;
  const currencyExchange = 1.0;

  const rDataResult = dataResult(allInputs, allInputs.overhead);

  const rDataMachineTimeCost = dataMachineCost(allInputs);

  const rDataTimeLabor = dataTimeLabor(allInputs);

  const totalTime60 = rDataTimeLabor.totalTime / 60;

  const consumables =
    (totalConsumableCosts() * currencyExchange * totalTime60) / nOfSheets;

  const electricityAndRent =
    ((rDataMachineTimeCost.powerConsumption +
      rDataMachineTimeCost.rentPerHour) *
      totalTime60) /
    nOfSheets;

  const capexCostPerPage =
    (rDataMachineTimeCost.hourlyCapexAmortization * totalTime60) / nOfSheets;

  const substrate = rDataResult.costOfMaterial / nOfSheets;

  const cmykPrint = rDataResult.costofCMYKPrint / nOfSheets;

  const spotUvCoating = rDataResult.costofVarnishUsed / nOfSheets;

  const digitalHotFoil = rDataResult.foilCosts / nOfSheets;

  const laborAndSetUp = rDataTimeLabor.jobLaborCost / nOfSheets;

  const otherCosts =
    allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets;

  const otherConsumables = consumables + electricityAndRent;

  const totalCostPerPage =
    substrate +
    cmykPrint +
    spotUvCoating +
    digitalHotFoil +
    laborAndSetUp +
    otherConsumables +
    otherCosts;

  const totalCostPerPageWithCapex =
    substrate +
    cmykPrint +
    spotUvCoating +
    digitalHotFoil +
    laborAndSetUp +
    otherConsumables +
    otherCosts +
    capexCostPerPage;

  return {
    substrate,
    cmykPrint,
    spotUvCoating,
    digitalHotFoil,
    laborAndSetUp,
    otherConsumables,
    totalCostPerPage,
    totalCostPerPageWithCapex,
    capexCostPerPage,
  };
}
