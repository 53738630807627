import useSWR from "swr";
import { useBackend } from "../backend/BackendProvider";

export function useRoi(id: number) {
  const backend = useBackend();
  const key = ["roi"];
  return useSWR(key, async (_) => {
    const test: any = await backend
      .getRoi(id)
      .then((e) => e)
      .catch(() => "Error");

    return test.error ? "Error" : test;
  });
}

export function useRoiList(uuid: string) {
  const backend = useBackend();
  const key = ["roiList"];
  return useSWR(key, async (_) => {
    return backend.getRoiList(uuid);
  });
}

// import { useEffect, useState } from "react";
// import { useBackend } from "../backend/BackendProvider";
// import { Roi } from "../components/roiTypes";

// export function FetchRoi(id: number) {
//   const backend = useBackend();

//   const [roi, setRoi] = useState<Roi>();
//   useEffect(() => {
//     async function fetchMachine() {
//       const response = await backend.getRoi(id);
//       setRoi(response);
//     }
//     fetchMachine();
//   }, [backend, id]);

//   return roi;
// }

// export function FetchRoiList() {
//   const backend = useBackend();

//   const [roi, setRoi] = useState<Roi[]>();
//   useEffect(() => {
//     async function fetchMachine() {
//       const response = await backend.getRoiList();
//       setRoi(response);
//     }
//     fetchMachine();
//   }, [backend]);

//   return roi;
// }
