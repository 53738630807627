const formula = require("@formulajs/formulajs");

export interface amortizationTable {
  month: number;
  bookValue: number;
  leaseTerm: number;
  redemption: number;
  interest: number;
  residualBookValue: number;
}

// E6 : lease forme (Fl = Financial or OL = Net Operational)
// E7 : interest
// E8 : lease duration
// E10 : funding amount
// E14 : Lease amount per month

// =SI(ET(E$6="fl";C18="Maand");INTPER(E$7/12;1;E$8;E10;E$11-1)*-1;" ")
//IPMT(0.1/12, 6, 2*12, 100000, 1000000, 0)
// Duration --> La durée de l'amortissement
// Book Value (E)
// =SI(E$6="fl";E$10-G18;"n.v.t.")
// Lease Term (F)
// =SI(D18=A18;E$14;" ")
// Redemption (G)
// =SI(ET(E$6="fl";C18="maand");F18-H18;" ")
// Interest (H)
// =SI(ET(E$6="fl";C18="Maand");INTPER(E$7/12;1;E$8;E10;E$11-1)*-1;" ")

export function generateAmortizationTable(
  duration: number,
  leaseForm: string,
  interestEntry: number,
  fundingAmount: number,
  LeaseAmountPerMonth: number
): amortizationTable[] {
  const amortTable: amortizationTable[] = [];

  for (let i = 0; i < duration; i++) {
    const interest = -formula.IPMT(
      interestEntry / 100 / 12,
      1,
      duration,
      i === 0 ? fundingAmount : amortTable[i - 1].bookValue,
      0
    );

    const leaseTerm = LeaseAmountPerMonth;
    const redemption = leaseTerm - interest;
    const bookValue =
      i === 0 ? fundingAmount : amortTable[i - 1].bookValue - redemption;
    const residualBookValue = bookValue - redemption;

    amortTable.push({
      month: i,
      bookValue: bookValue,
      leaseTerm: leaseTerm,
      redemption: redemption,
      interest: interest,
      residualBookValue: residualBookValue,
    });
  }

  return amortTable;
}
