import { Auth } from "@mgi-labs/mgi-id";
import { AllInputs, Roi } from "../components/roiTypes";

export default class Backend {
  constructor(
    public readonly rootUrl: string,
    public readonly auth: Auth,
    private readonly fetch: typeof window.fetch = (...args) =>
      window.fetch(...args)
  ) {}
  async fetchWithAuth(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response> {
    await this.auth?.refreshSession();
    init.headers = new Headers(init.headers);
    init.headers.set("Authorization", `Bearer ${this.auth?.token}`);
    return await this.fetch(input, init);
  }

  async addRoi(allInputs: AllInputs) {
    const url = this.expandURL("/projects");
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: allInputs,
        confirm: false,
      }),
    });
    const dto = await response.json();
    return dto;
  }

  async duplicateRoi(id: number) {
    const url = this.expandURL(`/projects/duplicate/${id}`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const dto = await response.json();
    return dto;
  }

  async updateRoi(id: number, allInputs: AllInputs, confirm: boolean) {
    const url = this.expandURL(`/projects/${id}`);
    return await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: allInputs,
        confirm: confirm,
      }),
    });
  }

  async getRoi(id: number) {
    const url = this.expandURL(`/projects/${id}`);
    const response = await this.fetchWithAuth(url.toString());
    const dto = (await response.json()) as Roi;
    return dto;
  }

  async getRoiList(uuid: string) {
    const url = this.expandURL(`/projects/list/${uuid}`);
    const response = await this.fetchWithAuth(url.toString());
    const dto = (await response.json()) as Roi[];
    return dto;
  }

  async deleteRoi(roiList: Number[]) {
    const url = this.expandURL(`/projects`);
    await this.fetchWithAuth(url.toString(), {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(roiList),
    });
  }

  private expandURL(url: string): string {
    return this.rootUrl ? new URL(url, this.rootUrl).toString() : url;
  }
}
