import React from "react";
import classNames from "classnames";
import styles from "./loading.module.scss";

const Loading = () => {
  return (
    <>
      <div className={classNames(styles.loadingzone)}>
        <div className={classNames(styles.loading)}>
          <div className={classNames(styles.ldsring)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Loading;
