import React, { useMemo } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
//import dashboardimg from "../image/img/dashboardimg.png";
import { Typography } from "@material-ui/core";
import { AllInputs } from "./roiTypes";
import Chartgoogle, { Chart } from "react-google-charts";
import Equalizer from "@material-ui/icons/Equalizer";
import { Search } from "@material-ui/icons";
import dataCostBreakdown from "./dataCostBreakdown";
import { formatNumber } from "./formatNumber";
import { Link, useRouteMatch } from "react-router-dom";
import dataCapex from "./dataCapex";

export type RoiArrayType = {
  month: number;
  equipement: number;
  lifeTimeFixedCost: number;
  lifeTimeVariableCost: number;
  lifeTimeTotalCost: number;
  lifeTimeTotalSales: number;
  netProfit: number;
};

const Dashboard = ({
  allInputs,
  inputEstimatedSheets,
}: {
  allInputs: AllInputs;
  inputEstimatedSheets: { [key: string]: number };
}) => {
  const rDataCostBreakdown = dataCostBreakdown(allInputs);

  const rDataCapex = dataCapex(allInputs);

  // const labor =
  //   allInputs.jobInfoInputs.alphaJetInfos.costPerOperatorPerHour *
  //   allInputs.calibrationInputs.yearlyMaintenanceContract
  //     .productionHoursPerDay *
  //   allInputs.calibrationInputs.yearlyMaintenanceContract.workingDaysPerWeek;

  const salesPricePerSheet =
    allInputs.jobInfoInputs.alphaJetInfos.jobSalesPrice /
    allInputs.jobInfoInputs.numberOfSheets;

  const salesVolumePerMonth = useMemo(
    () =>
      Math.round(
        Object.values(inputEstimatedSheets).reduce((a, b) => a + b, 0) /
          Object.keys(inputEstimatedSheets).length /
          12
      ),
    [inputEstimatedSheets]
  );

  const totalCostPerPage = rDataCostBreakdown.totalCostPerPage;

  const totalCostPerPageOverhead =
    rDataCostBreakdown.totalCostPerPage +
    allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets;

  const otherCosts =
    allInputs.overhead / allInputs.jobInfoInputs.numberOfSheets;

  function RoiArray(totalCostPerPage: number) {
    const equipement = allInputs.calibrationInputs.equipementCost;
    const lifeTimeFixedCost =
      allInputs.calibrationInputs.electricityCostPerKWHr * salesVolumePerMonth +
      allInputs.calibrationInputs.yearlyMaintenanceContract
        .yearlyMaintenanceContract;
    const lifeTimeVariableCost = totalCostPerPage * salesVolumePerMonth;
    const lifeTimeTotalCost =
      equipement + lifeTimeFixedCost + lifeTimeVariableCost;
    const lifeTimeTotalSales = salesPricePerSheet * salesVolumePerMonth;

    const roiArray: RoiArrayType[] = useMemo(() => {
      const roiInitial: RoiArrayType = {
        month: 1,
        equipement: equipement,
        lifeTimeFixedCost: lifeTimeFixedCost,
        lifeTimeVariableCost: lifeTimeVariableCost,
        lifeTimeTotalCost: lifeTimeTotalCost,
        lifeTimeTotalSales: lifeTimeTotalSales,
        netProfit: lifeTimeTotalSales - lifeTimeTotalCost,
      };
      const array = [roiInitial];
      let breakEven = true;
      let i = 1;
      while (breakEven) {
        const tempLifeTimeFixedCost =
          array[i - 1].lifeTimeFixedCost + lifeTimeFixedCost;
        const tempLifeTimeVariableCost =
          array[i - 1].lifeTimeVariableCost + lifeTimeVariableCost;
        const tempLifeTimeTotalCost =
          equipement + tempLifeTimeFixedCost + tempLifeTimeVariableCost;
        const tempLifeTimeTotalSales =
          array[i - 1].lifeTimeTotalSales + lifeTimeTotalSales;
        const tempNetProfit = tempLifeTimeTotalSales - tempLifeTimeTotalCost;
        array.push({
          month: i + 1,
          equipement: equipement,
          lifeTimeFixedCost: tempLifeTimeFixedCost,
          lifeTimeVariableCost: tempLifeTimeVariableCost,
          lifeTimeTotalCost: tempLifeTimeTotalCost,
          lifeTimeTotalSales: tempLifeTimeTotalSales,
          netProfit: tempNetProfit,
        });
        i += 1;
        if (tempLifeTimeTotalSales * 0.95 > tempLifeTimeTotalCost || i === 99)
          breakEven = false;
      }
      return array;
    }, [
      equipement,
      lifeTimeFixedCost,
      lifeTimeTotalCost,
      lifeTimeTotalSales,
      lifeTimeVariableCost,
    ]);

    return roiArray;
  }

  const roiArray = RoiArray(totalCostPerPage);
  const roiOverheadArray = RoiArray(totalCostPerPageOverhead);

  const finalRoiArray = useMemo(() => {
    const array: (string | number)[][] = [];
    roiArray.forEach((roi) =>
      array.push([roi.month, roi.lifeTimeTotalCost, roi.lifeTimeTotalSales])
    );
    return [
      ["Month", "Lifetime Total costs", "Lifetime Total sales"],
      ...array,
    ];
  }, [roiArray]);

  const finalRoiOverheadArray = useMemo(() => {
    const array: (string | number)[][] = [];
    roiOverheadArray.forEach((roi) =>
      array.push([roi.month, roi.lifeTimeTotalCost, roi.lifeTimeTotalSales])
    );
    return [
      ["Month", "Lifetime Total costs", "Lifetime Total sales"],
      ...array,
    ];
  }, [roiOverheadArray]);

  function BreakEvenAnalysis() {
    const sheetPerMonth = (salesVolumePerMonth * 0) / 10;

    const fixedCosts =
      rDataCapex.LeaseAmountPerMonth +
      allInputs.calibrationInputs.yearlyMaintenanceContract
        .yearlyMaintenanceContract /
        12;

    const variableCost = formatNumber(totalCostPerPage * sheetPerMonth);

    const totalCost = variableCost + fixedCosts;

    const totalSales = formatNumber(sheetPerMonth * salesPricePerSheet);

    // const variableCostArray = useMemo(() => {
    //   const array: number[] = [];
    //   sheetPerMonthArray.forEach((sheets) =>
    //     array.push(formatNumber(totalCostPerPage * sheets))
    //   );
    //   return array;
    // }, [sheetPerMonthArray, totalCostPerPage]);

    // const totalCostArray = useMemo(() => {
    //   const array: number[] = [];
    //   variableCostArray.forEach((variableCost) =>
    //     array.push(formatNumber(variableCost + fixedCosts))
    //   );
    //   return array;
    // }, [fixedCosts, variableCostArray]);

    // const totalSalesArray = useMemo(() => {
    //   const array: number[] = [];
    //   sheetPerMonthArray.forEach((sheets) =>
    //     array.push(formatNumber(sheets * salesPricePerSheet))
    //   );
    //   return array;
    // }, [salesPricePerSheet, sheetPerMonthArray]);

    // return totalSalesArray;

    const breakEvenInitial = {
      sheetPerMonth: sheetPerMonth,
      variableCost: variableCost,
      totalCost: totalCost,
      totalSales: totalSales,
    };
    const array = [breakEvenInitial];
    let breakEven = true;
    let i = 1;

    while (breakEven) {
      const tempSheetPerMonth = (salesVolumePerMonth * i) / 10;
      const tempVariableCost = formatNumber(
        totalCostPerPage * tempSheetPerMonth
      );
      const tempTotalCost = tempVariableCost + fixedCosts;
      const tempTotalSales = formatNumber(
        tempSheetPerMonth * salesPricePerSheet
      );
      array.push({
        sheetPerMonth: tempSheetPerMonth,
        variableCost: tempVariableCost,
        totalCost: tempTotalCost,
        totalSales: tempTotalSales,
      });
      i += 1;
      if (tempTotalSales * 0.95 > tempTotalCost || i === 99) breakEven = false;
    }
    return array;
  }

  const breakEvenArray = BreakEvenAnalysis();

  const finalDataArray = useMemo(() => {
    const array: (string | number)[][] = [];
    breakEvenArray.forEach((data) =>
      array.push([data.sheetPerMonth, data.totalCost, data.totalSales])
    );
    return [["Year", "Total costs", "Total sales"], ...array];
  }, [breakEvenArray]);

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const roiId = () => {
    if (routeMatch?.params.roiId) return routeMatch.params.roiId;
    else return "";
  };

  return (
    <div className={classNames(styles.customer, styles.height100)}>
      <Link
        className={classNames(styles.closedashboard)}
        to={"/roi/" + roiId()}
      >
        <span>Back</span>
      </Link>
      <Grid xs={12} className={classNames(styles.paddinglr10, styles.topform)}>
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10}>
            <Typography variant="h5">COST PER PAGE BREAKDOWN</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classNames(styles.dashboardzone, styles.inputcontent)}
        xs={12}
      >
        <Grid className={styles.alignstart} xs={1}>
          <Equalizer color="primary" />
        </Grid>
        <Grid
          xs={11}
          className={classNames(styles.dashboardcontent, styles.dispflex)}
        >
          <Grid xs={6} className={classNames(styles.dashgraph)}>
            <Chartgoogle
              width={"100%"}
              height={"100%"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["Sheet Detailed Breakdown", "Price"],
                ["Substrate", rDataCostBreakdown.substrate],
                ["CMYK Print", rDataCostBreakdown.cmykPrint],
                ["Spot UV Coaling", rDataCostBreakdown.spotUvCoating],
                ["Digital Hot-Foil", rDataCostBreakdown.digitalHotFoil],
                ["Labor and Set Up", rDataCostBreakdown.laborAndSetUp],
                ["Other Consumables", rDataCostBreakdown.otherConsumables],
                ["Other Costs", otherCosts],
              ]}
              options={{
                // Just add this option
                pieHole: 0.4,
                legend: "none",
                slices: {
                  0: { color: "#780695" },
                  1: { color: "#DD3910" },
                  2: { color: "#179718" },
                  3: { color: "#3366CC" },
                  4: { color: "#20C1E3" },
                  5: { color: "#FD9900" },
                  6: { color: "#FA6BAD" },
                },
              }}
              rootProps={{ "data-testid": "3" }}
            />
          </Grid>
          <Grid xs={6} className={classNames(styles.dispflex, styles.dashtext)}>
            <Grid xs={2} className={classNames(styles.heightalign)}>
              <Search
                className={classNames(styles.marginauto)}
                color="primary"
              ></Search>
            </Grid>
            <Grid xs={10}>
              <Typography
                className={classNames(styles.fontW600, styles.heightalign)}
              >
                PER SHEET DETAILED BREAKDOWN
              </Typography>
            </Grid>
            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.orange)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Substrate</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.substrate, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.blue1)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>CMYK Print</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.cmykPrint, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.blue2)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Spot UV Coating</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.spotUvCoating, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.blue3)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Digital Hot-Foil</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.digitalHotFoil, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.yellow1)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Labor and Set Up</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.laborAndSetUp, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.yellow2)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(
                styles.dispflex,
                styles.lastone,
                styles.heightalign
              )}
            >
              <Typography>Other Consumables</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.otherConsumables, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.rose)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(
                styles.dispflex,
                styles.lastone,
                styles.heightalign
              )}
            >
              <Typography>Other costs</Typography>
              <Typography>{formatNumber(otherCosts, 3)} €</Typography>
            </Grid>

            <Grid xs={12} className={classNames(styles.totalcost)}>
              <Typography
                className={classNames(styles.textalignend, styles.colorblue)}
              >
                Total Cost Per Page
              </Typography>
              <Typography
                className={classNames(
                  styles.textalignend,
                  styles.colorblue,
                  styles.price
                )}
              >
                {formatNumber(rDataCostBreakdown.totalCostPerPage, 3)} €
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} className={classNames(styles.paddinglr10, styles.topform)}>
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10}>
            <Typography variant="h5">
              COST PER PAGE BREAKDOWN WITH CAPEX
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(styles.dashboardzone, styles.inputcontent)}
        xs={12}
      >
        <Grid className={styles.alignstart} xs={1}>
          <Equalizer color="primary" />
        </Grid>

        <Grid
          xs={11}
          className={classNames(styles.dashboardcontent, styles.dispflex)}
        >
          <Grid xs={6} className={classNames(styles.dashgraph)}>
            <Chartgoogle
              width={"100%"}
              height={"100%"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["Sheet Detailed Breakdown", "Price"],
                ["Substrate", rDataCostBreakdown.substrate],
                ["CMYK Print", rDataCostBreakdown.cmykPrint],
                ["Spot UV Coaling", rDataCostBreakdown.spotUvCoating],
                ["Digital Hot-Foil", rDataCostBreakdown.digitalHotFoil],
                ["Labor and Set Up", rDataCostBreakdown.laborAndSetUp],
                ["Other Consumables", rDataCostBreakdown.otherConsumables],
                ["Other Costs", otherCosts],
                ["Capex per page", rDataCostBreakdown.capexCostPerPage],
              ]}
              options={{
                // Just add this option
                pieHole: 0.4,
                legend: "none",
                slices: {
                  0: { color: "#780695" },
                  1: { color: "#DD3910" },
                  2: { color: "#179718" },
                  3: { color: "#3366CC" },
                  4: { color: "#20C1E3" },
                  5: { color: "#FD9900" },
                  6: { color: "#FA6BAD" },
                  7: { color: "#FDCD00" },
                },
              }}
              rootProps={{ "data-testid": "3" }}
            />
          </Grid>
          <Grid xs={6} className={classNames(styles.dispflex, styles.dashtext)}>
            <Grid xs={2} className={classNames(styles.heightalign)}>
              <Search
                className={classNames(styles.marginauto)}
                color="primary"
              ></Search>
            </Grid>
            <Grid xs={10}>
              <Typography
                className={classNames(styles.fontW600, styles.heightalign)}
              >
                PER SHEET DETAILED BREAKDOWN WITH CAPEX
              </Typography>
            </Grid>
            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.orange)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Substrate</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.substrate, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.blue1)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>CMYK Print</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.cmykPrint, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.blue2)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Spot UV Coating</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.spotUvCoating, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.blue3)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Digital Hot-Foil</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.digitalHotFoil, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.yellow1)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(styles.dispflex, styles.heightalign)}
            >
              <Typography>Labor and Set Up</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.laborAndSetUp, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.yellow2)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(
                styles.dispflex,
                styles.lastone,
                styles.heightalign
              )}
            >
              <Typography>Other Consumables</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.otherConsumables, 3)} €
              </Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.rose)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(
                styles.dispflex,
                styles.lastone,
                styles.heightalign
              )}
            >
              <Typography>Other costs</Typography>
              <Typography>{formatNumber(otherCosts, 3)} €</Typography>
            </Grid>

            <Grid xs={2} className={classNames(styles.heightalign)}>
              <div className={classNames(styles.legend, styles.yellow3)}></div>
            </Grid>
            <Grid
              xs={10}
              className={classNames(
                styles.dispflex,
                styles.lastone,
                styles.heightalign
              )}
            >
              <Typography>Capex per page</Typography>
              <Typography>
                {formatNumber(rDataCostBreakdown.capexCostPerPage, 3)} €
              </Typography>
            </Grid>

            <Grid xs={12} className={classNames(styles.totalcost)}>
              <Typography
                className={classNames(styles.textalignend, styles.colorblue)}
              >
                Total Cost Per Page With Capex
              </Typography>
              <Typography
                className={classNames(
                  styles.textalignend,
                  styles.colorblue,
                  styles.price
                )}
              >
                {formatNumber(rDataCostBreakdown.totalCostPerPageWithCapex, 3)}{" "}
                €
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classNames(styles.dashboardzone, styles.inputcontent)}
        xs={12}
      >
        <Grid
          xs={11}
          className={classNames(styles.dashboardcontent, styles.dispflex)}
        >
          <Grid xs={12} className={classNames(styles.dashgraph)}>
            <Chart
              width={"100%"}
              height={"100%"}
              chartType="AreaChart"
              loader={<div>Loading Chart</div>}
              data={finalDataArray}
              options={{
                title: "Break Even Analysis",
                hAxis: {
                  title: "Sheet par month",
                  titleTextStyle: { color: "#333" },
                },
                vAxis: { minValue: 0 },
                // For the legend to fit, we make the chart area smaller
                chartArea: { width: "50%", height: "70%" },
                // lineWidth: 25
              }}
              // For tests
              rootProps={{ "data-testid": "1" }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classNames(styles.dashboardzone, styles.inputcontent)}
        xs={12}
      >
        <Grid
          xs={11}
          className={classNames(styles.dashboardcontent, styles.dispflex)}
        >
          <Grid xs={6} className={classNames(styles.dashgraph)}>
            <Chart
              width={"100%"}
              height={"100%"}
              chartType="AreaChart"
              loader={<div>Loading Chart</div>}
              data={finalRoiArray}
              options={{
                title: "Return on Investment",
                hAxis: {
                  title: "Months",
                  titleTextStyle: { color: "#333" },
                },
                vAxis: { minValue: 0 },
                // For the legend to fit, we make the chart area smaller
                chartArea: { width: "70%", height: "70%" },
                // lineWidth: 25
              }}
              // For tests
              rootProps={{ "data-testid": "1" }}
            />
          </Grid>

          <Grid xs={6} className={classNames(styles.dashgraph)}>
            <Chart
              width={"100%"}
              height={"100%"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={finalRoiOverheadArray}
              options={{
                title: "Return on Investment",
                hAxis: {
                  title: "Months",
                },

                series: {
                  1: { curveType: "function" },
                },
              }}
              rootProps={{ "data-testid": "2" }}
            />
          </Grid>
        </Grid>
      </Grid>

      <div className={classNames(styles.dashboardcontent)}></div>
      <div className={classNames(styles.dashboardtext)}></div>
      {/*<img src={dashboardimg} alt="Dashboard" />*/}
    </div>
  );
};
export default Dashboard;
