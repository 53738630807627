import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Businessicon from "@material-ui/icons/Business";
import Accountcircle from "@material-ui/icons/AccountCircle";
import Email from "@material-ui/icons/AlternateEmail";
import Tel from "@material-ui/icons/Call";
import Paper from "@material-ui/icons/NoteOutlined";
import Flag from "@material-ui/icons/Flag";
import House from "@material-ui/icons/HomeOutlined";
import Work from "@material-ui/icons/WorkOutline";
import Setting from "@material-ui/icons/SettingsBackupRestore";
import Trending from "@material-ui/icons/TrendingUp";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import {
  AllInputs,
  allInputsSchema,
  CustomerInputs,
  customerInputsSchema,
  RoiInfo,
  roiInfoSchema,
} from "./roiTypes";
import Prevnext from "./prevnext";
import { useBackend } from "../backend/BackendProvider";
import { useHistory } from "react-router";
import SwitchPageInput from "./switchPageInput";

const Customer = ({
  register,
  setValue,
  control,
  errors,
  handleSubmit,
  setCustomerInputs,
  setAllInputsId,
  allInputs,
  isInputForm,
  roiInfo,
  setRoiInfo,
  setIsLoading,
}: {
  register: any;
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined
  ) => void;
  control: Control<AllInputs>;
  errors: DeepMap<AllInputs, FieldError>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AllInputs>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<AllInputs> | undefined
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  setCustomerInputs: React.Dispatch<CustomerInputs | undefined>;
  setAllInputsId: React.Dispatch<number | undefined>;
  allInputs: AllInputs;
  isInputForm: boolean;
  roiInfo: RoiInfo | undefined;
  setRoiInfo: React.Dispatch<RoiInfo | undefined>;
  setIsLoading: React.Dispatch<boolean>;
}) => {
  const history = useHistory();

  const backend = useBackend();

  const [clicked, setClicked] = useState<string>("calibration");

  const roiAddHandler = useCallback(
    async (data: AllInputs) => {
      if (setCustomerInputs) {
        setCustomerInputs(data.customerInputs);
        if (allInputs.id) {
          const castedCustomerInput = customerInputsSchema.cast(
            data.customerInputs
          ) as unknown as CustomerInputs;

          setCustomerInputs(castedCustomerInput);

          const castedInfo = roiInfoSchema.cast(
            data.roiInfo
          ) as unknown as RoiInfo;

          setRoiInfo(castedInfo);
          const newAllInputs = new AllInputs(
            allInputs.calibrationInputs,
            allInputs.jobInfoInputs,
            allInputs.inputEstimatedSheets,
            allInputs.overhead,
            allInputs.otherCostType,
            castedCustomerInput,
            castedInfo,
            allInputs.id,
            allInputs.foilSurface
          );

          setIsLoading(true);
          try {
            await backend.updateRoi(
              allInputs.id,
              allInputsSchema.cast(newAllInputs) as unknown as AllInputs,
              false
            );
          } finally {
            setIsLoading(false);
            setAllInputsId && setAllInputsId(allInputs.id);
            history.push(`/${clicked}/${allInputs.id}`, allInputs.id);
          }
        } else {
          const castedInfo = roiInfoSchema.cast(
            data.roiInfo
          ) as unknown as RoiInfo;

          setRoiInfo(castedInfo);

          let newRoi;

          try {
            newRoi = await backend.addRoi(
              new AllInputs(
                allInputs.calibrationInputs,
                allInputs.jobInfoInputs,
                allInputs.inputEstimatedSheets,
                allInputs.overhead,
                allInputs.otherCostType,
                data.customerInputs,
                castedInfo
              )
            );
            setIsLoading(true);
          } finally {
            setIsLoading(false);
            setAllInputsId && setAllInputsId(newRoi.id);
            history.push(`/${clicked}/${newRoi.id}`, newRoi.id);
          }
        }
      }
    },
    [
      allInputs.calibrationInputs,
      allInputs.foilSurface,
      allInputs.id,
      allInputs.inputEstimatedSheets,
      allInputs.jobInfoInputs,
      allInputs.otherCostType,
      allInputs.overhead,
      backend,
      clicked,
      history,
      setAllInputsId,
      setCustomerInputs,
      setIsLoading,
      setRoiInfo,
    ]
  );

  const names = ["B1 (70 x 1000mm)", "B1+ (75 x 1200mm)", "B2 (520 x 740 mm)"];
  const market = [
    "Digital Printing",
    "Commercial Printing",
    "Packaging",
    "Web-on-Line",
    "Book Printer",
    "Autre",
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [customerMarket, setCustomerMarket] = useState<string[]>(
    allInputs.customerInputs ? allInputs.customerInputs?.customerMarket : []
  );

  const [country, setCountry] = useState<string>(
    allInputs.customerInputs ? allInputs.customerInputs?.country : ""
  );

  const [mainProductionFormat, setMainProductionFormat] = useState<string[]>(
    allInputs.customerInputs
      ? allInputs.customerInputs?.mainProductionFormat
      : []
  );

  return (
    <form
      autoComplete="off"
      style={{
        overflow: !isInputForm ? "scroll" : "",
        height: !isInputForm ? "calc(100%);" : "",
      }}
      onSubmit={handleSubmit(roiAddHandler)}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          //e.preventDefault();
        }
      }}
    >
      <SwitchPageInput setClicked={setClicked} allInputs={allInputs} />
      <Grid xs={12} className={styles.customer}>
        <Grid
          xs={12}
          className={classNames(
            styles.paddinglr10,
            styles.topform,
            styles.dispflex
          )}
        >
          <Grid className={styles.inputcontent} xs={6}>
            <Grid xs={2}></Grid>
            <Grid xs={10}>
              <Typography variant="h5">ROI NAME</Typography>
            </Grid>
          </Grid>
          <Grid className={styles.inputcontent} xs={6}>
            <Grid className={styles.iconcontent} xs={2}></Grid>
            <Grid xs={10}>
              <Typography className={styles.importanttext}>
                <span className={styles.colorred}>*</span> Fields marked with an
                asterisk are required
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} className={styles.dispflex}>
          <Grid xs={12} className={classNames(styles.paddinglr10)}>
            <Grid className={styles.inputcontent} xs={12}>
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid xs={11}>
                <TextField
                  className={classNames(styles.yellow, styles.required)}
                  fullWidth
                  required
                  md-no-asterisk
                  margin="normal"
                  size="small"
                  id="outlined-basic"
                  label="ROI Name"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  defaultValue={roiInfo?.roiName}
                  name="roiInfo.roiName"
                />
              </Grid>
              {errors?.customerInputs?.companyName?.types?.required && (
                <p>ROI Name</p>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} className={styles.dispflex}>
          <Grid xs={12} className={classNames(styles.paddinglr10)}>
            <Grid className={styles.inputcontent} xs={12}>
              <Grid className={styles.iconcontent} xs={1}></Grid>
              <Grid xs={11}>
                <TextField
                  className={classNames(styles.yellow)}
                  id="outlined-basic"
                  fullWidth
                  md-no-asterisk
                  label="ROI description"
                  multiline
                  rowsMax={4}
                  //ref={textRef}
                  inputRef={register({ required: true })}
                  variant="outlined"
                  defaultValue={roiInfo?.roiDesc}
                  name="roiInfo.roiDesc"
                  size="small"
                />
                {/* <TextField
                  className={classNames(styles.yellow)}
                  fullWidth
                  md-no-asterisk
                  margin="normal"
                  size="small"
                  id="outlined-basic"
                  label="ROI description"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  defaultValue={roiName}
                  name="roiName"
                  multiline
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          className={classNames(
            styles.paddinglr10,
            styles.topform,
            styles.dispflex
          )}
        >
          <Grid
            className={classNames(styles.inputcontent, styles.margint15)}
            xs={6}
          >
            <Grid xs={2}></Grid>
            <Grid xs={10}>
              <Typography variant="h5">CUSTOMER INFORMATION</Typography>
            </Grid>
          </Grid>
        </Grid>
        <FormControl className={styles.formControl}>
          <Grid xs={12} className={styles.dispflex}>
            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Businessicon color="primary" />
                </Grid>
                <Grid xs={10}>
                  <TextField
                    className={classNames(styles.yellow, styles.required)}
                    fullWidth
                    required
                    md-no-asterisk
                    margin="normal"
                    size="small"
                    id="outlined-basic"
                    label="Company's Name"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    defaultValue={allInputs.customerInputs?.companyName}
                    name="customerInputs.companyName"
                  />
                </Grid>
                {errors?.customerInputs?.companyName?.types?.required && (
                  <p>company name required</p>
                )}
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Accountcircle color="primary" />
                </Grid>
                <Grid xs={10}>
                  <TextField
                    className={classNames(styles.yellow, styles.required)}
                    fullWidth
                    size="small"
                    margin="normal"
                    id="outlined-basic"
                    label="Contact’s Name"
                    variant="outlined"
                    inputRef={register}
                    defaultValue={allInputs.customerInputs?.contactName}
                    name="customerInputs.contactName"
                  />
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Email color="primary" />
                </Grid>
                <Grid xs={10}>
                  <TextField
                    className={classNames(styles.yellow, styles.required)}
                    fullWidth
                    required
                    size="small"
                    margin="normal"
                    id="outlined-basic"
                    label="Contact's E-mail"
                    variant="outlined"
                    inputRef={register}
                    defaultValue={allInputs.customerInputs?.contactMail}
                    name="customerInputs.contactMail"
                  />
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Tel color="primary" />
                </Grid>
                <Grid xs={10}>
                  <TextField
                    className={styles.yellow}
                    fullWidth
                    margin="normal"
                    size="small"
                    id="outlined-basic"
                    label="Contact's Telephone"
                    variant="outlined"
                    inputRef={register}
                    defaultValue={allInputs.customerInputs?.contactPhone}
                    name="customerInputs.contactPhone"
                  />
                </Grid>
              </Grid>
              <Grid className={styles.multiselect} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Paper color="primary" />
                </Grid>
                <Grid xs={10}>
                  <FormControl
                    size="small"
                    margin="normal"
                    className={classNames(
                      styles.formControl,
                      styles.yellow,
                      styles.borderform
                    )}
                  >
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Customer main production format ?
                    </InputLabel>
                    <Controller
                      render={() => (
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          input={<Input />}
                          value={mainProductionFormat}
                          renderValue={(selected) => {
                            return (selected as string[]).join(", ");
                          }}
                          MenuProps={MenuProps}
                          displayEmpty
                          onChange={(e) => {
                            setMainProductionFormat(e.target.value as string[]);
                            setValue(
                              "customerInputs.mainProductionFormat",
                              e.target.value
                            );
                          }}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={
                                  mainProductionFormat.indexOf(name) > -1
                                }
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name="customerInputs.mainProductionFormat"
                      control={control}
                      defaultValue={mainProductionFormat}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10}>
                  <Typography>
                    Which digital solution does the customer have in house ?
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.textfield} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    margin="none"
                    id="standard-basic"
                    label="Your answer"
                    name="customerInputs.digitalSolution"
                    inputRef={register}
                    defaultValue={allInputs.customerInputs?.digitalSolution}
                  />
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10}>
                  <Typography>Offset printing type ?</Typography>
                </Grid>
              </Grid>
              <Grid className={styles.textfield} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid xs={10}>
                  <TextField
                    size="small"
                    margin="none"
                    fullWidth
                    id="standard-basic"
                    label="Your answer"
                    name="customerInputs.offsetPrintingType"
                    defaultValue={allInputs.customerInputs?.offsetPrintingType}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Flag color="primary" />
                </Grid>
                <Grid xs={10}>
                  <Controller
                    render={() => (
                      <Autocomplete
                        fullWidth
                        size="small"
                        id="filter-demo"
                        options={allCountries}
                        onChange={(_, option) => {
                          option && setCountry(option);
                          setValue("customerInputs.country", option);
                        }}
                        value={country}
                        defaultValue={country}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            autoComplete="nope"
                            size="small"
                            variant="outlined"
                            margin="normal"
                            className={classNames(
                              styles.formControl,
                              styles.yellow,
                              styles.required
                            )}
                            label="Country"
                          />
                        )}
                      />
                    )}
                    name="customerInputs.country"
                    control={control}
                    defaultValue={country}
                  />
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <House color="primary" />
                </Grid>
                <Grid xs={10}>
                  <TextField
                    fullWidth
                    className={classNames(styles.yellow)}
                    size="small"
                    margin="normal"
                    id="outlined-basic"
                    label="Company’s Address"
                    variant="outlined"
                    name="customerInputs.companyAddress"
                    defaultValue={allInputs.customerInputs?.companyAddress}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Setting color="primary" />
                </Grid>
                <Grid xs={10}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    className={classNames(
                      styles.formControl,
                      styles.yellow,
                      styles.required
                    )}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Company’s Turnover
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Company’s Turnover"
                          name="customerInputs.companyTurnover"
                        >
                          <MenuItem value="None">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"<5M"}>{"< 5 Millions €"}</MenuItem>
                          <MenuItem value={"<10M"}>
                            5 Millions € - 10 Millions €
                          </MenuItem>
                          <MenuItem value={"<50M"}>
                            10 Millions € - 50 Millions €
                          </MenuItem>
                          <MenuItem value={"<500M"}>
                            50 Millions € - 500 Millions €
                          </MenuItem>
                          <MenuItem value={"500M"}>
                            {"> 500 Millions €"}
                          </MenuItem>
                        </Select>
                      }
                      name="customerInputs.companyTurnover"
                      control={control}
                      defaultValue={
                        allInputs.customerInputs
                          ? allInputs.customerInputs?.companyTurnover
                          : "None"
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid className={styles.multiselect} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Work color="primary" />
                </Grid>
                <Grid xs={10}>
                  <FormControl
                    size="small"
                    margin="normal"
                    required
                    className={classNames(
                      styles.formControl,
                      styles.yellow,
                      styles.required,
                      styles.borderform
                    )}
                  >
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Customer’s Market / Industry
                    </InputLabel>
                    <Controller
                      render={() => (
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          name="customerInputs.customerMarket"
                          multiple
                          value={customerMarket}
                          defaultValue={customerMarket}
                          onChange={(e) => {
                            setCustomerMarket(e.target.value as string[]);
                            setValue(
                              "customerInputs.customerMarket",
                              e.target.value
                            );
                          }}
                          input={<Input />}
                          renderValue={(selected) =>
                            (selected as string[]).join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {market.map((market) => (
                            <MenuItem key={market} value={market}>
                              <Checkbox
                                checked={customerMarket.indexOf(market) > -1}
                              />
                              <ListItemText primary={market} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name="customerInputs.customerMarket"
                      control={control}
                      defaultValue={customerMarket}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Trending color="primary" />
                </Grid>
                <Grid xs={10}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    required
                    className={classNames(styles.formControl, styles.yellow)}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Investment project?
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Investment Project?"
                          name="customerInputs.investmentProject"
                        >
                          <MenuItem value="None">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"1 month"}>1 month</MenuItem>
                          <MenuItem value={"3 months"}>3 months</MenuItem>
                          <MenuItem value={"6 month"}>6 months</MenuItem>
                          <MenuItem value={"1 year"}>1 year</MenuItem>
                          <MenuItem value={"+1 year"}>+ 1 year</MenuItem>
                        </Select>
                      }
                      name="customerInputs.investmentProject"
                      defaultValue={
                        allInputs.customerInputs?.investmentProject
                          ? allInputs.customerInputs?.investmentProject
                          : "None"
                      }
                      control={control}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid xs={2}></Grid>
                <Grid xs={10}>
                  <Typography>
                    Does the customer currently sell spot UV & hot Foil stamping
                    ?
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid xs={2}></Grid>
                <Grid xs={10}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={
                      allInputs.customerInputs?.spotUvHotFoilStamping
                        ? "true"
                        : "false"
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" color="primary" />}
                      label="Yes"
                      inputRef={register}
                      name="customerInputs.spotUvHotFoilStamping"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" color="primary" />}
                      label="No"
                      inputRef={register}
                      name="customerInputs.spotUvHotFoilStamping"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid className={styles.textfield} xs={12}>
                <Grid xs={2}></Grid>
                <Grid xs={10}>
                  <Typography>
                    What is yearly volume of embellishment ?
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    margin="normal"
                    id="standard-basic"
                    label="Your answer"
                    name="customerInputs.yearlyEmbelishmentVolume"
                    defaultValue={
                      allInputs.customerInputs?.yearlyEmbelishmentVolume
                    }
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <input type="submit" /> */}
        </FormControl>
        <Prevnext isInputForm next="calibration" />
      </Grid>
    </form>
  );
};
export default Customer;

const allCountries = [
  "Andorra",
  "United Arab Emirates",
  "Afghanistan",
  "Antigua and Barbuda",
  "Anguilla",
  "Albania",
  "Armenia",
  "Angola",
  "Antarctica",
  "Argentina",
  "American Samoa",
  "Austria",
  "Australia",
  "Aruba",
  "Alland Islands",
  "Azerbaijan",
  "Bosnia and Herzegovina",
  "Barbados",
  "Bangladesh",
  "Belgium",
  "Burkina Faso",
  "Bulgaria",
  "Bahrain",
  "Burundi",
  "Benin",
  "Saint Barthelemy",
  "Bermuda",
  "Brunei Darussalam",
  "Bolivia",
  "Brazil",
  "Bahamas",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Belarus",
  "Belize",
  "Canada",
  "Cocos (Keeling) Islands",
  "Congo, Democratic Republic of the",
  "Central African Republic",
  "Congo, Republic of the",
  "Switzerland",
  "Cote d'Ivoire",
  "Cook Islands",
  "Chile",
  "Cameroon",
  "China",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Cape Verde",
  "Curacao",
  "Christmas Island",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Djibouti",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Estonia",
  "Egypt",
  "Western Sahara",
  "Eritrea",
  "Spain",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands (Malvinas)",
  "Micronesia, Federated States of",
  "Faroe Islands",
  "France",
  "Gabon",
  "United Kingdom",
  "Grenada",
  "Georgia",
  "French Guiana",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Greenland",
  "Gambia",
  "Guinea",
  "Guadeloupe",
  "Equatorial Guinea",
  "Greece",
  "South Georgia and the South Sandwich Islands",
  "Guatemala",
  "Guam",
  "Guinea-Bissau",
  "Guyana",
  "Hong Kong",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Ireland",
  "Israel",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Iraq",
  "Iran, Islamic Republic of",
  "Iceland",
  "Italy",
  "Jersey",
  "Jamaica",
  "Jordan",
  "Japan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Comoros",
  "Saint Kitts and Nevis",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Cayman Islands",
  "Kazakhstan",
  "Lao People's Democratic Republic",
  "Lebanon",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Liberia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Libya",
  "Morocco",
  "Monaco",
  "Moldova, Republic of",
  "Montenegro",
  "Saint Martin (French part)",
  "Madagascar",
  "Marshall Islands",
  "Macedonia, the Former Yugoslav Republic of",
  "Mali",
  "Myanmar",
  "Mongolia",
  "Macao",
  "Northern Mariana Islands",
  "Martinique",
  "Mauritania",
  "Montserrat",
  "Malta",
  "Mauritius",
  "Maldives",
  "Malawi",
  "Mexico",
  "Malaysia",
  "Mozambique",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "Niue",
  "New Zealand",
  "Oman",
  "Panama",
  "Peru",
  "French Polynesia",
  "Papua New Guinea",
  "Philippines",
  "Pakistan",
  "Poland",
  "Saint Pierre and Miquelon",
  "Pitcairn",
  "Puerto Rico",
  "Palestine, State of",
  "Portugal",
  "Palau",
  "Paraguay",
  "Qatar",
  "Reunion",
  "Romania",
  "Serbia",
  "Russian Federation",
  "Rwanda",
  "Saudi Arabia",
  "Solomon Islands",
  "Seychelles",
  "Sudan",
  "Sweden",
  "Singapore",
  "Saint Helena",
  "Slovenia",
  "Svalbard and Jan Mayen",
  "Slovakia",
  "Sierra Leone",
  "San Marino",
  "Senegal",
  "Somalia",
  "Suriname",
  "South Sudan",
  "Sao Tome and Principe",
  "El Salvador",
  "Sint Maarten (Dutch part)",
  "Syrian Arab Republic",
  "Swaziland",
  "Turks and Caicos Islands",
  "Chad",
  "French Southern Territories",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Timor-Leste",
  "Turkmenistan",
  "Tunisia",
  "Tonga",
  "Turkey",
  "Trinidad and Tobago",
  "Tuvalu",
  "Taiwan, Province of China",
  "United Republic of Tanzania",
  "Ukraine",
  "Uganda",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Holy See (Vatican City State)",
  "Saint Vincent and the Grenadines",
  "Venezuela",
  "British Virgin Islands",
  "US Virgin Islands",
  "Vietnam",
  "Vanuatu",
  "Wallis and Futuna",
  "Samoa",
  "Kosovo",
  "Yemen",
  "Mayotte",
  "South Africa",
  "Zambia",
  "Zimbabwe",
];
