export function formatNumber(num: number, fixed = 2) {
  const round = Math.pow(10, fixed);
  return Math.round(num * round) / round;
}

// Function to add whitespace between number
export function whiteSpaceNumber(value: string | number) {
  const newValue = typeof value === "number" ? value.toString() : value;

  // Prevent NaN
  if (isNaN(parseInt(newValue))) {
    return "";
  }

  const splittedValue = newValue.split(/[,.]/);

  if (newValue === "" || splittedValue[1] === "") {
    if (splittedValue.length > 2) {
      return splittedValue[0] + ".";
    } else {
      return newValue;
    }
  } else {
    // When number is float
    if (splittedValue.length > 1) {
      const numberValue1 = parseInt(splittedValue[0].replace(/\s/g, ""));

      // const tempValue2 = parseFloat("0." + splittedValue[1].replace(/\s/g, ""));
      // const finalValue2 = tempValue2.toLocaleString().split(/[,.]/);
      const tempValue2 = splittedValue[1].replace(/\s/g, "");

      return numberValue1.toLocaleString() + "." + tempValue2;
    } else {
      // When number is int
      const numberValue = parseInt(newValue.replace(/\s/g, ""));
      return numberValue.toLocaleString();
    }
  }
}

// Function to remove white space for calculation
export function removeWhiteSpaceInNumber(number: number | string) {
  if (typeof number === "string") {
    return parseFloat(number.replace(/\s/g, ""));
  } else {
    return parseFloat(number.toString().replace(/\s/g, ""));
  }
}
