export const usersAuthorized = [
  "edabergel@mgi-fr.com",
  "l.gautier@mgi-fr.com",
  "j.walther@mgi-fr.com",
  "s.zimmermann@mgi-fr.com",
  "m.labane@mgi-fr.com",
  "vabergel@mgi-fr.com",
  "a.tavernier@mgi-fr.com",
  "p.fardo@mgi-fr.com",
  "f.mossler@mgi-fr.com",
];
