import * as yup from "yup";

export function Round2(value: number | undefined) {
  if (value) return Math.round(value * 100) / 100;
  else return 0;
}

const consumableSchema = yup.object().shape({
  lifetime: yup.number(),
  quantity: yup.number(),
  cost: yup.number(),
});

export const customerInputsSchema = yup
  .object()
  .shape({
    companyName: yup.string().required(),
    contactName: yup.string().required(),
    contactMail: yup.string().required(),
    contactPhone: yup.string(),
    mainProductionFormat: yup.array(yup.string()).required(),
    country: yup.string().required(),
    companyAddress: yup.string(),
    companyTurnover: yup.string(),
    customerMarket: yup.array(yup.string()).required(),
    investmentProject: yup.string(),
    digitalSolution: yup.string(),
    offsetPrintingType: yup.string(),
    spotUvHotFoilStamping: yup.boolean().nullable(),
    yearlyEmbelishmentVolum: yup.string(),
  })
  .default(undefined);

export const calibrationInputsSchema = yup
  .object()
  .shape({
    equipementCost: yup.number().required(),
    amortizationPeriod: yup.number().required(),
    interestLeasingRate: yup.number().required(),
    leaseDownpayment: yup.number().required(),
    yearlyMaintenanceContract: yup.object().shape({
      yearlyMaintenanceContract: yup.number().required(),
      maintenanceContractLength: yup.number().required(),
      mgiConnectSubscription: yup.number().required(),
      monthPerYear: yup.number().required(),
      workingDaysPerMonth: yup.number().required(),
      workingDaysPerWeek: yup.number().required(),
      productionHoursPerDay: yup.number().required(),
    }),
    rentCostPerMonthPerSqm2: yup.number().required(),
    electricityCostPerKWHr: yup.number().required(),
    alphaJetGlobalPrintingCosts: yup.object().shape({
      alphaJetGlobalPrintingCost: yup.number(),
      costPerUvVarnishLiter: yup.number(),
      costPrimer: yup.number(),
      table: yup.object().shape({
        inkjetHeadsCleaningFlush: consumableSchema,
        headWipingCloth: consumableSchema,
        uvLampCost: consumableSchema,
        infraredLampCost: consumableSchema,
        dustStickyRollerCost: consumableSchema,
        epdmFoilRollerCost: consumableSchema,
        transportCleaningRollerCost: consumableSchema,
      }),
    }),
    foilInformation: yup.object().shape({
      foilMasterRollWidth: yup.number(),
      foilRollLength: yup.number(),
      foilMasterRollCost: yup.number(),
    }),
    isSqm: yup.boolean(),
    sqmPrice: yup.number(),
  })
  .default(undefined);

export const jobInfoInputsSchema = yup
  .object()
  .shape({
    paperFormat: yup.object().shape({
      length: yup.number().required(),
      width: yup.number().required(),
    }),
    upsPerSheet: yup.number(),
    numberOfSheets: yup.number().required(),
    weight: yup.number().required(),
    front: yup.object().shape({
      cmykPrintFileInkCoverage: yup.number(),
      embellishmentSpotUvCoverage: yup.number(),
      embellishmentUv2d3dEffect: yup.number(),
      digitalHotFoilCoverage: yup.number(),
      digitalHotFoil2d3dEffect: yup.number(),
      opv: yup.number(),
      opv2d3dEffect: yup.number(),
      optimizedFoilUsagePerSheet: yup
        .object()
        .shape({
          maximizedFoilWidthUsageRollWidth: yup.number().nullable(),
          maximizedFoilLengthUsagePerSheet: yup.number().nullable(),
          FoilWasterPerRollUpDown: yup.number().nullable(),
          TotalFoilLengthUsagePerSheet: yup.number().nullable(),
        })
        .nullable(),
    }),
    back: yup.object().shape({
      cmykPrintFileInkCoverage: yup.number(),
      embellishmentSpotUvCoverage: yup.number(),
      embellishmentUv2d3dEffect: yup.number(),
      digitalHotFoilCoverage: yup.number(),
      digitalHotFoil2d3dEffect: yup.number(),
      opv: yup.number(),
      opv2d3dEffect: yup.number(),
      optimizedFoilUsagePerSheet: yup
        .object()
        .shape({
          maximizedFoilWidthUsageRollWidth: yup.number().nullable(),
          maximizedFoilLengthUsagePerSheet: yup.number().nullable(),
          FoilWasterPerRollUpDown: yup.number().nullable(),
          TotalFoilLengthUsagePerSheet: yup.number().nullable(),
        })
        .nullable(),
    }),
    doubleSided: yup.boolean().nullable(),
    internalEcoConso: yup.boolean().nullable(),
    isInKg: yup.boolean().nullable(),
    paperCostForJob: yup.number(),

    alphaJetInfos: yup.object().shape({
      alphaJetOperatorsNumber: yup.number().required(),
      costPerOperatorPerHour: yup.number().required(),
      substratCostPer1000Sheet: yup.number().required(),
      substratCostPerKg: yup.number().required(),
      alphaJetPrintingSpeedPerHour: yup.number().required(),
      alphaJetProductivity: yup.number().required(),
      jobSalesPrice: yup.number().required(),
      scenario: yup.string().required(),
    }),
  })
  .default(undefined);

export const timeAndLaborSchema = yup
  .object()
  .shape({
    totalSetUpTime: yup.object().shape({
      editingAndPreFlight: yup.number().required(),
      RIP: yup.number().required(),
      paperLoading: yup.number().required(),
      testSheets: yup.number().required(),
      qualityControl: yup.number().required(),
    }),
    totalProductionTime: yup.object().shape({
      pureProductionTime: yup.number().required(),
    }),
  })
  .default(undefined);

export const resultSchema = yup.number();

export const otherCostSchema = yup.string();

export const roiInfoSchema = yup.object().shape({
  roiName: yup.string(),
  roiDesc: yup.string(),
});

export const allInputsSchema = yup
  .object()
  .shape({
    id: yup.number(),
    customerInputs: customerInputsSchema,
    calibrationInputs: calibrationInputsSchema,
    jobInfoInputs: jobInfoInputsSchema,
    timeAndLabor: timeAndLaborSchema,
    result: resultSchema,
    roiInfo: roiInfoSchema,
    foilSurface: yup.number(),
  })
  .default(undefined);

export type Roi = {
  data: AllInputs;
  confirm: boolean;
  id: number;
  userName: string;
  created: Date;
  modified: Date;
};

export type RoiInfo = {
  roiName?: string;
  roiDesc?: string;
};

export type OtherCostType = "fix" | "total" | "retail";

export class AllInputs {
  calibrationInputs: CalibrationInputs;
  jobInfoInputs: JobInfoInputs;
  inputEstimatedSheets: { [key: string]: number };
  overhead: number;
  otherCostType: OtherCostType;
  customerInputs?: CustomerInputs;
  id?: number;
  roiInfo?: RoiInfo;
  foilSurface?: number;

  constructor(
    calibrationInputs: CalibrationInputs,
    jobInfoInputs: JobInfoInputs,
    inputEstimatedSheets: { [key: string]: number },
    overhead: number,
    otherCostType: OtherCostType,
    customerInputs?: CustomerInputs,
    roiInfo?: RoiInfo,
    id?: number,
    foilSurface?: number
  ) {
    this.id = id;
    this.customerInputs = customerInputs;
    this.calibrationInputs = calibrationInputs;
    this.jobInfoInputs = jobInfoInputs;
    this.inputEstimatedSheets = inputEstimatedSheets;
    this.overhead = overhead;
    this.otherCostType = otherCostType;
    this.roiInfo = roiInfo;
    this.foilSurface = foilSurface;
  }
}

export type CustomerInputs = {
  companyName: string;
  contactName: string;
  contactMail: string;
  contactPhone?: string;
  mainProductionFormat: string[];
  country: string;
  companyAddress: string;
  companyTurnover: string;
  customerMarket: string[];
  investmentProject?: string;
  digitalSolution?: string;
  offsetPrintingType?: string;
  spotUvHotFoilStamping?: boolean;
  yearlyEmbelishmentVolume?: number;
};

export type CalibrationInputs = {
  equipementCost: number;
  amortizationPeriod: number;
  interestLeasingRate: number;
  leaseDownpayment: number;
  yearlyMaintenanceContract: YearlyMaintenanceContract;
  rentCostPerMonthPerSqm2: number;
  electricityCostPerKWHr: number;
  alphaJetGlobalPrintingCosts: AlphaJetGlobalCosts;
  foilInformation: FoilInformation;
  isSqm: boolean;
  sqmPrice: number;
};

export type FaceInfos = {
  cmykPrintFileInkCoverage: number;
  embellishmentSpotUvCoverage: number;
  embellishmentUv2d3dEffect: number;
  digitalHotFoilCoverage: number;
  digitalHotFoil2d3dEffect: number;
  opv: number;
  opv2d3dEffect: number;
  optimizedFoilUsagePerSheet: OptimizedFoilUsagePerSheet;
};

export type JobInfoInputs = {
  paperFormat: { length: number; width: number };
  upsPerSheet: number;
  numberOfSheets: number;
  weight: number;
  front: FaceInfos;
  back: FaceInfos;
  doubleSided: boolean;
  internalEcoConso: boolean;
  isInKg: boolean;
  alphaJetInfos: AlphaJetInfos;
  paperCostForJob: number;
};

export type YearlyMaintenanceContract = {
  yearlyMaintenanceContract: number;
  maintenanceContractLength: number;
  mgiConnectSubscription: number;
  monthPerYear: number;
  workingDaysPerMonth: number;
  workingDaysPerWeek: number;
  productionHoursPerDay: number;
};

export type FoilInformation = {
  foilMasterRollWidth: number;
  foilRollLength: number;
  foilMasterRollCost: number;
};

export class ConsumableTable {
  name: string;
  lifetime: number;
  quantity: number;
  cost: number;

  constructor(name: string, lifetime: number, quantity: number, cost: number) {
    this.name = name;
    this.lifetime = lifetime;
    this.quantity = quantity;
    this.cost = cost;
  }

  getCostPerHour() {
    return (this.cost * this.quantity) / this.lifetime;
  }
}

export type AlphaJetGlobalCosts = {
  alphaJetGlobalPrintingCost: number;
  costPerUvVarnishLiter: number;
  costPrimer: number;
  table: AlphaJetGlobalPrintingCosts;
};

export class AlphaJetGlobalPrintingCosts {
  readonly table: {
    inkjetHeadsCleaningFlush: ConsumableTable;
    headWipingCloth: ConsumableTable;
    uvLampCost: ConsumableTable;
    infraredLampCost: ConsumableTable;
    dustStickyRollerCost: ConsumableTable;
    epdmFoilRollerCost: ConsumableTable;
    transportCleaningRollerCost: ConsumableTable;
  } = {
    inkjetHeadsCleaningFlush: new ConsumableTable(
      "Inkjet heads cleaning flush",
      120,
      1,
      51
    ),
    headWipingCloth: new ConsumableTable("Head wiping cloth", 75, 1, 51),
    uvLampCost: new ConsumableTable("UV lamp", 1500, 3, 675),
    infraredLampCost: new ConsumableTable("Infrared lamp", 12000, 2, 655),
    dustStickyRollerCost: new ConsumableTable(
      "Dust sticky roller",
      8765,
      1,
      228
    ),
    epdmFoilRollerCost: new ConsumableTable("EPDM Foil roller", 2000, 1, 3631),
    transportCleaningRollerCost: new ConsumableTable(
      "Transport cleaning roller",
      8765,
      1,
      213
    ),
  };
}

export type OptimizedFoilUsagePerSheet = {
  maximizedFoilWidthUsageRollWidth: number;
  maximizedFoilLengthUsagePerSheet: number;
  FoilMasterPerRollUpDown: number;
  TotalFoilLengthUsagePerSheet: number;
};

export type ScenarioType = "job" | "customer" | "subcontract";

export type AlphaJetInfos = {
  alphaJetOperatorsNumber: number;
  costPerOperatorPerHour: number;
  substratCostPer1000Sheet: number;
  substratCostPerKg: number;
  alphaJetPrintingSpeedPerHour: number;
  alphaJetProductivity: number;
  jobSalesPrice: number;
  scenario: ScenarioType;
};

export type TimeAndLabor = {
  totalSetUpTime: TotalSetUpTime;
};

export class TotalSetUpTime {
  readonly editingAndPreFlight: number = 0;
  readonly RIP: number = 0;
  readonly paperLoading: number = 10;
  readonly testSheets: number = 0;
  readonly qualityControl: number = 0;

  getTotalTime(doubleSided?: boolean) {
    const total =
      this.editingAndPreFlight +
      this.RIP +
      this.paperLoading +
      this.testSheets +
      this.qualityControl;

    return doubleSided ? total + 6 : total;
  }
}

export class MachineTimeCost {
  readonly powerConsumption: number = 9.0;
}

export const timeAndLabor: TimeAndLabor = {
  totalSetUpTime: new TotalSetUpTime(),
};

export function mapMicronToValue(micron: number | undefined) {
  switch (micron) {
    case 3:
      return 0.7;
    case 7:
      return 0.7;
    case 14:
      return 0.7;
    case 28:
      return 0.628;
    case 35:
      return 0.519;
    case 42:
      return 0.41;
    case 56:
      return 0.314;
    case 63:
      return 0.2825;
    case 77:
      return 0.251;
    case 112:
      return 0.179;
    default:
      return 0;
  }
}

export function totalConsumableCosts() {
  let sum = 0;
  Object.values(new AlphaJetGlobalPrintingCosts().table).forEach((val) => {
    sum += val.getCostPerHour();
  });
  return Round2(sum);
}

export const defaultCalibrationInput: CalibrationInputs = {
  equipementCost: 3200000,
  amortizationPeriod: 72,
  interestLeasingRate: 2,
  leaseDownpayment: 0,
  yearlyMaintenanceContract: {
    yearlyMaintenanceContract: 72000,
    maintenanceContractLength: 1,
    mgiConnectSubscription: 0,
    monthPerYear: 12,
    workingDaysPerMonth: 21,
    workingDaysPerWeek: 5,
    productionHoursPerDay: 8,
  },
  rentCostPerMonthPerSqm2: 15,
  electricityCostPerKWHr: 0.08,
  alphaJetGlobalPrintingCosts: {
    alphaJetGlobalPrintingCost: 90,
    costPerUvVarnishLiter: 73,
    costPrimer: 6.5,
    table: new AlphaJetGlobalPrintingCosts(),
  },
  foilInformation: {
    foilMasterRollWidth: 640,
    foilRollLength: 400,
    foilMasterRollCost: 110,
  },
  isSqm: true,
  sqmPrice: 0.45,
};

export const defaultJobInfoInput: JobInfoInputs = {
  paperFormat: { length: 1020, width: 720 },
  upsPerSheet: 0,
  numberOfSheets: 1000,
  weight: 100,
  front: {
    cmykPrintFileInkCoverage: 30,
    embellishmentSpotUvCoverage: 10,
    embellishmentUv2d3dEffect: 7,
    digitalHotFoilCoverage: 0,
    digitalHotFoil2d3dEffect: 0,
    opv: 0,
    opv2d3dEffect: 0,
    optimizedFoilUsagePerSheet: {
      maximizedFoilWidthUsageRollWidth: 700,
      maximizedFoilLengthUsagePerSheet: 1000,
      TotalFoilLengthUsagePerSheet: 1050.8,
      FoilMasterPerRollUpDown: 50.8,
    },
  },
  back: {
    cmykPrintFileInkCoverage: 0,
    embellishmentSpotUvCoverage: 0,
    embellishmentUv2d3dEffect: 0,
    digitalHotFoilCoverage: 0,
    digitalHotFoil2d3dEffect: 0,
    opv: 0,
    opv2d3dEffect: 0,
    optimizedFoilUsagePerSheet: {
      maximizedFoilWidthUsageRollWidth: 700,
      maximizedFoilLengthUsagePerSheet: 1000,
      TotalFoilLengthUsagePerSheet: 1050.8,
      FoilMasterPerRollUpDown: 50.8,
    },
  },
  doubleSided: false,
  internalEcoConso: true,
  isInKg: false,
  paperCostForJob: 170,
  alphaJetInfos: {
    alphaJetOperatorsNumber: 1,
    costPerOperatorPerHour: 30,
    substratCostPer1000Sheet: 170,
    substratCostPerKg: 3,
    alphaJetPrintingSpeedPerHour: 1800,
    alphaJetProductivity: 80,
    jobSalesPrice: 2200,
    scenario: "job",
  },
};

export const defaultInputEstimatedSheet = { year0: 0 };

export const defaultOverHead = 0;
