import React from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";
import dataMachineCost from "./dataMachineCost";
import { Typography } from "@material-ui/core";
import { AllInputs, totalConsumableCosts } from "./roiTypes";
import { formatNumber, whiteSpaceNumber } from "./formatNumber";
import { isIncluded } from "./consumables";

const Machinetimecost = ({ allInputs }: { allInputs: AllInputs }) => {
  const dataFMachineCost = dataMachineCost(allInputs);

  return (
    <div className={classNames(styles.customer, styles.height100)}>
      <Grid xs={12} className={classNames(styles.paddinglr10, styles.topform)}>
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10}>
            <Typography variant="h5">HOURLY MACHINE TIME COST</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid className={classNames(styles.inputcontent)} xs={12}>
        <Grid className={styles.dispflex} xs={10}>
          <Grid className={classNames(styles.algnitem)} xs={10}>
            <Typography variant="h6">LABOR</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography className={styles.alignend6} variant="h6">
              {dataFMachineCost.labor} €
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      {dataFMachineCost.hourlyMgiConnectSubscription ? (
        <>
          <Grid className={classNames(styles.inputcontent)} xs={12}>
            <Grid className={styles.dispflex} xs={10}>
              <Grid className={classNames(styles.algnitem)} xs={10}>
                <Typography variant="h6">
                  HOURLY MGI CONNECT SUBSCRIPTION
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className={styles.alignend6} variant="h6">
                  {whiteSpaceNumber(
                    formatNumber(
                      dataFMachineCost.hourlyMgiConnectSubscription,
                      2
                    )
                  )}{" "}
                  €
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
        </>
      ) : (
        ""
      )}
      {dataFMachineCost.hourlyMaintenanceContract ? (
        <>
          <Grid className={classNames(styles.inputcontent)} xs={12}>
            <Grid className={styles.dispflex} xs={10}>
              <Grid className={classNames(styles.algnitem)} xs={10}>
                <Typography variant="h6">
                  HOURLY MAINTENANCE CONTRACT
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className={styles.alignend6} variant="h6">
                  {whiteSpaceNumber(
                    formatNumber(dataFMachineCost.hourlyMaintenanceContract, 2)
                  )}{" "}
                  €
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
        </>
      ) : (
        ""
      )}
      <Grid className={classNames(styles.inputcontent)} xs={12}>
        <Grid className={styles.dispflex} xs={10}>
          <Grid className={classNames(styles.algnitem)} xs={10}>
            <Typography variant="h6">
              POWER CONSUMPTION BASED ON €{" "}
              {allInputs.calibrationInputs.electricityCostPerKWHr}/KILOWATT
              HOUR&nbsp;
              {allInputs.jobInfoInputs.front.embellishmentUv2d3dEffect < 0.14
                ? "USING 2D IR AND UV"
                : "USING 3D LED AND UV"}
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Typography className={styles.alignend6} variant="h6">
              {whiteSpaceNumber(
                formatNumber(dataFMachineCost.powerConsumption)
              )}{" "}
              €
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid className={classNames(styles.inputcontent)} xs={12}>
        <Grid className={styles.dispflex} xs={10}>
          <Grid className={classNames(styles.algnitem)} xs={10}>
            <Typography variant="h6">
              RENT PER HOUR : 150 SQUARE METERS (OVERHEAD)
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Typography className={styles.alignend6} variant="h6">
              {whiteSpaceNumber(dataFMachineCost.rentPerHour)} €
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      {isIncluded(totalConsumableCosts(), allInputs) !== "Included" && (
        <>
          <Grid className={classNames(styles.inputcontent)} xs={12}>
            <Grid className={styles.dispflex} xs={10}>
              <Grid className={classNames(styles.algnitem)} xs={10}>
                <Typography variant="h6">ALL OTHER CONSUMABLES</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className={styles.alignend6} variant="h6">
                  {whiteSpaceNumber(totalConsumableCosts())} €
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
          <Grid
            className={classNames(
              styles.inputcontent,
              styles.margint15,
              styles.dispflex
            )}
            xs={12}
          ></Grid>
        </>
      )}
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid className={classNames(styles.inputcontent)} xs={12}>
        <Grid
          className={classNames(styles.dispflex, styles.bluecontent)}
          xs={10}
        >
          <Grid className={classNames(styles.algnitem)} xs={8}>
            <Typography variant="h5">
              TOTAL HOURLY COST <b color="primary">WITHOUT</b> CAPEX
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography
              className={styles.alignend6}
              variant="h5"
              color="primary"
            >
              {whiteSpaceNumber(
                formatNumber(dataFMachineCost.totalCostWithouthCapex)
              )}{" "}
              €
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid className={classNames(styles.inputcontent)} xs={12}>
        <Grid className={styles.dispflex} xs={10}>
          <Grid className={classNames(styles.algnitem)} xs={9}>
            <Typography>
              HOURLY CAPEX AMORTIZATION <br /> CAPEX : Including Machine and
              Interest
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className={styles.alignend6} variant="h5">
              {whiteSpaceNumber(
                formatNumber(dataFMachineCost.hourlyCapexAmortization)
              )}{" "}
              €
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>{" "}
      <Grid
        className={classNames(
          styles.inputcontent,
          styles.margint15,
          styles.dispflex
        )}
        xs={12}
      ></Grid>
      <Grid className={classNames(styles.inputcontent)} xs={12}>
        <Grid
          className={classNames(styles.dispflex, styles.bluecontent)}
          xs={10}
        >
          <Grid className={classNames(styles.algnitem)} xs={8}>
            <Typography variant="h5">
              TOTAL HOURLY COST <b color="primary">WITH</b> CAPEX
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography
              className={styles.alignend6}
              variant="h5"
              color="primary"
            >
              {whiteSpaceNumber(
                formatNumber(dataFMachineCost.totalCostWithCapex)
              )}{" "}
              €
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Machinetimecost;
