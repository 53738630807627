import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import Switchpage from "./switchpage";
import Formcontent from "./formcontent";

import mgikm from "../image/logo/kmmgi.svg";

import alphajet from "../image/logo/alphajet.svg";

import { ThemeType, TopBar, useAuth } from "@mgi-labs/mgi-id";

import { useForm } from "react-hook-form";
import {
  AllInputs,
  allInputsSchema,
  CalibrationInputs,
  CustomerInputs,
  JobInfoInputs,
  OtherCostType,
  RoiInfo,
} from "./roiTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { Route, Switch, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import Loading from "../Loading";
import i18n from "@mgi-labs/mgi-id/dist/Components/Internationalization/i18n";
import logo from "../image/img/alphajet.png";
import DownloadModal from "./DownloadModal";
const drawerWidth = 240;

const paddingrl = "calc(5px + 5vw)";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },

    hide: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    content: {
      flexGrow: 1,
      paddingLeft: paddingrl,
      paddingRight: paddingrl,

      paddingBottom: 0,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    yellow: {
      backgroundColor: "#FFF7BE",
    },
    "& label.Mui-focused": {
      color: "#2E35DB !important",
    },
    "& label.MuiInputLabel-root": {
      color: "rgb(0 0 0 / 70%)",
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      color: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        color: "black",
      },
      "&:hover fieldset": {},
      "&.Mui-focused fieldset": {},
    },
  })
);

let vraitheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#2e35db",
      light: "#F3F3FD",
      dark: "#303f9f",
      contrastText: "#fff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#2E35DB",
    },
  },
});
vraitheme = responsiveFontSizes(vraitheme);

export default function Roi({
  allInputs,
  setAllInputsId,
  setCustomerInputs,
  setCalibrationInputs,
  setJobInfoInputs,
  setInputEstimatedSheets,
  overhead,
  setOverHead,
  roiInfo,
  setRoiInfo,
  inputEstimatedSheets,
  otherCost,
  setOtherCost,
}: {
  allInputs: AllInputs;
  setAllInputsId: React.Dispatch<number | undefined>;
  setJobInfoInputs: React.Dispatch<JobInfoInputs>;
  inputEstimatedSheets: { [key: string]: number };
  setInputEstimatedSheets: React.Dispatch<{ [key: string]: number }>;
  setCustomerInputs: React.Dispatch<CustomerInputs | undefined>;
  setCalibrationInputs: React.Dispatch<CalibrationInputs>;
  overhead: number;
  setOverHead: React.Dispatch<number>;
  roiInfo: RoiInfo | undefined;
  setRoiInfo: React.Dispatch<RoiInfo | undefined>;
  otherCost: OtherCostType;
  setOtherCost: React.Dispatch<OtherCostType>;
}) {
  const classes = useStyles();

  const open = false;

  const location = useLocation();
  const history = useHistory();

  // useEffect(() => {
  //   if (roiTest) {
  //     setCustomerInputs(roiTest.data?.customerInputs);
  //     setCalibrationInputs(roiTest.data?.calibrationInputs);
  //     setJobInfoInputs(roiTest.data?.jobInfoInputs);
  //     setInputEstimatedSheets(roiTest.data?.inputEstimatedSheets);
  //     setOverHead(roiTest.data?.overhead);
  //   }
  // }, [roiTest]);

  const { register, setValue, control, watch, errors, handleSubmit } =
    useForm<AllInputs>({
      resolver: yupResolver(allInputsSchema),
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const { initialized, auth } = useAuth();

  const isInputForm =
    !location.pathname.includes("customer") &&
    !location.pathname.includes("calibration") &&
    !location.pathname.includes("roi") &&
    !location.pathname.includes("job-info") &&
    !location.pathname.includes("result") &&
    !location.pathname.includes("roiTable");

  const changeLang = useCallback((lang) => {
    i18n.changeLanguage(lang);
  }, []);
  const [theme] = useState<ThemeType>("light");
  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return (
    <div className={classes.root}>
      {isLoading && <Loading />}
      {isOpenModal && (
        <DownloadModal
          setIsOpenModal={setIsOpenModal}
          overhead={overhead}
          allInputs={allInputs}
        />
      )}
      <ThemeProvider theme={vraitheme}>
        <CssBaseline />
        <Switch>
          <Route>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar className={classNames(styles.toolbar)}>
                <div
                // onClick={() => {
                //   history.push("/");
                // }}
                >
                  <img src={mgikm} alt="Logo KMMGI" />
                  <img src={alphajet} alt="Logo ALPHAJET" />
                  <div className={classNames(styles.navbarzone2)}>
                    <TopBar
                      logo={logo}
                      pageName="CostCalculator"
                      currentLang={i18n.language}
                      changeLang={changeLang}
                      theme={theme}
                      // handleUpdateUserTheme={handleUpdateUserTheme}
                      backendUrl={process.env["REACT_APP_BACKEND_CONNECT_URL"]}
                      // currentConnectPage={location.pathname}
                      connectUrl={process.env["REACT_APP_CONNECT_URL"]}
                    />
                  </div>
                </div>

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  className={clsx(open && classes.hide, styles.buttonmenu)}
                  onClick={() => {
                    history.push("/roiTable");
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Route>
        </Switch>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {isInputForm && <Switchpage allInputs={allInputs}></Switchpage>}

          <Formcontent
            register={register}
            setValue={setValue}
            control={control}
            handleSubmit={handleSubmit}
            watch={watch}
            errors={errors}
            allInputs={allInputs}
            setAllInputsId={setAllInputsId}
            setJobInfoInputs={setJobInfoInputs}
            inputEstimatedSheets={inputEstimatedSheets}
            setInputEstimatedSheets={setInputEstimatedSheets}
            setCustomerInputs={setCustomerInputs}
            setCalibrationInputs={setCalibrationInputs}
            overhead={overhead}
            setOverHead={setOverHead}
            roiInfo={roiInfo}
            setRoiInfo={setRoiInfo}
            isInputForm={isInputForm}
            otherCost={otherCost}
            setOtherCost={setOtherCost}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsOpenModal={setIsOpenModal}
          ></Formcontent>

          {/* <Prevnext getValues={getValues}></Prevnext> */}
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        ></Drawer>
      </ThemeProvider>
    </div>
  );
}
