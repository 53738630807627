import { generateAmortizationTable } from "./generateAmortization";
import { AllInputs } from "./roiTypes";
const formula = require("@formulajs/formulajs");
export default function dataCapex(allInputs: AllInputs) {
  //TODO Calc this vlaue
  const e7 = allInputs.calibrationInputs
    ? allInputs.calibrationInputs.interestLeasingRate
    : 0;
  const e8 = allInputs.calibrationInputs
    ? allInputs.calibrationInputs?.amortizationPeriod
    : 0;

  const e4 = allInputs.calibrationInputs
    ? allInputs.calibrationInputs?.equipementCost
    : 0;
  const g9 = allInputs.calibrationInputs
    ? allInputs.calibrationInputs?.leaseDownpayment
    : 0;
  const g10 = 0;
  const e10 = e4 - g9 - g10;
  const g13 = 0;
  const e11 = e10 * g13; //=PRODUIT(E10;G13)

  const LeaseAmountPerMonth = -formula.PMT(e7 / 100 / 12, e8, e10, e11 - 1, 0);
  const h18 = -formula.IPMT(e7 / 100 / 12, 1, e8, e10, e11, 0);
  const g18 = LeaseAmountPerMonth - h18;
  const e18 = e10 - g18;

  const h19 = calcSumIpmt(e7, e8, e18, e11);
  const e19 = e18 - (LeaseAmountPerMonth - h19);
  let interest = h18 + h19;
  let varData = e19;
  for (let i = 2; i < e8; i++) {
    let lastCall = calcSumIpmt(e7, e8, varData, e11);
    interest += lastCall;
    varData = varData - (LeaseAmountPerMonth - lastCall);
  }
  function calcSumIpmt(e7: number, e8: number, lastData: number, e11: number) {
    return -formula.IPMT(e7 / 100 / 12, 1, e8, lastData, e11 - 1);
  }

  const investment = allInputs.calibrationInputs?.equipementCost;
  const interestRate = allInputs.calibrationInputs?.interestLeasingRate;
  const over = allInputs.calibrationInputs?.amortizationPeriod;
  const yearlyMaintenanceContract =
    allInputs.calibrationInputs?.yearlyMaintenanceContract
      .yearlyMaintenanceContract;
  const maintenanceContract = allInputs.calibrationInputs
    ? allInputs.calibrationInputs?.yearlyMaintenanceContract
        .maintenanceContractLength *
      allInputs.calibrationInputs?.yearlyMaintenanceContract
        .yearlyMaintenanceContract
    : 0;
  const yearMaintenance =
    allInputs.calibrationInputs?.yearlyMaintenanceContract
      .maintenanceContractLength;
  const mgiConnectSubscription =
    allInputs.calibrationInputs?.yearlyMaintenanceContract
      .mgiConnectSubscription;

  const total = allInputs.calibrationInputs
    ? allInputs.calibrationInputs?.equipementCost +
      interest -
      allInputs.calibrationInputs.leaseDownpayment
    : 0;

  const yearsAmortization = allInputs.calibrationInputs?.amortizationPeriod
    ? allInputs.calibrationInputs?.amortizationPeriod / 12
    : 0;

  const costPerYear = total / yearsAmortization;

  const monthlyCostToAmortizeEquipment = costPerYear / 12;

  const dayByMonth =
    allInputs.calibrationInputs?.yearlyMaintenanceContract.workingDaysPerMonth;
  const dayByWeek =
    allInputs.calibrationInputs?.yearlyMaintenanceContract.workingDaysPerWeek;
  const hoursByDay =
    allInputs.calibrationInputs?.yearlyMaintenanceContract
      .productionHoursPerDay;

  const costPerWeek =
    dayByWeek && dayByMonth
      ? (monthlyCostToAmortizeEquipment / dayByMonth) * dayByWeek
      : 0;

  const totalCostPerHour = hoursByDay ? costPerWeek / 5 / hoursByDay : 0;

  function getCostPerHour(value: number) {
    const costPerYear = value / yearsAmortization;
    const monthlyCostToAmortizeEquipment = costPerYear / 12;
    const costPerWeek =
      dayByWeek && dayByMonth
        ? (monthlyCostToAmortizeEquipment / dayByMonth) * dayByWeek
        : 0;

    const totalCostPerHour = hoursByDay ? costPerWeek / 5 / hoursByDay : 0;

    return totalCostPerHour;
  }

  const amortizationTable = generateAmortizationTable(
    e8,
    "FL",
    interestRate,
    e10,
    LeaseAmountPerMonth
  );

  const hourlyMaintenanceContract = getCostPerHour(maintenanceContract);
  const hourlyMgiConnectSubscription = getCostPerHour(mgiConnectSubscription);

  return {
    interest: interest,
    LeaseAmountPerMonth: LeaseAmountPerMonth,
    investment: investment,
    interestRate: interestRate,
    over: over,
    yearlyMaintenanceContract: yearlyMaintenanceContract,
    monthlyCosttoAmortizeEquipment: monthlyCostToAmortizeEquipment,
    maintenanceContract: maintenanceContract,
    yearMaintenance: yearMaintenance,
    total: total,
    yearsAmortization: yearsAmortization,
    costPerYear: costPerYear,
    dayByMonth: dayByMonth,
    dayByWeek: dayByWeek,
    hoursByDay: hoursByDay,
    costPerWeek: costPerWeek,
    totalCostPerHour: totalCostPerHour,
    hourlyMaintenanceContract: hourlyMaintenanceContract,
    hourlyMgiConnectSubscription: hourlyMgiConnectSubscription,
    amortizationTable: amortizationTable,
  };
}
