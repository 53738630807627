import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

import Grid from "@material-ui/core/Grid";

import {
  Checkbox,
  InputAdornment,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  withStyles,
  Theme,
  createStyles,
  InputBase,
} from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";

import NoteOutlined from "@material-ui/icons/NoteOutlined";

import Colorsform from "../image/icon/Colorsform.svg";
import paperoil from "../image/icon/paper-roll.svg";
import alphajetmachine from "../image/img/MGIAlphaJET.jpg";
import Opacity from "@material-ui/icons/Opacity";
import Acces from "@material-ui/icons/AccessTimeOutlined";
import Euro from "@material-ui/icons/EuroOutlined";
import Emoji from "@material-ui/icons/EmojiEventsOutlined";
import {
  AllInputs,
  allInputsSchema,
  otherCostSchema,
  OtherCostType,
  resultSchema,
  ScenarioType,
} from "./roiTypes";
import dataResult from "./dataResult";
import SwitchPageInput from "./switchPageInput";

import visibility from "../image/icon/visibility.svg";
import visibilityoff from "../image/icon/visibilityoff.svg";

import {
  formatNumber,
  removeWhiteSpaceInNumber,
  whiteSpaceNumber,
} from "./formatNumber";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useBackend } from "../backend/BackendProvider";
import {
  Control,
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import Prevnext from "./prevnext";

const Result = ({
  allInputs,
  overhead,
  setOverHead,
  handleSubmit,
  isInputForm,
  register,
  watch,
  setValue,
  control,
  otherCost,
  setOtherCost,
  setIsLoading,
}: {
  allInputs: AllInputs;
  isInputForm: boolean;
  overhead: number;
  setOverHead: React.Dispatch<number>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AllInputs>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    onInvalid?: SubmitErrorHandler<AllInputs> | undefined
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  register: any;
  control: Control<AllInputs>;
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined
  ) => void;
  watch: () => AllInputs;
  otherCost: OtherCostType;
  setOtherCost: React.Dispatch<OtherCostType>;
  setIsLoading: React.Dispatch<boolean>;
}) => {
  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {
          marginTop: theme.spacing(3),
        },
      },
      input: {
        borderRadius: 4,
        height: "20px",
        border: "1px solid #ced4da",
        padding: "10px 26px 10px 12px",
        backgroundColor: "#FAFAFA",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.

        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    })
  )(InputBase);
  const dataFResult = dataResult(allInputs, overhead);

  const [includeCapex, setIncludeCapex] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const backend = useBackend();

  const roiIdLocation: number = location.state as number;

  const routeMatch = useRouteMatch<{
    page: string | undefined;
    roiId: string | undefined;
  }>("/:page/:roiId");

  const roiId = useCallback(() => {
    if (routeMatch?.params.roiId) return parseInt(routeMatch.params.roiId);
    else return roiIdLocation;
  }, [roiIdLocation, routeMatch?.params.roiId]);

  const hasFoil =
    (allInputs.jobInfoInputs?.front.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs?.front.digitalHotFoil2d3dEffect !== 0) ||
    (allInputs.jobInfoInputs?.back.digitalHotFoilCoverage > 0 &&
      allInputs.jobInfoInputs?.back.digitalHotFoil2d3dEffect !== 0);

  const hasOpv =
    allInputs.jobInfoInputs.front.opv > 0 ||
    allInputs.jobInfoInputs.back.opv > 0;

  const [clicked, setClicked] = useState<string>("result");

  const localOverhead: number | undefined = watch().overhead;

  const isLiter = allInputs.jobInfoInputs.numberOfSheets >= 1000;

  const netProfitMargin = useMemo(() => {
    if (localOverhead) {
      if (otherCost === "fix") {
        return (
          dataFResult.totalGrossProfitMargin -
          removeWhiteSpaceInNumber(localOverhead)
        );
      } else if (otherCost === "total") {
        return (
          dataFResult.totalGrossProfitMargin -
          (removeWhiteSpaceInNumber(localOverhead) / 100) *
            dataFResult.totalCostofJob
        );
      } else {
        return (
          dataFResult.totalGrossProfitMargin -
          (removeWhiteSpaceInNumber(localOverhead) / 100) *
            dataFResult.retailCostofJob
        );
      }
    } else {
      if (otherCost === "fix") {
        return (
          dataFResult.totalGrossProfitMargin -
          removeWhiteSpaceInNumber(overhead ?? allInputs.overhead)
        );
      } else if (otherCost === "total") {
        return (
          dataFResult.totalGrossProfitMargin -
          (removeWhiteSpaceInNumber(overhead ?? allInputs.overhead) / 100) *
            dataFResult.totalCostofJob
        );
      } else {
        return (
          dataFResult.totalGrossProfitMargin -
          (removeWhiteSpaceInNumber(overhead ?? allInputs.overhead) / 100) *
            dataFResult.retailCostofJob
        );
      }
    }
  }, [
    allInputs.overhead,
    dataFResult.retailCostofJob,
    dataFResult.totalCostofJob,
    dataFResult.totalGrossProfitMargin,
    localOverhead,
    otherCost,
    overhead,
  ]);

  const roiUpdateResultHandler = useCallback(
    async (data: AllInputs) => {
      if (data) {
        const castedResult = resultSchema.cast(
          data.overhead ? removeWhiteSpaceInNumber(data.overhead) : 0
        ) as unknown as number;

        const castedOtherCostType = otherCostSchema.cast(
          data.otherCostType ?? "fix"
        ) as unknown as OtherCostType;

        setOverHead(castedResult);
        setOtherCost(castedOtherCostType);

        const newAllInputs = new AllInputs(
          allInputs.calibrationInputs,
          allInputs.jobInfoInputs,
          allInputs.inputEstimatedSheets,
          castedResult,
          castedOtherCostType,
          allInputs?.customerInputs,
          allInputs?.roiInfo,
          roiId()
        );

        setIsLoading(true);
        try {
          await backend.updateRoi(
            roiId(),
            allInputsSchema.cast(newAllInputs) as unknown as AllInputs,
            false
          );
        } finally {
          setIsLoading(false);
          history.push(`/${clicked}/${roiId()}`, roiId());
        }
      }
    },
    [
      allInputs.calibrationInputs,
      allInputs?.customerInputs,
      allInputs.inputEstimatedSheets,
      allInputs.jobInfoInputs,
      allInputs?.roiInfo,
      backend,
      clicked,
      history,
      roiId,
      setIsLoading,
      setOtherCost,
      setOverHead,
    ]
  );

  const [showCMYK, setShowCMYK] = useState<boolean>(false);
  const [showVarnish, setShowVarnish] = useState<boolean>(false);
  const [showOPV, setShowOPV] = useState<boolean>(false);

  return (
    <form
      onSubmit={handleSubmit(roiUpdateResultHandler)}
      style={{
        overflow: !isInputForm ? "scroll" : "",
        height: !isInputForm ? "calc(100%);" : "",
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <SwitchPageInput setClicked={setClicked} allInputs={allInputs} />
      <div className={classNames(styles.customer, styles.height100)}>
        <Grid
          xs={12}
          className={classNames(styles.paddinglr10, styles.topform)}
        >
          <Grid className={styles.inputcontent} xs={6}>
            <Grid xs={2}></Grid>
            <Grid xs={10}>
              <Typography variant="h5">RESULTS</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles.inputcontent} xs={6}>
          <Grid xs={2}></Grid>
          <Grid xs={10}></Grid>
        </Grid>

        <FormControl className={styles.formControl}>
          <Grid xs={12} className={styles.dispflex}>
            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <NoteOutlined color="primary" />
                </Grid>
                <Grid xs={10} className={styles.dispflex}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Total N° of sheets /job</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend6}>
                      {whiteSpaceNumber(dataFResult.totalNOSheetByJob)} sheets
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Acces color="primary" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>
                      Total time to produce
                      <br />{" "}
                      <span className={classNames(styles.italic)}>
                        (at{" "}
                        {
                          allInputs.jobInfoInputs.alphaJetInfos
                            .alphaJetProductivity
                        }
                        % productivity)
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend6}>
                      {dataFResult.totaltimetoproduceAt}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>
                      Equipment hourly cost
                      <br />{" "}
                      <span className={classNames(styles.italic)}>
                        Includes: Labor, Maintenance Contract, Electricity &
                        Overhead (Rent)
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(styles.alignend6, styles.relative)}
                    >
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.amortizedCosts)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Cost of Substrates</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend6}>
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.costOfMaterial)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,

                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.flexwrap)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <img src={Colorsform} alt="Colorsform" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Cost of CMYK Print</Typography>
                    <img
                      className={classNames(styles.eyeimg)}
                      src={!showCMYK ? visibility : visibilityoff}
                      alt="Visible"
                      onClick={() => setShowCMYK(!showCMYK)}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend6}>
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.costofCMYKPrint)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
                {showCMYK && (
                  <>
                    <Grid xs={2}></Grid>
                    <Grid className={styles.dispflex} xs={10}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littlefont)}>
                          Front Side Ink Consumption
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography
                          className={classNames(
                            styles.alignend6,
                            styles.littlefont,
                            styles.relative
                          )}
                        >
                          {whiteSpaceNumber(
                            formatNumber(
                              dataFResult.conso.front.cmyk *
                                (isLiter ? 1 : 1000)
                            )
                          )}{" "}
                          {allInputs.jobInfoInputs.numberOfSheets >= 1000}
                          {isLiter ? "L" : "mL"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {showCMYK && allInputs.jobInfoInputs.doubleSided && (
                  <>
                    <Grid xs={2}></Grid>
                    <Grid className={styles.dispflex} xs={10}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littlefont)}>
                          Back Side Ink Consumption
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography
                          className={classNames(
                            styles.alignend6,
                            styles.littlefont,
                            styles.relative
                          )}
                        >
                          {whiteSpaceNumber(
                            formatNumber(
                              dataFResult.conso.back.cmyk * (isLiter ? 1 : 1000)
                            )
                          )}{" "}
                          {isLiter ? "L" : "mL"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.flexwrap)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Opacity color="primary"></Opacity>
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>
                      Cost of Spot UV Coating
                      {/* (
                      {allInputs.jobInfoInputs.embellishment2d3dEffect} µm)
                      {allInputs.jobInfoInputs.opv && "(OPV)"} */}
                    </Typography>
                    <img
                      className={classNames(styles.eyeimg)}
                      src={!showVarnish ? visibility : visibilityoff}
                      alt="Visible"
                      onClick={() => setShowVarnish(!showVarnish)}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(styles.alignend6, styles.relative)}
                    >
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.costofVarnishUsed)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
                {showVarnish && (
                  <>
                    <Grid xs={2}></Grid>
                    <Grid className={styles.dispflex} xs={10}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littlefont)}>
                          Front Varnish Consumption
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography
                          className={classNames(
                            styles.alignend6,
                            styles.littlefont,
                            styles.relative
                          )}
                        >
                          {whiteSpaceNumber(
                            formatNumber(
                              dataFResult.conso.front.varnish *
                                (isLiter ? 1 : 1000)
                            )
                          )}{" "}
                          {isLiter ? "L" : "mL"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {showVarnish && allInputs.jobInfoInputs.doubleSided && (
                  <>
                    <Grid xs={2}></Grid>
                    <Grid className={styles.dispflex} xs={10}>
                      <Grid className={classNames(styles.algnitem)} xs={8}>
                        <Typography className={classNames(styles.littlefont)}>
                          Back Varnish Consumption
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography
                          className={classNames(
                            styles.alignend6,
                            styles.littlefont,
                            styles.relative
                          )}
                        >
                          {whiteSpaceNumber(
                            formatNumber(
                              dataFResult.conso.back.varnish *
                                (isLiter ? 1 : 1000)
                            )
                          )}{" "}
                          {isLiter ? "L" : "mL"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Cost of Primer</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend6}>
                      {whiteSpaceNumber(formatNumber(dataFResult.costOfPrimer))}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid
                className={classNames(
                  styles.inputcontent,

                  styles.dispflex
                )}
                xs={12}
              ></Grid> */}
              {hasOpv && (
                <Grid
                  className={classNames(styles.inputcontent, styles.flexwrap)}
                  xs={12}
                >
                  <Grid className={styles.iconcontent} xs={2}>
                    <Opacity color="primary"></Opacity>
                  </Grid>
                  <Grid className={styles.dispflex} xs={10}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>
                        Cost of OPV (
                        {allInputs.jobInfoInputs.front.opv > 0 &&
                          "Front " +
                            allInputs.jobInfoInputs.front.opv2d3dEffect +
                            " µm"}
                        {allInputs.jobInfoInputs.front.opv > 0 &&
                          allInputs.jobInfoInputs.back.opv > 0 &&
                          " / "}
                        {allInputs.jobInfoInputs.back.opv > 0 &&
                          "Back " +
                            allInputs.jobInfoInputs.back.opv2d3dEffect +
                            " µm"}
                        )
                        {/* (
                      {allInputs.jobInfoInputs.embellishment2d3dEffect} µm)
                      {allInputs.jobInfoInputs.opv && "(OPV)"} */}
                      </Typography>
                      <img
                        className={classNames(styles.eyeimg)}
                        src={!showOPV ? visibility : visibilityoff}
                        alt="Visible"
                        onClick={() => setShowOPV(!showOPV)}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <Typography
                        className={classNames(
                          styles.alignend6,
                          styles.relative
                        )}
                      >
                        {whiteSpaceNumber(
                          formatNumber(dataFResult.finalOpvCost)
                        )}{" "}
                        €
                      </Typography>
                    </Grid>
                  </Grid>

                  {showOPV && (
                    <>
                      <Grid xs={2}></Grid>
                      <Grid className={styles.dispflex} xs={10}>
                        <Grid className={classNames(styles.algnitem)} xs={8}>
                          <Typography className={classNames(styles.littlefont)}>
                            Front Side Varnish Consumption
                          </Typography>
                        </Grid>
                        <Grid xs={4}>
                          <Typography
                            className={classNames(
                              styles.alignend6,
                              styles.littlefont,
                              styles.relative
                            )}
                          >
                            {whiteSpaceNumber(
                              formatNumber(
                                dataFResult.conso.front.opv *
                                  (isLiter ? 1 : 1000)
                              )
                            )}{" "}
                            {isLiter ? "L" : "mL"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showOPV && allInputs.jobInfoInputs.doubleSided && (
                    <>
                      <Grid xs={2}></Grid>
                      <Grid className={styles.dispflex} xs={10}>
                        <Grid className={classNames(styles.algnitem)} xs={8}>
                          <Typography className={classNames(styles.littlefont)}>
                            Back Side Varnish Consumption
                          </Typography>
                        </Grid>
                        <Grid xs={4}>
                          <Typography
                            className={classNames(
                              styles.alignend6,
                              styles.littlefont,
                              styles.relative
                            )}
                          >
                            {whiteSpaceNumber(
                              formatNumber(dataFResult.finalOpvCost)
                            )}{" "}
                            €
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              {allInputs.jobInfoInputs.front.digitalHotFoil2d3dEffect ? (
                <Grid className={classNames(styles.inputcontent)} xs={12}>
                  <Grid className={styles.iconcontent} xs={2}>
                    <img src={paperoil} alt="Colorsform" />
                  </Grid>

                  <Grid className={styles.dispflex} xs={10}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Cost of Foil</Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography className={styles.alignend6}>
                        {whiteSpaceNumber(formatNumber(dataFResult.foilCosts))}{" "}
                        €
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>{" "}
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Euro color="primary" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>Total cost of job</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      style={{ position: "relative" }}
                      className={styles.alignend6}
                    >
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.totalCostofJob)
                      )}{" "}
                      €
                      <span
                        className={classNames(styles.blueline)}
                        style={{
                          bottom:
                            hasFoil && hasOpv
                              ? "-86px"
                              : hasFoil || hasOpv
                              ? "-50px"
                              : "-14px",
                        }}
                      ></span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}></Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography>
                      {scenarioText(
                        allInputs.jobInfoInputs.alphaJetInfos.scenario
                      )}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={styles.alignend6}>
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.retailCostofJob)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>{" "}
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <Emoji color="primary" />
                </Grid>
                <Grid
                  className={classNames(styles.dispflex, styles.bluecontent)}
                  xs={10}
                >
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">
                      GROSS PROFIT MARGIN ON JOB
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(styles.alignend6, styles.textbig)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.totalGrossProfitMargin)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                  <Grid className={classNames(styles.algnitem)} xs={6}>
                    <Typography variant="h6" color="primary">
                      GROSS PROFIT MARGIN
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography
                      className={classNames(styles.alignend6, styles.textbig)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(formatNumber(dataFResult.grossProfit))}{" "}
                      %
                    </Typography>
                  </Grid>

                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15,
                      styles.flexwrap
                    )}
                    xs={12}
                  >
                    <Grid
                      xs={12}
                      className={classNames(
                        styles.dispflex,
                        styles.radiocontent
                      )}
                    >
                      <Grid className={classNames(styles.algnitem)} xs={4}>
                        <Typography>Information</Typography>
                      </Grid>
                      <Grid
                        xs={8}
                        className={classNames(
                          styles.alignend,
                          styles.radiozone
                        )}
                      >
                        <FormControl size="small" margin="none">
                          <Controller
                            render={() => (
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={otherCost}
                                required
                                onChange={(e) => {
                                  setValue("otherCostType", e.target.value);
                                  setOtherCost(e.target.value as OtherCostType);
                                }}
                                input={<BootstrapInput />}
                                defaultValue={allInputs.otherCostType ?? "fix"}
                              >
                                <MenuItem value={"fix"}>fixed cost</MenuItem>
                                <MenuItem value={"total"}>
                                  % of total cost of job
                                </MenuItem>
                                <MenuItem value={"retail"}>
                                  % of retail cost of job
                                </MenuItem>
                              </Select>
                            )}
                            name="otherCostType"
                            control={control}
                            defaultValue={allInputs.otherCostType ?? "fix"}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid className={styles.dispflex} xs={12}>
                      <Grid
                        className={classNames(styles.algnitem, styles.flexwrap)}
                        xs={8}
                      >
                        <Typography className={classNames(styles.sizemax)}>
                          Other cost(s)
                        </Typography>
                        <Typography
                          className={classNames(
                            styles.littlefont,
                            styles.sizemax
                          )}
                        >
                          Finishing, Shipping & Overheads
                        </Typography>
                      </Grid>
                      <Grid xs={4} className={styles.alignend6}>
                        <FormControl
                          className={styles.yellownotransparent}
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                              <InputAdornment position="end">
                                {otherCost === "retail" || otherCost === "total"
                                  ? "%"
                                  : "€"}
                              </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            placeholder="0"
                            defaultValue={whiteSpaceNumber(overhead) ?? 0}
                            onKeyUp={(e) => {
                              e.currentTarget.value = whiteSpaceNumber(
                                e.currentTarget.value
                              );
                              setOverHead(
                                removeWhiteSpaceInNumber(e.currentTarget.value)
                              );
                            }}
                            inputRef={register}
                            name="overhead"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classNames(styles.algnitem, styles.margint15)}
                    xs={8}
                  >
                    <Typography variant="h6">
                      TOTAL GROSS PROFIT MARGIN ON JOB
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(
                        styles.alignend6,
                        styles.textbig,
                        styles.margint15
                      )}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(formatNumber(netProfitMargin))} €
                    </Typography>
                  </Grid>
                  <Grid className={classNames(styles.algnitem)} xs={6}>
                    <Typography variant="h6" color="primary">
                      TOTAL GROSS PROFIT MARGIN
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography
                      className={classNames(styles.alignend6, styles.textbig)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        formatNumber(
                          100 * (netProfitMargin / dataFResult.retailCostofJob)
                        )
                      )}{" "}
                      %
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent, styles.margint15)}
                xs={12}
              >
                <Grid className={styles.iconcontent} xs={12}>
                  <Grid className={styles.iconcontent} xs={2}></Grid>
                  <Grid xs={10} className={styles.dispflex}>
                    <Grid className={classNames(styles.algnitem)} xs={8}>
                      <Typography>Including capex </Typography>
                    </Grid>
                    <Grid xs={4} className={styles.justifyend}>
                      <Checkbox
                        className={styles.inputecoconso}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={(e) => setIncludeCapex(e.target.checked)}
                        checked={includeCapex}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(styles.inputcontent)}
                xs={12}
                style={{ visibility: includeCapex ? "visible" : "hidden" }}
              >
                <Grid className={styles.iconcontent} xs={2}>
                  <Emoji color="primary" />
                </Grid>
                <Grid
                  className={classNames(styles.dispflex, styles.bluecontent)}
                  xs={10}
                >
                  <Grid className={classNames(styles.algnitem)} xs={6}>
                    <Typography variant="h6" color="primary">
                      CAPEX JOB COST
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography
                      className={classNames(styles.alignend6, styles.textbig)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.hourlyCapexCost)
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                  <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">
                      NET PROFIT MARGIN ON JOB
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      className={classNames(styles.alignend6, styles.textbig)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        formatNumber(
                          netProfitMargin - dataFResult.hourlyCapexCost
                        )
                      )}{" "}
                      €
                    </Typography>
                  </Grid>
                  <Grid className={classNames(styles.algnitem)} xs={6}>
                    <Typography variant="h6" color="primary">
                      NET PROFIT MARGIN
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography
                      className={classNames(styles.alignend6, styles.textbig)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        formatNumber(
                          100 *
                            ((netProfitMargin - dataFResult.hourlyCapexCost) /
                              dataFResult.retailCostofJob)
                        )
                      )}{" "}
                      %
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ALPHAJET CONSUMables Costs */}

            <Grid xs={6} className={classNames(styles.paddinglr10)}>
              <Grid className={styles.inputcontent} xs={12}>
                <Grid className={styles.iconcontent} xs={2}>
                  <NoteOutlined color="primary" />
                </Grid>
                <Grid className={styles.dispflex} xs={10}>
                  <Typography className={styles.textcenter} color="primary">
                    ROI Amortization to Break-Even <br /> Monthly rent &nbsp;
                    {whiteSpaceNumber(
                      Math.round(
                        dataFResult.rOIAmortizationToBreakEvenMonthlyRent
                      )
                    )}{" "}
                    €
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid xs={2}></Grid>
                <Grid
                  className={classNames(styles.dispflex, styles.bluecontent)}
                  xs={10}
                >
                  <Grid className={classNames(styles.algnitem)} xs={7}>
                    <Typography variant="h6">
                      MONTHLY SHEETS
                      <br />
                      <span className={classNames(styles.italic)}>
                        Needed to reach Break-even
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      className={(styles.alignend2, styles.alignend6)}
                      variant="h6"
                    >
                      {whiteSpaceNumber(
                        Math.round(
                          dataFResult.monthlySheetsNeededToReachBreakEvent
                        )
                      )}
                      &nbsp;
                      <span className={classNames(styles.littletext)}>
                        sheets
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {allInputs.jobInfoInputs.upsPerSheet > 0 && (
                <>
                  <Grid
                    className={classNames(
                      styles.inputcontent,
                      styles.margint15,
                      styles.dispflex
                    )}
                    xs={12}
                  ></Grid>

                  <Grid className={classNames(styles.inputcontent)} xs={12}>
                    <Grid xs={2}></Grid>
                    <Grid
                      className={classNames(
                        styles.dispflex,
                        styles.bluecontent
                      )}
                      xs={10}
                    >
                      <Grid className={classNames(styles.algnitem)} xs={7}>
                        <Typography variant="h6">
                          MONTHLY PRODUCT
                          <br />
                          <span className={classNames(styles.italic)}>
                            Needed to reach Break-even
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography
                          className={(styles.alignend2, styles.alignend6)}
                          variant="h6"
                        >
                          {whiteSpaceNumber(
                            Math.round(
                              dataFResult.monthlySheetsNeededToReachBreakEvent *
                                allInputs.jobInfoInputs.upsPerSheet
                            )
                          )}
                          &nbsp;
                          <span className={classNames(styles.littletext)}>
                            products
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid xs={2}></Grid>
                <Grid
                  className={classNames(styles.dispflex, styles.bluecontent)}
                  xs={10}
                >
                  <Grid className={classNames(styles.algnitem)} xs={7}>
                    <Typography variant="h6">
                      MONTHLY JOBS <br />
                      <span className={classNames(styles.italic)}>
                        Needed to reach Break-even
                      </span>
                    </Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      className={classNames(
                        styles.fontWeight,
                        styles.alignend6
                      )}
                      variant="h6"
                    >
                      {whiteSpaceNumber(
                        Math.round(dataFResult.monthlyJobsNeededBreakEvent)
                      )}{" "}
                      jobs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid xs={2}></Grid>
                <Grid
                  className={classNames(styles.dispflex, styles.bluecontent)}
                  xs={10}
                >
                  <Grid className={classNames(styles.algnitem)} xs={7}>
                    <Typography variant="h6">
                      YEARLY VOLUME
                      <br />
                      <span className={classNames(styles.italic)}>
                        Needed to reach Break-even
                      </span>
                    </Typography>
                  </Grid>

                  <Grid xs={5}>
                    <Typography
                      className={(styles.alignend2, styles.alignend6)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        Math.round(
                          dataFResult.yearVolumeOfSheetsToReachBreakEvent
                        )
                      )}
                      &nbsp;
                      <span className={classNames(styles.littletext)}>
                        sheets
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classNames(
                  styles.inputcontent,
                  styles.margint15,
                  styles.dispflex
                )}
                xs={12}
              ></Grid>
              <Grid className={classNames(styles.inputcontent)} xs={12}>
                <Grid xs={2}></Grid>
                <Grid
                  className={classNames(styles.dispflex, styles.bluecontent)}
                  xs={10}
                >
                  <Grid className={classNames(styles.algnitem)} xs={12}>
                    <Typography
                      className={classNames(styles.breakenven)}
                      variant="h6"
                    >
                      BREAK-EVEN Based On
                      <p>
                        <span style={{ color: "#2e35db" }}>
                          {whiteSpaceNumber(formatNumber(netProfitMargin))} €
                          T.G.P.{" "}
                        </span>
                        On{" "}
                        <span style={{ color: "#2e35db" }}>
                          {allInputs.jobInfoInputs.numberOfSheets} Sheets
                        </span>
                        {allInputs.jobInfoInputs.upsPerSheet > 0 && (
                          <>
                            /
                            <span style={{ color: "#2e35db" }}>
                              {allInputs.jobInfoInputs.numberOfSheets *
                                allInputs.jobInfoInputs.upsPerSheet}{" "}
                              Products
                            </span>
                          </>
                        )}
                      </p>
                    </Typography>
                    {/* <Grid className={classNames(styles.algnitem)} xs={8}>
                    <Typography variant="h6">Break-even Based On</Typography>
                    <br />
                    <Typography variant="h6">On</Typography>
                  </Grid>

                  <Grid xs={4}>
                    <Typography
                      className={(styles.alignend2, styles.alignend6)}
                      variant="h6"
                      color="primary"
                    >
                      {whiteSpaceNumber(
                        formatNumber(dataFResult.totalGrossProfitMargin)
                      )}{" "}
                      € G.P. <br />
                      <span className={classNames(styles.littletext)}>
                        sheets
                      </span>
                    </Typography>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classNames(styles.alphajetmachine, styles.dispflex)}
                xs={12}
              >
                <Grid xs={2}></Grid>
                <Grid xs={10}>
                  <img src={alphajetmachine} alt="ALPHAJET machine" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <Prevnext isInputForm previous="machine-time-costs" next="roi" />
    </form>
  );
};
export default Result;

function scenarioText(scenario: ScenarioType): string {
  switch (scenario) {
    case "job":
      return "Retail Cost of Job";
    case "customer":
      return "Customer Cost of Production";
    case "subcontract":
      return "Customer Subcontracting Price";
  }
}
